import { SvgIcon } from '~/ui/SvgIcon/SvgIcon';
import ReactDOMServer from 'react-dom/server';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { svgIcons } from '~/utils/getIcons';
import classNames from 'classnames';
import styles from './IconsContainer.module.scss';
import { Button } from '~/ui';

interface IIconProps {
  onCloseClick: () => void;
  setSelectedId: (value: number) => void;
}

const convertSvgToMarkup = (SvgComponent: React.FC) => {
  return ReactDOMServer.renderToStaticMarkup(<SvgComponent />);
};

const convertIcons = svgIcons.map(item => {
  const icon = convertSvgToMarkup(item.icon);
  return { id: item.id, icon };
});

export const IconsContainer = ({ onCloseClick, setSelectedId }: IIconProps) => {
  const [selectedIcon, setSelectedIcon] = useState<number | null>(null);
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (selectedIcon === null) return;
    onCloseClick();
    setSelectedId(selectedIcon);
    setSelectedIcon(null);
  };

  const handleClose = () => {
    onCloseClick();
    setSelectedIcon(null);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.titleWrapper}>
        <p className={styles.title}>{t('choose_icon')}</p>
        <Button
          text={t('close')}
          onClick={handleClose}
          className={styles.closeButton}
        />
      </div>
      <div className={styles.iconsList}>
        {convertIcons.map(item => (
          <button
            key={item.id}
            onClick={() => setSelectedIcon(item.id)}
            className={classNames(
              styles.button,
              selectedIcon === item.id ? styles.active : ''
            )}
          >
            <SvgIcon
              svgString={item.icon}
              key={item.id}
              className={styles.icon}
            />
          </button>
        ))}
      </div>
      <Button
        text={t('confirm')}
        onClick={handleSubmit}
        className={styles.confirmBtn}
        disabled={selectedIcon === null}
      />
    </div>
  );
};
