import { FormikHandlers } from 'formik';
import { ChangeEvent } from 'react';
import InputMask from 'react-input-mask';
import cn from 'classnames';
import styles from './InputMaskBar.module.scss';

interface InputMaskBarProps {
  type: string;
  placeholder: string;
  value: string;
  star: boolean;
  label: string;
  name: string;
  mask: string;
  errors?: string;
  error?: boolean;
  disabled?: boolean;
  onBlur?: FormikHandlers['handleBlur'];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const InputMaskBar = ({
  type,
  placeholder,
  value,
  star,
  label,
  name,
  mask,
  disabled = false,
  errors,
  error,
  onBlur,
  onChange,
}: InputMaskBarProps) => {
  return (
    <>
      <div className={styles.input__wrapper}>
        <label className={styles.input__label} htmlFor={name}>
          <span className={styles.input__text}>
            {label}
            {star ? <span className={styles.input__star}>*</span> : null}
          </span>
          <InputMask
            mask={mask}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          >
            {/* @ts-ignore */}
            <div className={styles.input__container}>
              <input
                type={type}
                placeholder={placeholder}
                className={cn(styles.form__input, {
                  [styles['input__errors']]: error,
                })}
                id={name}
                disabled={disabled}
              />
            </div>
          </InputMask>
          {error ? <div className={styles.errors__text}>{errors}</div> : null}
        </label>
      </div>
    </>
  );
};
