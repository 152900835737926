import { USER_ROLES } from './getUserRole'
import {
  nameValidate,
  telValidate,
  emailValidate,
  phraseValidate,
} from './regex'
import i18next from 'i18next'

export const validate = values => {
  const errors = {}
  // uniqueId

  if (!values.uniqueId) {
    errors.uniqueId = i18next.t('user_id_validate')
  } else if (!phraseValidate(values.price)) {
    errors.price = i18next.t('numbers_validate')
  }

  // type

  if (!values.grade.id) {
    errors.grade = i18next.t('user_type_validate')
  }

  // role

  if (!values.role.id) {
    errors.role_id = i18next.t('role_validate')
  }

  // name
  if (!values.name) {
    errors.name = i18next.t('enter_first_name')
  } else if (values.name.length < 2) {
    errors.name = i18next.t('at_least_two_characters')
  } else if (values.name.length >= 32) {
    errors.name = i18next.t('no_more_thirty_characters')
  }

  // phone

  if (!values.phone || !telValidate(values.phone)) {
    errors.phone = i18next.t('fill_field')
  }

  // Email

  if (!values.email) {
    errors.email = i18next.t('fill_field')
  } else if (!emailValidate(values.email)) {
    errors.email = i18next.t('incorrect_email')
  }

  // // Password
  // if (!values.password) {
  //   errors.password = 'Заполните пароль';
  // } else if (values.password.length < 8) {
  //   errors.password = 'Минимум 8 символов';
  // }

  // Busitess_trips

  if (!values.business_trips.length) {
    errors.business_trips = i18next.t('trip_validate')
  }

  // Company name / Asset

  if (values.role.name === USER_ROLES.ASSET_ADMIN) {
    if (!values.assets.id) {
      errors.assets = i18next.t('validate_asset')
      delete errors.company_name
    }
  } else if (values.role.name === USER_ROLES.COMPANY_ADMIN) {
    if (!values.company_name) {
      errors.company_name = i18next.t('validate_company_name')
    }
  } else {
    delete errors.company_name
    delete errors.assets
    if (values.foodPoint) {
      if (!foodPoint) {
        errors.foodPoint = i18next.t('food_point_validate')
      }
    }
  }

  // Sections

  const isAllowedEmptySections =
    values.role.name === USER_ROLES.USER ||
    values.role.name === USER_ROLES.SUPER_ADMIN

  if (!isAllowedEmptySections && values.sections.length === 0) {
    errors.sections = i18next.t('add_sections')
  }

  return errors
}
