import { Board } from '~/components/Shared/Layout/Board/Board';
import { Action } from '~/ui/Actions';
import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';
import { useContext, useState } from 'react';
import LevelsTable from './LevelsTable/LevelsTable';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { Spinner } from '~/ui';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useToastError } from '~/utils/useToastError';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import Tooltip from '~/components/Shared/Tooltip/Tooltip';
import styles from './LevelsBoard.module.scss';

const LevelsBoard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const isSuperAdmin = checkIfUserSuperAdmin(user?.data.role_name);
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const client = useQueryClient();
  const [asset, setAsset] = useState({
    id: isSuperAdmin ? 0 : user ? user?.data?.asset_id : 0,
    name: '',
  });
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });

  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/loyalty-level', {
        params: {
          page: 1,
          perPage: 100,
          ...(asset.id ? { asset_id: asset.id } : {}),
        },
      });
      return data;
    },
    queryKey: ['promotion-levels', asset],
  });

  const { mutateAsync: deleteLevel } = useMutation(
    async (id: number) => {
      const result = await instance.delete(`admin/loyalty-level/${id}`);
      return result.data;
    },
    {
      onSuccess: () => {
        toastSuccess(t('level_successfull_deleted'));
        client.invalidateQueries(['promotion-levels']);
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Board classNames={styles.board}>
      <div className={styles.boardHeading}>
        {isSuperAdmin && (
          <CustomQuerySelect
            label={t('assets')}
            query="admin/assets"
            queryKey={['assets', {}]}
            placeholder={t('validate_asset')}
            params={null}
            value={asset}
            className={styles.querySelect}
            onChange={value => {
              if (!Array.isArray(value) && value?.name) {
                setAsset(value);
              }
            }}
          />
        )}

        {!isSuperAdmin && data?.data?.length >= 5 ? (
          <div className={styles.tooltipWrapper}>
            <Tooltip title={t('max_5_conditions')} className={styles.tooltip}>
              <Action
                variant="success"
                disabled={!isSuperAdmin && data?.data?.length >= 5}
                className={styles.action}
                onClick={() => {
                  navigate('/promotions/levels/create');
                }}
              >
                <AddIcon /> {t('add_level')}
              </Action>
            </Tooltip>
          </div>
        ) : (
          <Action
            variant="success"
            className={styles.action}
            onClick={() => {
              navigate('/promotions/levels/create');
            }}
          >
            <AddIcon /> {t('add_level')}
          </Action>
        )}
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <LevelsTable
          sort={sort}
          setSort={setSort}
          levels={data?.data}
          deleteLevel={deleteLevel}
        />
      )}
    </Board>
  );
};

export default LevelsBoard;
