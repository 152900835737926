import { useTranslation } from 'react-i18next';
import ModalConfirm from '~/components/Shared/ModalConfirm';
import { IconButton } from '~/ui';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { useNavigate } from 'react-router-dom';

import styles from './LevelsTable.module.scss';

interface ILevel {
  id: number;
  name: string;
  points: number;
  discount_percent: number;
}

interface ILevelTableProps {
  levels: ILevel[];
  sort: {
    field: string;
    direction: string;
  };
  setSort: ({ field, direction }: { field: string; direction: string }) => void;
  deleteLevel: (id: number) => void;
}

const LevelsTable = ({
  sort,
  setSort,
  levels,
  deleteLevel,
}: ILevelTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead field="name">{t('level_name')}</TableCellHead>
        <TableCellHead field="points">{t('points_quantity')}</TableCellHead>
        <TableCellHead field="discount_percent">
          {t('discount_level')}
        </TableCellHead>
        <TableCellHead field="actions" sortable={false}>
          {t('actions')}
        </TableCellHead>
      </TableHead>
      <TableBody>
        {levels.map(item => (
          <TableRow key={item.id}>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.points}</TableCell>
            <TableCell>{item.discount_percent}</TableCell>
            <TableCell>
              <div className={styles.levelTableActions}>
                <IconButton
                  // @ts-ignore
                  onClick={event => {
                    event.stopPropagation();
                    navigate(`/promotions/levels/${item.id}`);
                  }}
                  className={styles.levelTableBtnLink}
                >
                  <Edit />
                </IconButton>
                <ModalConfirm
                  title={`${t('sure_delete_level')}?`}
                  description={t('impossible_return_level')}
                  onConfirm={() => {
                    deleteLevel(item.id);
                  }}
                >
                  {open => (
                    <IconButton
                      // @ts-ignore
                      onClick={event => {
                        event.stopPropagation();
                        open();
                      }}
                    >
                      <Basket />
                    </IconButton>
                  )}
                </ModalConfirm>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default LevelsTable;
