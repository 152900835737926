import Category from './Category/Category';

import styles from './CategoryList.module.scss';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useTranslation } from 'react-i18next';

export interface IKnowledge {
  id: number;
  asset_id: number;
  name: string;
  description: string;
  svg_icon: string;
  articles_count: number;
}
interface IDataProps {
  data: IKnowledge[];
  mobile?: boolean;
}

const CategoryList = ({ data, mobile = false }: IDataProps) => {
  const { t } = useTranslation();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();

  const client = useQueryClient();

  const { mutateAsync: deleteCategory } = useMutation(
    async (id: number) => {
      const response = await instance.delete(
        `admin/knowledge-base-categories/${id}`
      );

      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['knowledge-base']);
        toastSuccess(t('category_successfully_deleted'));
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <ul className={mobile ? styles.mobileCategoryList : styles.categoryList}>
      {data.map(item => {
        return (
          <Category
            key={item.id}
            data={item}
            onClick={deleteCategory}
            mobile={mobile}
          />
        );
      })}
    </ul>
  );
};

export default CategoryList;
