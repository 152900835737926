import i18next from 'i18next'
import { Dayjs } from 'dayjs'

export const validate = values => {
    const errors = {}

    // Name
    if (!values.name) {
        errors.name = i18next.t('validate_name')
    }

    // Description
    if (!values.description) {
        errors.description = i18next.t('description_validate')
    }

    // Asset
    if (!values.asset) {
        errors.asset = i18next.t('validate_asset')
    }

    // Accommodations
    if (!values.accommodations.length) {
        errors.accommodations = i18next.t('validate_accommodations')
    }

    // Organizations
    if (!values.organizations.length) {
        errors.organizations = i18next.t('validate_organizations')
    }

    // Start Date validation
    if (!values.date.startDate) {
        errors.startDate = i18next.t('specify_start_date')
    }

    // End Date validation
    if (!values.date.endDate) {
        errors.endDate = i18next.t('specify_end_date')
    }

    // End Point validation
    if (!values.points) {
        errors.points = i18next.t('validate_points_quantity')
    } else if (!/^\d+$/.test(values.points)) {
        errors.points = i18next.t('must_be_number')
    } else if (parseInt(values.points) <= 0) {
        errors.points = i18next.t('must_be_greater_than_zero')
    }

    // End action validation
    if (!values.days_action) {
        errors.days_action = i18next.t('validate_points_burned')
    } else if (!/^\d+$/.test(values.days_action)) {
        errors.days_action = i18next.t('must_be_number')
    } else if (parseInt(values.days_action) <= 0) {
        errors.days_action = i18next.t('must_be_greater_than_zero')
    }

    // Image
    if (!values.image.full_photo_url) {
        errors.image = i18next.t('enter_image')
    }

    return errors
}
