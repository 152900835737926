import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import Checkmark from '~/assets/svg/newSvg/mark-icon.svg?react';
import { IconButton, InputBar } from '~/ui';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { validateCondition } from '~/utils/validateCondition';

import classNames from 'classnames';
import styles from './PromotionConditionTable.module.scss';
import { ICondition } from '~/components/PromotionsBoard/StockBoard/StockBoard';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';
import { useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';

interface ISlotTableProps {
  sort: any;
  setSort: ({ field, direction }: any) => void;
  conditions: ICondition[];
  isAdding: boolean;
  disabled?: boolean;
  onDeleteAddingSlotClick: () => void;
  handleAddPromotion: (condition: ICondition) => void;
  handleDeleteCondition: (id: string, item: { rule_id: number }) => void;
  handleEditCondition: (condition: ICondition) => void;
}

interface IRulesList {
  id: number;
  description: string;
}

const PromotionConditionTable = ({
  sort,
  setSort,
  conditions,
  isAdding,
  disabled = false,
  onDeleteAddingSlotClick,
  handleAddPromotion,
  handleDeleteCondition,
  handleEditCondition,
}: ISlotTableProps) => {
  const { t } = useTranslation();
  const [editingSlotId, setEditingSlotId] = useState<string>('');
  const [rulesOptions, setRulesOptions] = useState([]);
  const toastError = useToastError();
  const queryClient = useQueryClient();

  const {
    values,
    handleChange,
    resetForm,
    handleSubmit,
    setFieldValue,
    errors,
    isValid,
    setValues,
  } = useFormik<ICondition>({
    initialValues: {
      id: '',
      department: { id: 0, name: '' },
      description: { id: 0, name: '' },
      meaning: '',
    },
    validate: validateCondition,
    onSubmit: values => {
      handleAddPromotion({
        ...values,
        id: uuidv4(),
        meaning: values.meaning,
        department: values.department,
        description: values.description,
      });
      handleResetForm();
    },
  });

  const loadRulesById = async (id: number | undefined) => {
    if (!id) return;
    try {
      const { data } = await instance.get('admin/promo-loyalty-rules', {
        params: { module_id: id },
      });
      queryClient.setQueryData(['loyalty-rules', id], undefined);

      const rules = data.map((item: IRulesList) => {
        return {
          id: item.id,
          name: item.description,
        };
      });

      setRulesOptions(rules);
    } catch (error) {
      toastError(error);
    }
  };

  const handleResetForm = () => {
    resetForm();
    onDeleteAddingSlotClick();
  };

  const handleSubmitEditing = () => {
    handleEditCondition({
      ...values,
      id: editingSlotId,
      department: values.department,
      description: values.description,
      meaning: values.meaning,
    });
    setEditingSlotId('');
    resetForm();
  };

  const handleResetEditing = () => {
    resetForm();
  };

  useEffect(() => {
    if (values.department.id === 0) return;
    loadRulesById(values.department.id);
  }, [values.department.id]);

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead field="department" className={styles.departmentHead}>
            {t('chapter')}
          </TableCellHead>
          <TableCellHead className={styles.slotsCell} field="condition">
            {t('condition')}
          </TableCellHead>
          <TableCellHead field="meaning" className={styles.meaningHead}>
            {t('meaning')}
          </TableCellHead>
          <TableCellHead field="actions">{t('actions')}</TableCellHead>
        </TableHead>
        <TableBody>
          {isAdding && (
            <TableRow style={styles.editRow}>
              <TableCell className={styles.chapter}>
                <CustomQuerySelect
                  placeholder={t('validate_chaptert')}
                  value={values.department}
                  query="admin/promo-loyalty-modules"
                  queryKey={['promo-loyalty-modules']}
                  params={{}}
                  onChange={value => {
                    setFieldValue('department', value),
                      setFieldValue('description', { id: 0, name: '' });
                  }}
                  error={errors.department as string}
                  className={styles.chapterSelect}
                />
              </TableCell>
              <TableCell className={styles.tableEditCell}>
                <CustomSelect
                  placeholder=""
                  value={values.description}
                  options={rulesOptions}
                  onChange={values => setFieldValue('description', values)}
                  className={styles.rulesInput}
                  error={errors.description as string}
                  disabled={values.department.id === 0}
                />
              </TableCell>
              <TableCell className={classNames(styles.statusCell)}>
                <InputBar
                  name="meaning"
                  value={values.meaning}
                  onChange={handleChange}
                  errors={errors.meaning}
                  error={!!errors.meaning}
                />
              </TableCell>
              <TableCell>
                <div className={styles.slotTableActions}>
                  <IconButton onClick={handleSubmit} isDisabled={!isValid}>
                    <Checkmark />
                  </IconButton>
                  <IconButton onClick={handleResetForm}>
                    <Basket />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          )}
          {conditions?.map(item => {
            if (editingSlotId === item.id) {
              return (
                <TableRow style={styles.editRow} key={item.id}>
                  <TableCell className={styles.chapter}>
                    <CustomQuerySelect
                      placeholder={t('validate_chaptert')}
                      value={values.department}
                      query="admin/promo-loyalty-modules"
                      queryKey={['promo-loyalty-modules']}
                      params={{}}
                      onChange={value => {
                        setFieldValue('department', value),
                          setFieldValue('description', { id: 0, name: '' });
                      }}
                      error={errors.department as string}
                      className={styles.chapterSelect}
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell className={styles.tableEditCell}>
                    <CustomSelect
                      placeholder=""
                      value={values.description}
                      options={rulesOptions}
                      onChange={values => setFieldValue('description', values)}
                      className={styles.rulesInput}
                      error={errors.description as string}
                      disabled={values.department.id === 0 || disabled}
                    />
                  </TableCell>
                  <TableCell
                    className={classNames(styles.statusCell, styles.meaning)}
                  >
                    <InputBar
                      name="meaning"
                      value={values.meaning}
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <div className={styles.slotTableActions}>
                      <IconButton onClick={handleSubmitEditing}>
                        <Checkmark />
                      </IconButton>
                      <IconButton onClick={handleResetEditing}>
                        <Basket />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              );
            }
            return (
              <TableRow key={item?.id}>
                <TableCell className={styles.chapter}>
                  {item.department.name}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {item.description.name}
                </TableCell>
                <TableCell
                  className={classNames(styles.statusCell, styles.meaning)}
                >
                  {item.meaning}
                </TableCell>
                <TableCell>
                  <div className={styles.slotTableActions}>
                    <IconButton
                      onClick={() => {
                        setEditingSlotId(item.id);
                        setValues(item);
                      }}
                      className={styles.entertainmentTableBtnLink}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        handleDeleteCondition(item.id, {
                          rule_id: item.description.id,
                        });
                      }}
                    >
                      <Basket />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default PromotionConditionTable;
