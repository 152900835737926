import { useTranslation } from 'react-i18next';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import SlotsBoard from '~/components/SlotsBoard/SlotsBoard';

const Slots = () => {
  const { t } = useTranslation();

  return (
    <Page heading={<Heading text={t('slots')} />}>
      <SlotsBoard />
    </Page>
  );
};

export default Slots;
