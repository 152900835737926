import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import ModalConfirm from '../../Shared/ModalConfirm';
import { IconButton } from '~/ui';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';

import styles from './EntertainmentTable.module.scss';
import { IEntertainment } from '../EntertainmentBoard';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';
import TooltipText from '~/components/Shared/TooltipText/TooltipText';
import EntertainmentStatusBadge from './EntertainmentStatusBadge/EntertainmentStatusBadge';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastSuccess } from '~/utils/useToastSuccess';

interface IEntertainmentProps {
  places: IEntertainment[];
  sort: any;
  setSort: ({ field, direction }: any) => void;
  selectedItems: number[];
  handleToggleAll: () => void;
  handleToggle: (id: number) => void;
  refetch: () => void;
}

const EntertainmentTable = ({
  places,
  sort,
  setSort,
  selectedItems,
  handleToggleAll,
  handleToggle,
  refetch,
}: IEntertainmentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useQueryClient();
  const toastSuccess = useToastSuccess();

  const { mutate: deleteSlot } = useMutation(
    async (itemId: string) => {
      const response = await instance.delete(
        `admin/entertainment-objects/${itemId}`
      );
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['entertainments']);
        refetch();
        toastSuccess(t('accommodation_removed'));
      },
    }
  );

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead
            className={styles.tableCellHead}
            sortable={false}
            field="checkbox"
          >
            <CustomCheckbox
              isChecked={
                selectedItems.length === places?.length && !!places?.length
              }
              // @ts-ignore
              onChange={event => {
                event.stopPropagation();
                handleToggleAll();
              }}
            />
          </TableCellHead>
          <TableCellHead field="name">{t('name')}</TableCellHead>
          <TableCellHead field="address">{t('address')}</TableCellHead>
          <TableCellHead field="status_id">{t('status')}</TableCellHead>
          <TableCellHead field="actions" sortable={false}>
            {t('actions')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {places?.map(item => {
            return (
              <TableRow
                onClick={() => navigate(`/entertainment/${item.id}/slots`)}
                key={item?.id}
                style={styles.row}
              >
                <TableCell>
                  <CustomCheckbox
                    isChecked={selectedItems.includes(item.id)}
                    onChange={() => handleToggle(item.id)}
                    // @ts-ignore
                    onClick={event => {
                      event.stopPropagation();
                    }}
                  />
                </TableCell>
                <TableCell>{item?.name}</TableCell>
                <TableCell className={styles.tableCell}>
                  <TooltipText text={item.address} />
                </TableCell>
                <TableCell className={styles.statusCell}>
                  <EntertainmentStatusBadge status={item.status} />
                </TableCell>
                <TableCell>
                  <div className={styles.entertainmentTableActions}>
                    <IconButton
                      // @ts-ignore
                      onClick={event => {
                        event.stopPropagation();
                        navigate(`/entertainment/${item.id}`);
                      }}
                      className={styles.entertainmentTableBtnLink}
                    >
                      <Edit />
                    </IconButton>
                    <ModalConfirm
                      title={t('delete_selected_object_question')}
                      description={t('impossible_restore_object')}
                      onConfirm={() => {
                        deleteSlot(String(item.id));
                      }}
                    >
                      {open => (
                        <IconButton
                          // @ts-ignore
                          onClick={event => {
                            event.stopPropagation();
                            open();
                          }}
                        >
                          <Basket />
                        </IconButton>
                      )}
                    </ModalConfirm>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default EntertainmentTable;
