import { IAsset } from '../../Storage';
import FolderIcon from '~/assets/svg/newSvg/folder.svg?react';
import styles from './FolderCard.module.scss';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import LoaderSpinner from '~/components/Shared/LoaderSpinner/LoaderSpinner';

interface IFolderProps {
  asset: IAsset;
}

const FolderCard = ({ asset }: IFolderProps) => {
  const { t } = useTranslation();
  const toastError = useToastError();

  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/documents', {
        params: {
          asset_id: asset.id,
        },
      });
      return data;
    },
    queryKey: ['documents', asset.id],
    onError: error => {
      toastError(error);
    },
  });

  const objectLength = data?.data?.length || 0;

  return (
    <div className={styles.card}>
      <FolderIcon />
      <p className={styles.name}>{asset.name}</p>
      {data && !isLoading ? (
        <span className={styles.objects}>
          {t('objects')} {`(${objectLength})`}
        </span>
      ) : (
        <LoaderSpinner />
      )}
    </div>
  );
};

export default FolderCard;
