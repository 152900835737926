import { IAccommodation } from '~/components/UserForm/BusinessTripHistoryTable/BusinessTripTable/BusinessTripTable';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';
import { IconsContainer } from '../CreateCategory/IconsContainer/IconsContainer';
import {
  IEntertainment,
  IUpdateEntertainment,
} from '../EditEntertainmentPage/EditEntertainmentPage';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';
import DeleteSvg from '~/assets/svg/newSvg/delete.svg?react';
import { IAddedSlot } from './SlotBoard/SlotTable/SlotTable';
import { Button, IconButton, InputBar } from '~/ui';
import TimeInputBar from '~/ui/TimeInputBar/TimeInputBar';
import { validate } from '~/utils/validateEntertainment';
import { Modal } from '~/components/Shared/Modal/Modal';
import PhotoIcon from './images/image-icon.svg?react';
import SlotBoard from './SlotBoard/SlotBoard';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactDOMServer from 'react-dom/server';
import { useEffect, useState } from 'react';
import { instance } from '~/utils/api/api';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import { DatePicker } from 'antd';
import i18n from '~/i18n';

import styles from './EditEntertainment.module.scss';
import { SvgIcon } from '~/ui/SvgIcon/SvgIcon';
import { svgIcons } from '~/utils/getIcons';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

interface Day {
  id: number;
  name: string;
  displayName: string;
}

const getDays = () => {
  const t = i18n.t;
  return [
    { id: 0, name: t('monday'), displayName: 'Monday' },
    { id: 1, name: t('tuesday'), displayName: 'Tuesday' },
    { id: 2, name: t('wednesday'), displayName: 'Wednesday' },
    { id: 3, name: t('thursday'), displayName: 'Thursday' },
    { id: 4, name: t('friday'), displayName: 'Friday' },
    { id: 5, name: t('saturday'), displayName: 'Saturday' },
    { id: 6, name: t('sunday'), displayName: 'Sunday' },
  ] as Day[];
};

const EditEntertainment = ({
  data,
  isEntertainmentLoading,
  updateEntertainment,
}: {
  data: IEntertainment;
  isEntertainmentLoading: boolean;
  updateEntertainment: (values: IUpdateEntertainment) => void;
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();

  const { data: objectStatuses, isLoading: isStatusesLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(
        'admin/entertainment-objects-statuses'
      );
      return data;
    },
    queryKey: ['object-statuses'],
  });

  const days = getDays();

  const handleSetIsModalOpen = () => {
    setIsModalOpen(false);
  };

  const {
    values,
    setFieldValue,
    errors,
    handleSubmit,
    touched,
    handleBlur,
    setFieldTouched,
    setValues,
  } = useFormik({
    initialValues: {
      name: '',
      asset: {
        id: 0,
        name: '',
      },
      address: '',
      slots_count: '',
      days: [] as { id: number; name: string }[],
      startTime: null as Dayjs | null,
      endTime: null as Dayjs | null,
      startDate: null as Dayjs | null,
      endDate: null as Dayjs | null,
      status: null as null | { id: number; type: string },
      accommodations: [] as IAccommodation[],
      organizations: [{ id: 0, name: '' }],
      icon: null as null | JSX.Element,
      iconId: null,
      slots: [] as IAddedSlot[],
    },

    validate,

    onSubmit: async values => {
      const stringIcon =
        values.icon !== null
          ? ReactDOMServer.renderToStaticMarkup(values.icon)
          : '';

      const accommodation_ids = values.accommodations.map(item => item.id);
      const organization_ids = values.organizations.map(item => item.id);

      const entertainment_object = {
        name: values.name,
        asset_id: values.asset.id,
        start_time: values.startTime
          ? (values.startTime as Dayjs).format('HH:mm')
          : null,
        end_time: values.endTime
          ? (values.endTime as Dayjs).format('HH:mm')
          : null,
        start_date: values.startDate
          ? (values.startDate as Dayjs).format('YYYY-MM-DD')
          : null,
        end_date: values.endDate
          ? (values.endDate as Dayjs).format('YYYY-MM-DD')
          : null,
        address: values.address,
        accommodation_ids,
        organization_ids,
        svg: stringIcon,
        status_id: values.status ? (values.status as { id: number }).id : null,
        slots_count: values.slots_count,
        working_days: values.days,
      };
      const entertainment_object_time_slots = values.slots.map(
        (slot: IAddedSlot) => {
          return {
            start_time: slot.startTime
              ? (slot.startTime as Dayjs).format('HH:mm')
              : null,
            end_time: slot.endTime
              ? (slot.endTime as Dayjs).format('HH:mm')
              : null,
            max_users_count: Number(slot.slotsCount),
            status_id: slot.status ? slot.status.id : null,
          };
        }
      );

      updateEntertainment({
        entertainment_object,
        entertainment_object_time_slots,
      });
    },
  });

  useEffect(() => {
    if (data) {
      setValues({
        name: data?.name,
        asset: data?.asset,
        address: data?.address,
        slots_count: '',
        days: data?.working_days,
        startTime: dayjs(data?.start_time, 'HH:mm'),
        endTime: dayjs(data?.end_time, 'HH:mm'),
        startDate: dayjs(data?.start_date),
        endDate: dayjs(data?.end_date),
        status: data?.status,
        accommodations: data?.accommodations,
        organizations: data?.organizations,
        icon: <SvgIcon svgString={data?.svg} className={styles.comingIcon} />,
        iconId: null,
        slots: data?.time_slots.map(slot => ({
          id: slot.id.toString(),
          startTime: dayjs(slot.start_time, 'HH:mm'),
          endTime: dayjs(slot.end_time, 'HH:mm'),
          slotsCount: String(slot.max_users_count),
          status: slot.status,
        })),
      });
    }
  }, [data]);

  const handleAddSlot = async (slot: IAddedSlot) => {
    try {
      await instance.post('admin/entertainment-time-slots', {
        entertainment_object_time_slots: {
          entertainment_object_id: id,
          start_time: slot.startTime?.format('HH:mm'),
          end_time: slot.endTime?.format('HH:mm'),
          max_users_count: slot.slotsCount,
          status_id: slot.status ? slot.status.id : null,
        },
      });
      setFieldValue('slots', [...values.slots, slot]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteSlot = async (id: string) => {
    try {
      await instance.delete(`admin/entertainment-time-slots/${id}`);
      setFieldValue(
        'slots',
        values.slots.filter(slot => slot.id !== id)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditSlot = async (slot: IAddedSlot) => {
    try {
      await instance.put(`admin/entertainment-time-slots/${slot.id}`, {
        entertainment_object_time_slots: {
          start_time: slot.startTime?.format('HH:mm'),
          end_time: slot.endTime?.format('HH:mm'),
          max_users_count: slot.slotsCount,
          status_id: slot.status ? slot.status.id : null,
        },
      });
      setFieldValue(
        'slots',
        values.slots.map(item => {
          if (slot.id === item.id) {
            return slot;
          }
          return item;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (values.iconId === null) return;
    const Icon = svgIcons[values.iconId].icon;
    setFieldValue('icon', <Icon className={styles.icon} />);
  }, [values.iconId]);

  const isLoading = isStatusesLoading || isEntertainmentLoading;

  return (
    <>
      <div className={styles.inputs}>
        <InputBar
          label={t('object_name')}
          name="name"
          placeholder="GYM24"
          value={values.name}
          onChange={event => setFieldValue('name', event.target.value)}
          required
          star
          onBlur={handleBlur}
          error={touched.name && Boolean(errors.name)}
          errors={errors.name}
          disabled={isLoading}
        />
        <CustomQuerySelect
          label={t('asset')}
          query="admin/assets"
          queryKey={['assets', {}]}
          placeholder={t('validate_asset')}
          params={null}
          value={values.asset}
          onChange={value => {
            setFieldValue('asset', value);
            setFieldValue('accommodations', []);
          }}
          required
          error={touched.asset ? (errors.asset as string) : ''}
          onTouch={() => setFieldTouched('asset', true)}
          disabled={isLoading}
        />
        <div className={styles.timeCell}>
          <CustomSelect
            label={t('schedule')}
            options={days}
            value={values.days}
            onChange={value => setFieldValue('days', value)}
            multiselect
            placeholder={t('select_days')}
            required
            className={styles.scheduleSelect}
            onTouch={() => setFieldTouched('days', true)}
            error={touched.days ? (errors.days as string) : undefined}
            disabled={true}
          />
          <label className={styles.timeInput}>
            <span className={styles.inputLabel}>{t('repeat_schedule')}</span>
            <DatePicker.RangePicker
              className={classNames(
                styles.datepicker,
                isLoading && styles.datepickerLoading
              )}
              format="DD.MM.YYYY"
              value={[values.startDate, values.endDate]}
              onChange={date => {
                if (date && date[0] && date[1]) {
                  setFieldValue('startDate', date[0]);
                  setFieldValue('endDate', date[1]);
                } else {
                  setFieldValue('startDate', dayjs().startOf('day'));
                  setFieldValue('endDate', dayjs().endOf('day'));
                }
              }}
              placeholder={[t('date'), t('date')]}
              disabled={true}
            />
          </label>
          <TimeInputBar
            label={t('object_working_time')}
            startTime={values.startTime}
            endTime={values.endTime}
            onStartTimeChange={value => setFieldValue('startTime', value)}
            onEndTimeChange={value => setFieldValue('endTime', value)}
            required
            disabled={isLoading}
          />
        </div>
        <InputBar
          name="address"
          label={t('address')}
          placeholder="ул. Некрасова, 12"
          value={values.address}
          onChange={event => setFieldValue('address', event.target.value)}
          required
          star
          onBlur={handleBlur}
          error={touched.address && !!errors.address}
          errors={errors.address}
          disabled={isLoading}
        />
        <CustomSelect
          label={t('status')}
          value={values.status}
          onChange={value => {
            setFieldValue('status', value);
          }}
          options={objectStatuses}
          placeholder={t('select_status')}
          required
          onTouch={() => setFieldTouched('status', true)}
          error={touched.status ? (errors.status as string) : ''}
          disabled={isLoading}
        />
        <div className={styles.accommodationInput}>
          <CustomQuerySelect
            label={t('accommodation_facility')}
            onChange={value => {
              setFieldValue('accommodations', value);
            }}
            value={values.accommodations}
            query="admin/accommodations"
            queryKey={[
              'accommodations-100',
              {
                sort: '',
                perPage: 100,
                field: '',
                page: 1,
                asset_id: values.asset.id,
              },
            ]}
            params={{
              sort: '',
              perPage: 100,
              field: '',
              page: 1,
              asset_id: values.asset.id,
            }}
            required
            placeholder={t('to_choose')}
            multiselect
            isSearchEnabled
            onTouch={() => setFieldTouched('accommodations', true)}
            error={
              touched.accommodations ? (errors.accommodations as string) : ''
            }
            disabled={!values.asset.id && !values.asset.name && isLoading}
          />
        </div>
        <div className={styles.organizationsWrapper}>
          <CustomQuerySelect
            label={t('organizations')}
            query="admin/organization"
            onChange={value => {
              setFieldValue('organizations', value);
            }}
            placeholder={t('validate_organizations')}
            value={values.organizations}
            isSearchEnabled
            queryKey={[
              'organizations',
              {
                sort: '',
                perPage: 100,
                field: '',
                page: 1,
              },
            ]}
            params={{
              sort: '',
              perPage: 100,
              field: '',
              page: 1,
            }}
            required
            multiselect
            onTouch={() => setFieldTouched('organizations', true)}
            error={
              touched.organizations ? (errors.organizations as string) : ''
            }
            disabled={!values.asset.id && !values.asset.name && isLoading}
          />
        </div>
      </div>
      <div className={styles.iconBtnWrapper}>
        {values.icon !== null ? (
          <div className={styles.iconWrapper}>
            {values.icon}
            <button
              type="button"
              className={styles.deleteBtd}
              onClick={() => {
                setFieldValue('iconId', null);
                setFieldValue('icon', null);
              }}
            >
              <DeleteSvg />
            </button>
          </div>
        ) : isLoading ? (
          <div className={styles.iconBtnWrapperPlaceholder} />
        ) : (
          <div className={styles.iconBtnWrapper}>
            <IconButton
              onClick={() => setIsModalOpen(true)}
              className={styles.photoPickerButton}
            >
              <PhotoIcon />
              <span className={styles.photoPickerText}>
                {t('choose_category_icon')}
              </span>
            </IconButton>
            {errors.icon && (
              <span className={styles.iconBtnError}>
                {t('choose_category_icon')}
              </span>
            )}
          </div>
        )}
      </div>
      <SlotBoard
        handleAddSlot={handleAddSlot}
        handleDeleteSlot={handleDeleteSlot}
        handleEditSlot={handleEditSlot}
        slots={values.slots}
        isLoading={isLoading}
      />
      {errors.slots && (
        <span className={styles.slotErrorMsg}>{errors.slots as string}</span>
      )}
      <Button
        text={t('save_n_finish')}
        className={styles.saveButton}
        onClick={handleSubmit}
        disabled={
          !values.name ||
          !values.asset.name ||
          !values.days.length ||
          !values.address ||
          !values.status ||
          !values.accommodations.length ||
          !values.icon ||
          !values.slots.length
        }
      />

      <Modal
        modalStyles={styles.photosModal}
        isOpen={isModalOpen}
        onClose={handleSetIsModalOpen}
      >
        <IconsContainer
          onCloseClick={handleSetIsModalOpen}
          setSelectedId={value => {
            setFieldValue('iconId', value);
          }}
        />
      </Modal>
    </>
  );
};

export default EditEntertainment;
