import { phraseValidate } from '../utils/regex'
import i18next from 'i18next'

export const validate = values => {
  const errors = {}

  // name

  if (!values.name) {
    errors.name = i18next.t('announcement_validate')
  } else if (!values.text) {
    errors.text = i18next.t('announcement_text_validate')
  }

  // date

  if (!values.start_date) {
    errors.start_date = i18next.t('begin_activity_validate')
  }

  if (!values.end_date) {
    errors.end_date = i18next.t('end_activity_validate')
  }

  return errors
}
