import { IAccommodation } from '~/components/UserForm/BusinessTripHistoryTable/BusinessTripTable/BusinessTripTable';
import EditEntertainment from '../EditEntertainment/EditEntertainment';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { instance } from '~/utils/api/api';
import { IAsset } from '../Assets/Assets';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useToastError } from '~/utils/useToastError';
import { useTranslation } from 'react-i18next';

export interface ITimeSlot {
  id: number;
  entertainment_object_id: number;
  status_id: number;
  start_time: string;
  end_time: string;
  max_users_count: number;
  status: { id: number; name: string; type: string; is_active: boolean };
}

export interface IEntertainment {
  accommodations: IAccommodation[];
  organizations: IAccommodation[];
  address: string;
  asset: IAsset;
  id: number;
  name: string;
  status: { id: number; name: string; type: string };
  svg: string;
  time_slots: ITimeSlot[];
  start_time: string;
  end_time: string;
  start_date: string;
  end_date: string;
  working_days: { id: number; name: string }[];
}

export interface IUpdateEntertainment {
  entertainment_object: {
    name: string;
    asset_id: number;
    start_time: string | null;
    start_date: string | null;
    end_date: string | null;
    end_time: string | null;
    address: string;
    accommodation_ids: number[];
    svg: string;
    status_id: number | null;
    slots_count: string;
    organization_ids: number[];
    working_days: { id: number; name: string }[];
  };
  entertainment_object_time_slots: {
    start_time: string | null;
    end_time: string | null;
    max_users_count: number;
    status_id: number | null;
  }[];
}

const EditEntertainmentPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useQueryClient();
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();

  const { mutateAsync: updateEntertainment } = useMutation(
    async ({
      entertainment_object,
      entertainment_object_time_slots,
    }: IUpdateEntertainment) => {
      const response = await instance.put(`admin/entertainment-objects/${id}`, {
        entertainment_object,
        entertainment_object_time_slots,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['entertainment', id]);
        toastSuccess(t('entertainment_successfully_updated'));
        navigate('/entertainment');
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { data: entertainment, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(`admin/entertainment-objects/${id}`);
      return data;
    },
    queryKey: ['entertainment', id],
  });

  return (
    <Page heading={<Heading text={entertainment?.name} />}>
      <EditEntertainment
        data={entertainment}
        isEntertainmentLoading={isLoading}
        updateEntertainment={updateEntertainment}
      />
    </Page>
  );
};

export default EditEntertainmentPage;
