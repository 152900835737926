import EntertainmentStatusBadge from '~/components/EntertainmentBoard/EntertainmentTable/EntertainmentStatusBadge/EntertainmentStatusBadge';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import Checkmark from '~/assets/svg/newSvg/mark-icon.svg?react';
import { IconButton, InputBar } from '~/ui';
import TimeInputBar from '~/ui/TimeInputBar/TimeInputBar';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import { validateSlot } from '~/utils/validateSlot';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import { Dayjs } from 'dayjs';

import styles from './SlotTable.module.scss';
import { useState } from 'react';
import classNames from 'classnames';

export interface IAddedSlot {
  id: string;
  startTime: Dayjs | null;
  endTime: Dayjs | null;
  slotsCount: string;
  status: { id: number; name: string; is_active: boolean; type: string };
}

interface ISlotTableProps {
  sort: any;
  setSort: ({ field, direction }: any) => void;
  records: IAddedSlot[];
  isAdding: boolean;
  onDeleteAddingSlotClick: () => void;
  handleAddSlot: (slot: IAddedSlot) => void;
  handleDeleteSlot: (id: string) => void;
  handleEditSlot: (slot: IAddedSlot) => void;
}

const SlotTable = ({
  sort,
  setSort,
  records,
  isAdding,
  onDeleteAddingSlotClick,
  handleAddSlot,
  handleDeleteSlot,
  handleEditSlot,
}: ISlotTableProps) => {
  const { t } = useTranslation();
  const [editingSlotId, setEditingSlotId] = useState<string>('');

  const {
    values,
    setFieldValue,
    resetForm,
    handleSubmit,
    errors,
    isValid,
    setValues,
  } = useFormik({
    initialValues: {
      startTime: null as Dayjs | null,
      endTime: null as Dayjs | null,
      slotsCount: '',
      status: {
        id: 1,
        name: 'Слот активен',
        type: 'active',
        is_active: true,
      },
    },
    validate: validateSlot,
    onSubmit: values => {
      handleAddSlot({
        ...values,
        id: uuidv4(),
        status: {
          ...values.status,
          name: values.status.name || '',
        },
      });
      handleResetForm();
    },
  });

  const handleResetForm = () => {
    resetForm();
    onDeleteAddingSlotClick();
  };

  const handleSubmitEditing = () => {
    handleEditSlot({
      ...values,
      id: editingSlotId,
      status: { ...values.status, name: values.status.name || '' },
    });
    setEditingSlotId('');
    resetForm();
  };

  const handleResetEditing = () => {
    resetForm();
  };

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead field="time">{t('time')}</TableCellHead>
          <TableCellHead className={styles.slotsCell} field="slots">
            {t('slots_amount')}
          </TableCellHead>
          <TableCellHead field="status">{t('status')}</TableCellHead>
          <TableCellHead field="actions">{t('actions')}</TableCellHead>
        </TableHead>
        <TableBody>
          {isAdding && (
            <TableRow style={styles.editRow}>
              <TableCell className={styles.tableEditCell}>
                <TimeInputBar
                  startTime={values.startTime}
                  endTime={values.endTime}
                  labelHidden
                  onStartTimeChange={date => setFieldValue('startTime', date)}
                  onEndTimeChange={date => setFieldValue('endTime', date)}
                  required
                />
                <div className={styles.timeErrors}>
                  <span className={styles.errors__text}>
                    {errors.startTime}
                  </span>
                  <span className={styles.errors__text}>{errors.endTime}</span>
                </div>
              </TableCell>
              <TableCell className={styles.tableEditCell}>
                <InputBar
                  placeholder="10"
                  classNameInput={styles.input}
                  value={values.slotsCount}
                  onChange={event =>
                    setFieldValue('slotsCount', event.target.value)
                  }
                  errors={errors.slotsCount}
                  error={!!errors.slotsCount}
                />
              </TableCell>
              <TableCell className={classNames(styles.statusCell)}>
                <EntertainmentStatusBadge status={values.status} />
              </TableCell>
              <TableCell>
                <div className={styles.slotTableActions}>
                  <IconButton onClick={handleSubmit} isDisabled={!isValid}>
                    <Checkmark />
                  </IconButton>
                  <IconButton onClick={handleResetForm}>
                    <Basket />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          )}
          {records?.map(item => {
            if (editingSlotId === item.id) {
              return (
                <TableRow style={styles.editRow}>
                  <TableCell className={styles.tableEditCell}>
                    <TimeInputBar
                      startTime={values.startTime}
                      endTime={values.endTime}
                      labelHidden
                      onStartTimeChange={date =>
                        setFieldValue('startTime', date)
                      }
                      onEndTimeChange={date => setFieldValue('endTime', date)}
                      required
                    />
                  </TableCell>
                  <TableCell className={styles.tableEditCell}>
                    <InputBar
                      placeholder="10"
                      classNameInput={styles.input}
                      value={values.slotsCount}
                      onChange={event =>
                        setFieldValue('slotsCount', event.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell className={styles.statusCell}>
                    <EntertainmentStatusBadge status={values.status} />
                  </TableCell>
                  <TableCell>
                    <div className={styles.slotTableActions}>
                      <IconButton onClick={handleSubmitEditing}>
                        <Checkmark />
                      </IconButton>
                      <IconButton onClick={handleResetEditing}>
                        <Basket />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              );
            }
            return (
              <TableRow key={item?.id}>
                <TableCell className={styles.tableCell}>
                  {item.startTime?.format('HH:mm')}-
                  {item.endTime?.format('HH:mm')}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {item.slotsCount}
                </TableCell>
                <TableCell className={styles.statusCell}>
                  <EntertainmentStatusBadge status={item.status} />
                </TableCell>
                <TableCell>
                  <div className={styles.slotTableActions}>
                    <IconButton
                      onClick={() => {
                        setEditingSlotId(item.id);
                        setValues(item);
                      }}
                      className={styles.entertainmentTableBtnLink}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteSlot(item.id)}>
                      <Basket />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default SlotTable;
