import { Button, Pagination, Spinner } from '~/ui';
import { t } from 'i18next';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useEffect, useState } from 'react';
import SelectedIcon from '../ImageArtickeContainer/icoms/selected-icon.svg?react';
import styles from './ImageQuillContainer.module.scss';
import classNames from 'classnames';

interface IImage {
  id: number;
  full_photo_url: string;
  path: string;
}

interface IImageQuillProps {
  isOpen: boolean;
  onClose: () => void;
  onInsertImage: (imageUrl: string[]) => void;
}

const ImageQuillContainer = ({
  isOpen,
  onClose,
  onInsertImage,
}: IImageQuillProps) => {
  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState<IImage[]>([]);
  const toastError = useToastError();

  const { data: images, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(
        'admin/knowledge-base-articles-get-images',
        {
          params: { perPage: 9, page },
        }
      );
      return data;
    },
    queryKey: ['articles-images', page],
    onError: error => toastError(error),
    enabled: isOpen,
  });

  const handleSubmit = () => {
    if (!!selectedId.length) {
      const imageUrl = selectedId.map(item => item.full_photo_url);
      onInsertImage(imageUrl);
      onClose();
    }
  };

  const handleSelectImage = (item: IImage) => {
    setSelectedId(prev =>
      prev.some(selected => selected.id === item.id)
        ? prev.filter(selected => selected.id !== item.id)
        : [...prev, item]
    );
  };

  useEffect(() => {
    if (!isOpen) setSelectedId([]);
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles.photoContainer}>
      <div className={styles.headingWrapper}>
        <p className={styles.title}>{t('choose_photo')}</p>
        <div className={styles.closeBtnWrapper}>
          {!!selectedId.length && (
            <Button
              text={t('discard')}
              className={styles.resetBtn}
              onClick={() => setSelectedId([])}
            />
          )}
          <Button
            text={t('close')}
            className={styles.closeBtn}
            onClick={onClose}
          />
        </div>
      </div>
      <div className={styles.imagesWrapper}>
        <ul className={!isLoading ? styles.imagesList : styles.loadingList}>
          {images && !isLoading ? (
            images.data.map((item: IImage) => (
              <li key={item.id} className={styles.imagesItem}>
                <button
                  type="button"
                  onClick={() => handleSelectImage(item)}
                  className={classNames(
                    styles.imageBtn,
                    selectedId.some(selected => selected.id === item.id) &&
                      styles.activeImage
                  )}
                >
                  <img
                    src={item.full_photo_url}
                    alt={item.path}
                    className={styles.image}
                  />
                  {selectedId.some(selected => selected.id === item.id) && (
                    <SelectedIcon className={styles.selectedIcon} />
                  )}
                </button>
              </li>
            ))
          ) : (
            <Spinner />
          )}
        </ul>
        <Pagination
          pageCount={images?.last_page}
          onPageChange={(selectedPage: { selected: number }) =>
            setPage(selectedPage.selected + 1)
          }
        />
      </div>
      <Button
        text={t('confirm')}
        className={styles.submitBtn}
        onClick={handleSubmit}
        disabled={!selectedId}
      />
    </div>
  );
};

export default ImageQuillContainer;
