import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Page from '~/components/Shared/Layout/Page/Page';
import Heading from '~/components/Shared/Heading/Heading';
import { QuestionsBoard } from './QuestionsBoard/QuestionsBoard';
import { SocialForm } from '~/components/SocialForm/SocialForm';
import { Modal } from '~/components/Shared/Modal/Modal';
import styles from './CreateSocial.module.scss';
import { useTranslation } from 'react-i18next';

const CreateSocial = (): JSX.Element => {
  const [_, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpenModal = () => {
    setIsOpen(true);
    setSearchParams({ name: 'questions' });
  };

  useEffect(() => {
    if (!isOpen) {
      setSearchParams(params => {
        params.delete('name');
        return params;
      });
    }
  }, [isOpen]);

  return (
    <Page
      heading={
        <Heading
          text={t('create_social_survey')}
          actions={
            <Link
              to="/social/create/"
              onClick={handleOpenModal}
              className={styles.createBtn}
            >
              {t('create_category')}
            </Link>
          }
        />
      }
    >
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="full">
        <QuestionsBoard />
      </Modal>
      <SocialForm storageKey="create-social" />
    </Page>
  );
};

export default CreateSocial;
