import { IArticle } from '~/utils/types/knowledgeTypes';
import Article from './Article/Article';

import styles from './ArticleList.module.scss';
import { Pagination } from '~/ui';

interface IArtikles {
  page?: number;
  setPage: (value: number) => void;
  data: IArticle[] | undefined;
  mobile?: boolean;
}

interface SelectedPage {
  selected: number;
}

const ArticleList = ({ data, page, setPage, mobile = false }: IArtikles) => {
  const handleSetPage = (selectedPage: SelectedPage) => {
    setPage(selectedPage.selected + 1);
  };

  return (
    <div className={styles.listWrapper}>
      <ul className={mobile ? styles.mobileArticleList : styles.articleList}>
        {data &&
          data.map(item => (
            <Article data={item} key={item.id} mobile={mobile} />
          ))}
      </ul>
      {page && !!data?.length && (
        <Pagination pageCount={page} onPageChange={handleSetPage} />
      )}
    </div>
  );
};

export default ArticleList;
