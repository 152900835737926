import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import EditSlotForm from '~/components/EditSlotForm/EditSlotForm';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { instance } from '~/utils/api/api';

const EditSlot = () => {
  const { slotId } = useParams();
  const { t } = useTranslation();
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [perPage, setPerPage] = useState(15);
  const [page, setPage] = useState<number>();

  const params = {
    field: sort.field,
    sort: sort.direction,
    perPage,
    page,
  };

  const { data: appointments } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(
        `admin/entertainment-date-book/${slotId}`,
        { params }
      );
      return data;
    },
    queryKey: ['slot', slotId, params],
    keepPreviousData: true,
  });

  return (
    <Page
      heading={
        <Heading
          text={`${t('slot')} ${dayjs(
            appointments?.date_slot_object.start_time,
            'HH:mm'
          ).format('HH:mm')}-${dayjs(
            appointments?.date_slot_object.end_time,
            'HH:mm'
          ).format('HH:mm')} ${t('from')} ${dayjs(
            appointments?.date_slot_object.date
          ).format('DD.MM.YYYY')}
          `}
        />
      }
    >
      <EditSlotForm
        appointments={appointments}
        sort={sort}
        setSort={setSort}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </Page>
  );
};

export default EditSlot;
