import { Board } from '~/components/Shared/Layout/Board/Board';
import { Action } from '~/ui/Actions';
import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import { useTranslation } from 'react-i18next';

import styles from './PromotionConditionBoard.module.scss';
import { useState } from 'react';
import PromotionConditionTable from './PromotionConditionTable/PromotionConditionTable';
import { ICondition } from '~/components/PromotionsBoard/StockBoard/StockBoard';

interface IConditionsProps {
  conditions: ICondition[];
  handleAddPromotion: (values: ICondition) => void;
  handleDeleteCondition: (id: string, item: { rule_id: number }) => void;
  handleEditCondition: (values: ICondition) => void;
  disabled?: boolean;
  isEdit?: boolean;
}

const PromotionConditionBoard = ({
  conditions,
  handleAddPromotion,
  handleDeleteCondition,
  handleEditCondition,
  disabled = false,
  isEdit = false,
}: IConditionsProps) => {
  const { t } = useTranslation();
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [isAddingSlot, setIsAddingSlot] = useState(false);

  const toggleAddingSlot = () => {
    setIsAddingSlot(prevState => !prevState);
  };

  return (
    <Board classNames={styles.board}>
      <div className={styles.conditionBoardHeader}>
        <Action
          variant="success"
          onClick={toggleAddingSlot}
          className={styles.actionBtn}
          disabled={disabled}
        >
          <AddIcon /> {t('add_condition')}
        </Action>
      </div>
      <PromotionConditionTable
        disabled={isEdit}
        sort={sort}
        setSort={setSort}
        conditions={conditions}
        isAdding={isAddingSlot}
        onDeleteAddingSlotClick={toggleAddingSlot}
        handleAddPromotion={handleAddPromotion}
        handleDeleteCondition={handleDeleteCondition}
        handleEditCondition={handleEditCondition}
      />
    </Board>
  );
};

export default PromotionConditionBoard;
