import GearIcon from '~/assets/svg/knowledge/gear.svg?react';
import ForkIcon from '~/assets/svg/knowledge/fork.svg?react';
import BinocularsIcon from '~/assets/svg/knowledge/binoculars.svg?react';
import BulbIcon from '~/assets/svg/knowledge/bulb.svg?react';
import DefaultKeyIcon from '~/assets/svg/knowledge/default-key.svg?react';
import BedIcon from '~/assets/svg/knowledge/bed.svg?react';
import KeyIcon from '~/assets/svg/knowledge/key.svg?react';
import KeyboardIcon from '~/assets/svg/knowledge/keyboard.svg?react';
import LoadIcon from '~/assets/svg/knowledge/load.svg?react';
import LoadingIcon from '~/assets/svg/knowledge/loading.svg?react';
import NotificationIcon from '~/assets/svg/knowledge/notification.svg?react';
import PhotoIcon from '~/assets/svg/knowledge/photo.svg?react';
import QuestionIcon from '~/assets/svg/knowledge/question.svg?react';
import SettingsIcon from '~/assets/svg/knowledge/settings.svg?react';
import StarSearchIcon from '~/assets/svg/knowledge/star-search.svg?react';
import SupportIcon from '~/assets/svg/knowledge/support.svg?react';
import LockIcon from '~/assets/svg/knowledge/lock.svg?react';

export const svgIcons = [
  { id: 0, icon: GearIcon },
  { id: 1, icon: ForkIcon },
  { id: 2, icon: BinocularsIcon },
  { id: 3, icon: BulbIcon },
  { id: 4, icon: DefaultKeyIcon },
  { id: 5, icon: BedIcon },
  { id: 6, icon: KeyIcon },
  { id: 7, icon: KeyboardIcon },
  { id: 8, icon: LoadIcon },
  { id: 9, icon: LoadingIcon },
  { id: 10, icon: NotificationIcon },
  { id: 11, icon: PhotoIcon },
  { id: 12, icon: QuestionIcon },
  { id: 13, icon: SettingsIcon },
  { id: 14, icon: StarSearchIcon },
  { id: 15, icon: SupportIcon },
  { id: 16, icon: LockIcon },
];
