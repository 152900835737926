import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import ArticleList from './ArticleList/ArticleList';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputBar, Spinner } from '~/ui';

import styles from './CategoryPage.module.scss';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';

const CategoryPage = () => {
  const { t } = useTranslation();
  const toastError = useToastError();
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue.trim(), 500);
  const [page, setPage] = useState(1);

  const params = {
    category_id: id,
    query: debouncedSearchValue,
    perPage: 6,
    page,
  };

  const { data: articlesData, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/knowledge-base-articles', {
        params,
      });
      return data;
    },
    queryKey: ['knowledge-articles', id, debouncedSearchValue, page],
    onError: error => {
      toastError(error);
    },
  });

  const { data: category, isLoading: isCategoryLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(
        `admin/knowledge-base-categories/${id}`,
        {
          params,
        }
      );
      return data;
    },
    queryKey: ['knowledge-base', id],
    onError: error => {
      toastError(error);
    },
  });

  return (
    <Page
      heading={
        <Heading
          text={
            !isCategoryLoading && (category ? category.name : t('category'))
          }
          classNameContainer={styles.heading}
          actions={
            <Link
              to={`/knowledge-base/${id}/create-article`}
              className={styles.createBtn}
            >
              <AddIcon />
              {t('create_article')}
            </Link>
          }
        />
      }
    >
      {articlesData?.data.length ? (
        <InputBar
          name="title"
          classNameInputWrapper={styles.searchBar}
          label={t('search')}
          value={searchValue}
          placeholder={t('search_by_name')}
          onChange={e => setSearchValue(e.target.value)}
        />
      ) : !isLoading ? (
        <span className={styles.emptyPlaceholder}>
          {t('knowledge_base_categories_empty')}
        </span>
      ) : (
        ''
      )}
      <div className={styles.articleListContainer}>
        {isLoading ? (
          <Spinner />
        ) : (
          <ArticleList
            data={articlesData?.data}
            page={articlesData.last_page}
            setPage={setPage}
          />
        )}
      </div>
    </Page>
  );
};

export default CategoryPage;
