import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import MobileHeading from '~/components/MobileHeading/MobileHeading';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import ArticleList from '../CategoryPage/ArticleList/ArticleList';
import styles from './MobileArticleList.module.scss';
import { InputBar } from '~/ui';
import { useInView } from 'react-intersection-observer';
import LoaderSpinner from '~/components/Shared/LoaderSpinner/LoaderSpinner';
import SpinnerCircle from '~/ui/SpinnerCircle/SpinnerCircle';

const MobileArticlesPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { t } = useTranslation();
  const toastError = useToastError();
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue.trim(), 500);
  const [page, setPage] = useState(1);
  const { ref, inView, entry } = useInView();

  const params = {
    category_id: id,
    query: debouncedSearchValue,
    perPage: 5,
    page,
  };

  const fetchArticles = async ({ pageParam = 1 }) => {
    const { data } = await instance.get('admin/knowledge-base-articles', {
      params: {
        category_id: id,
        query: debouncedSearchValue,
        perPage: 5,
        page: pageParam,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading: isArticlesLoading,
  } = useInfiniteQuery({
    queryKey: ['knowledge-articles-list', id, debouncedSearchValue],
    queryFn: fetchArticles,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.data.length !== 0 ? allPages.length + 1 : undefined;
    },
    onError: error => {
      toastError(error);
    },
  });

  const dataMap = data?.pages.flatMap(page => page.data);

  const { data: category } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(
        `admin/knowledge-base-categories/${id}`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    },
    queryKey: ['knowledge-data', id],
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    onError: error => {
      toastError(error);
    },
  });

  useEffect(() => {
    if (entry && inView) {
      fetchNextPage();
    }
  }, [entry]);

  return (
    <main className={styles.mainContainer}>
      <MobileHeading title={category ? category.name : t('category')} />
      <div className={styles.row} />
      <div className={styles.contentWrapper}>
        <InputBar
          name="title"
          classNameInputWrapper={styles.searchBar}
          label=""
          value={searchValue}
          placeholder={t('search_by_positions')}
          onChange={e => setSearchValue(e.target.value)}
        />
        {(isArticlesLoading && !data) || isError ? (
          <div className={styles.spinnerWrapper}>
            <SpinnerCircle />
          </div>
        ) : (
          <ArticleList mobile data={dataMap} setPage={setPage} />
        )}
        {isFetchingNextPage ? (
          <LoaderSpinner />
        ) : hasNextPage ? (
          <div ref={ref} />
        ) : null}
      </div>
    </main>
  );
};

export default MobileArticlesPage;
