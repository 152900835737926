import { forwardRef, useImperativeHandle, useRef } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';

import cn from 'classnames';
import styles from './TextEditor.module.scss';

interface IEditorProps {
  className?: string;
  value: string;
  label?: string;
  star?: boolean;
  onChange: (name: string, value: string) => void;
  name: string;
  modules: ReactQuillProps['modules'];
  disabled?: boolean;
  error?: boolean;
  errors?: string;
  maxLength?: number;
  showCounter?: boolean;
  placeholder?: string;
}

const TextEditor = forwardRef<ReactQuill, IEditorProps>(
  (
    {
      className,
      label,
      star,
      value,
      name,
      modules,
      onChange,
      error,
      errors,
      disabled = false,
      showCounter = false,
      maxLength,
      placeholder,
    },
    ref
  ) => {
    const quillRef = useRef<ReactQuill | null>(null);

    useImperativeHandle(ref, () => quillRef.current as ReactQuill, []);

    const getPlainTextLength = (html: string) => {
      return html.replace(/<[^>]*>/g, '').trim().length;
    };

    const handleContentChange = (values: string) => {
      const cleanedValue = /^<p>(<br>)?<\/p>$/.test(values) ? '' : values;
      const textLength = getPlainTextLength(cleanedValue);

      if (maxLength && textLength > maxLength) {
        if (quillRef.current) {
          const quill = quillRef.current.getEditor();
          quill.deleteText(maxLength, textLength);
        }
        return;
      }

      onChange(name, cleanedValue);
    };

    return (
      <div className={styles.quilWrapper}>
        {label && (
          <span className={styles.textarea__text}>
            {label}
            {star ? <span className={styles.textarea__star}>*</span> : null}
          </span>
        )}
        <ReactQuill
          placeholder={placeholder || ''}
          ref={quillRef}
          className={cn('editor', styles.quill, className, {
            [styles.disabled]: disabled,
          })}
          value={value}
          onChange={handleContentChange}
          modules={modules}
          readOnly={disabled}
        />
        {showCounter && maxLength && (
          <span className={styles.counter}>
            {value ? getPlainTextLength(value) : '0'}
            {'/'}
            {maxLength}
          </span>
        )}
        {error ? <span className={styles.errors__text}>{errors}</span> : null}
      </div>
    );
  }
);

export default TextEditor;
