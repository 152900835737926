import { DatePicker } from 'antd';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import styles from './TimeInputBar.module.scss';
import ClockIcon from './images/clock.svg?react';
import ArrowIcon from './images/arrow.svg?react';
import classNames from 'classnames';

interface ITimeInputBarProps {
  label?: string;
  startTime: Dayjs | null;
  endTime: Dayjs | null;
  required?: boolean;
  labelHidden?: boolean;
  disabled?: boolean;
  onStartTimeChange: (time: Dayjs | null) => void;
  onEndTimeChange: (time: Dayjs | null) => void;
}

const TimeInputBar = ({
  label,
  startTime,
  endTime,
  disabled,
  onStartTimeChange,
  onEndTimeChange,
}: ITimeInputBarProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.timeInputBar}>
      {label ? <span className={styles.label}>{label}</span> : ''}
      <div
        className={classNames(
          styles.timeInputs,
          label ? '' : styles.timeInputsNoLabel,
          disabled && styles.timeInputsDisabled
        )}
      >
        <DatePicker.TimePicker
          name="time"
          style={{ boxShadow: 'none' }}
          className={styles.dateLeft}
          format="HH:mm"
          showNow={false}
          placeholder={t('time_select')}
          onChange={dateString => onStartTimeChange(dateString)}
          value={startTime}
          suffixIcon={null}
          minuteStep={5}
          disabled={disabled}
        />
        <ArrowIcon className={styles.arrowIcon} />
        <DatePicker.TimePicker
          name="time"
          style={{ boxShadow: 'none' }}
          className={styles.dateRight}
          format="HH:mm"
          showNow={false}
          placeholder={t('time_select')}
          onChange={dateString => onEndTimeChange(dateString)}
          value={endTime}
          suffixIcon={null}
          minuteStep={5}
          disabled={disabled}
        />
        <ClockIcon className={styles.clockIcon} />
      </div>
    </div>
  );
};

export default TimeInputBar;
