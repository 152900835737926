import { useState } from 'react';
import EntertainmentTable from './EntertainmentTable/EntertainmentTable';
import { Board } from '../Shared/Layout/Board/Board';
import { TableSelect } from '~/ui/TableSelect/TableSelect';
import { Button, Pagination, Spinner } from '~/ui';
import { useTranslation } from 'react-i18next';
import styles from './EntertainmentBoard.module.scss';
import { Action } from '~/ui/Actions';
import { useNavigate } from 'react-router-dom';
import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import ModalConfirm from '../Shared/ModalConfirm';
import { useToastError } from '~/utils/useToastError';

interface SelectedPage {
  selected: number;
}

export interface IEntertainment {
  id: number;
  name: string;
  address: string;
  status: { id: number; name: string; type: string };
}

const EntertainmentBoard = () => {
  const [selectedPlaces, setSelectedPlaces] = useState<number[]>([]);
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [perPage, setPerPage] = useState(15);
  const [page, setPage] = useState<number>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toastError = useToastError();

  const params = {
    sort: sort.direction,
    perPage,
    field: sort.field,
    page,
  };

  const {
    data: entertainments,
    isLoading,
    refetch,
  } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/entertainment-objects', {
        params,
      });
      return data;
    },
    queryKey: ['entertainments', params],
    keepPreviousData: true,
  });

  const handleDeleteObjects = async () => {
    try {
      await instance.delete(`/admin/entertainment-objects-many`, {
        data: {
          entertainment_ids: selectedPlaces,
        },
      });
      refetch();
      setSelectedPlaces([]);
    } catch (error) {
      console.log(error);
      toastError(error);
    }
  };

  const handleSelect = (id: number) => {
    if (selectedPlaces.includes(id)) {
      const filteredUsers = selectedPlaces.filter(
        selectedId => selectedId !== id
      );
      setSelectedPlaces(filteredUsers);
    } else {
      setSelectedPlaces([...selectedPlaces, id]);
    }
  };

  const handleToggleAll = () => {
    if (selectedPlaces.length === entertainments?.data.length) {
      setSelectedPlaces([]);
    } else {
      const userIdArray = entertainments?.data.map((user: any) => user.id);
      setSelectedPlaces(userIdArray);
    }
  };

  return (
    <Board>
      <div className={styles.entertainmentTableOptions}>
        <TableSelect perPage={perPage} setPerPage={setPerPage} />
        <div className={styles.tableHeadRightWrapper}>
          {!!selectedPlaces.length && (
            <ModalConfirm
              title={t('confirm_delete')}
              onConfirm={handleDeleteObjects}
            >
              {open => (
                <Button
                  text={t('delete')}
                  color="red"
                  onClick={open}
                  className={styles.deleteButton}
                />
              )}
            </ModalConfirm>
          )}
          <Action
            variant="success"
            onClick={() => {
              navigate('/entertainment/create');
            }}
          >
            <AddIcon /> {t('create_entertainment')}
          </Action>
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <EntertainmentTable
          places={entertainments?.data}
          sort={sort}
          refetch={refetch}
          setSort={setSort}
          selectedItems={selectedPlaces}
          handleToggle={handleSelect}
          handleToggleAll={handleToggleAll}
        />
      )}
      <div className={styles.foodTablePagination}>
        <TableAmountPage
          firstRow={entertainments?.from}
          lastRow={entertainments?.to}
          total={entertainments?.total}
        />
        <Pagination
          pageCount={entertainments?.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
    </Board>
  );
};

export default EntertainmentBoard;
