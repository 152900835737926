import { useFormik } from 'formik';
import styles from './CloseQuestionForm.module.scss';
import { Button, IconButton, InputBar } from '~/ui';
import AddIcon from '~/assets/svg/newSvg/add.svg?react';
import DeleteIcon from '~/assets/svg/newSvg/basket.svg?react';
import { Switch, Thumb } from '@radix-ui/react-switch';
import { useTranslation } from 'react-i18next';

interface AnswersValue {
  id: number;
  answer: string;
}

interface QuestionValue {
  question: string;
  answers: [] | AnswersValue[];
  multiple: boolean;
  required: boolean;
}

export const CloseQuestionForm = () => {
  const formValues = {
    question: '',
    answers: [
      { id: 1, answer: '' },
      { id: 2, answer: '' },
    ],
    multiple: true,
    required: true,
  };
  const { t } = useTranslation();

  const formik = useFormik<QuestionValue>({
    initialValues: formValues,
    onSubmit: () => {},
  });

  const addAnswer = () => {
    const id = formik.values.answers.length + 1;
    const newAnswer = { id: id, answer: '' };
    formik.setValues({
      ...formik.values,
      answers: [...formik.values.answers, newAnswer],
    });
  };

  const deleteAnswer = (id: number) => {
    formik.setValues({
      ...formik.values,
      answers: formik.values.answers.filter(answer => answer.id !== id),
    });
  };

  return (
    <div className={styles.formWrapper}>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <span className={styles.questionTitle}>{t('question_title')}</span>
        <InputBar
          name="question"
          value={formik.values.question}
          classNameInputWrapper={styles.questionInputWrapper}
          classNameInput={styles.questionInput}
          label=""
          placeholder={t('enter_question')}
          onChange={formik.handleChange}
        />
        <span className={styles.listTitle}>{t('answer_variants')}</span>
        <div className={styles.answersWrapper}>
          <ul className={styles.answersList}>
            {formik.values.answers.length !== 0 &&
              formik.values.answers.map(answer => (
                <li key={answer.id} className={styles.answersItem}>
                  <input
                    type="text"
                    name={answer.id.toString()}
                    className={styles.answerInput}
                    placeholder={t('answer')}
                    onChange={() => {}}
                  />
                  <IconButton
                    type="button"
                    onClick={() => deleteAnswer(answer.id)}
                  >
                    <DeleteIcon className={styles.deleteIcon} />
                  </IconButton>
                </li>
              ))}
          </ul>
          <IconButton
            className={styles.addBtn}
            type="button"
            onClick={addAnswer}
          >
            <AddIcon className={styles.addIcon} />
            {t('add_answer')}
          </IconButton>
        </div>
        <span className={styles.answersHelp}>{t('can_add_ten_more')}.</span>
        <div className={styles.chooseWrapper}>
          <ul className={styles.switchList}>
            <li className={styles.switchItem}>
              <label htmlFor="multiple" className={styles.switchLabel}>
                Выбор нескольких ответов
              </label>
              <Switch
                className={styles.switch}
                id="multiple"
                name="multiple"
                checked={formik.values.multiple}
                onCheckedChange={() => {
                  formik.setFieldValue('multiple', !formik.values.multiple);
                }}
              >
                <Thumb className={styles.thumb} />
              </Switch>
            </li>
            <li className={styles.switchItem}>
              <label htmlFor="required" className={styles.switchLabel}>
                Обязательное поле
              </label>
              <Switch
                className={styles.switch}
                id="required"
                name="required"
                checked={formik.values.required}
                onCheckedChange={() => {
                  formik.setFieldValue('required', !formik.values.required);
                }}
              >
                <Thumb className={styles.thumb} />
              </Switch>
            </li>
          </ul>
        </div>
        <Button
          type="submit"
          text={t('save_n_exit')}
          className={styles.formBtn}
          disabled={!((formik.isValid && formik.dirty) || formik.isSubmitting)}
        />
      </form>
    </div>
  );
};
