import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthentication } from './Authentication/useAuthentication';
import {
  asideBarList,
  asideBarListSetting,
} from './Shared/Layout/Aside/AsideBarList';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { headerBarList } from '~/constants/headerBarList';

interface PrivateRouteProps {
  children: ReactNode;
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const location = useLocation();
  const { authenticated, user } = useAuthentication();
  const isSuperAdmin = checkIfUserSuperAdmin(user?.data.role_name);
  const allowedRoutes = user?.data.sections;
  const headerLinks = headerBarList();

  const defaultPath = asideBarList.find(item => {
    if (!!allowedRoutes?.length) {
      return item.sectionId === allowedRoutes[0].pivot.section_id;
    }
    return { path: '/settings' };
  });

  const currentPath = asideBarList.find(item => {
    return location.pathname.includes(item.path);
  });

  const currentPathHeader = headerLinks.find(item =>
    location.pathname.includes(item.path)
  );

  const currentPathSettings = asideBarListSetting.find(item =>
    location.pathname.includes(item.path)
  );

  const isAccess =
    location.pathname === '/settings' ||
    allowedRoutes?.some(item => {
      return (
        currentPath?.sectionId === item.pivot.section_id ||
        currentPathSettings?.sectionId === item.pivot.section_id ||
        currentPathHeader?.path.includes(item.type)
      );
    });

  if (!authenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else if (authenticated && !isAccess && !isSuperAdmin) {
    return (
      <Navigate
        to={defaultPath?.path || '/settings'}
        state={{ from: location }}
      />
    );
  } else {
    return children;
  }
}

export default PrivateRoute;
