import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToastError } from '~/utils/useToastError';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import styles from './CreatePromotion.module.scss';
import { useTranslation } from 'react-i18next';
import PromotionForm from '~/components/PromotionForm/PromotionForm';
import { instance } from '~/utils/api/api';
import { useToastSuccess } from '~/utils/useToastSuccess';

export interface ICreatePromotionProps {
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  asset_id: number;
  status_id: number;
  burn_count_days: number;
  calculated_points: number;
  piwigo_image_id: number;
  accommodation_ids: number[];
  organization_ids: number[];
  rules: { rule_id: number; value: number }[];
}

const CreatePromotion = () => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { t } = useTranslation();

  const { mutateAsync: updatePromotionMethod } = useMutation(
    async ({
      name,
      description,
      start_date,
      end_date,
      asset_id,
      status_id,
      burn_count_days,
      calculated_points,
      piwigo_image_id,
      accommodation_ids,
      organization_ids,
      rules,
    }: ICreatePromotionProps) => {
      const response = await instance.post('admin/promo-loyalty', {
        loyalty_promo: {
          name,
          description,
          start_date,
          end_date,
          asset_id,
          status_id,
          burn_count_days,
          calculated_points,
          piwigo_image_id,
          accommodation_ids,
          organization_ids,
        },
        rules,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['promotions']);
        toastSuccess(t('stock_successfull_created'));
        navigate(`/promotions`);
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page
      heading={
        <Heading
          text={t('create_promotion')}
          classNameText={styles.createNotificationText}
        />
      }
    >
      <PromotionForm onSubmit={values => updatePromotionMethod(values)} />
    </Page>
  );
};

export default CreatePromotion;
