import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '~/ui';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';

interface ISections {
  id: number;
  name: string;
  type: string;
  pivot: { user_id: number; section_id: number };
}
interface User {
  data: {
    id: number;
    role_name: '' | 'user';
    full_name: string;
    company_name: string | undefined;
    name: string;
    patronymic: string;
    asset_id: number;
    sections: ISections[];
  };
}

export interface AuthenticationContextValue {
  authenticated: boolean;
  login: (email: string, password: string) => void;
  logout: () => void;
  user: User | null;
}

interface Props {
  children?: React.ReactNode;
}

export const AuthenticationContext =
  React.createContext<AuthenticationContextValue>({
    authenticated: false,
    login() {},
    logout() {},
    user: null,
  });

const AUTH_TOKEN = 'AUTH_TOKEN';

export function AuthenticationProvider({ children }: Props): JSX.Element {
  const toastError = useToastError();
  const { t } = useTranslation();

  const [authenticated, setAuthenticated] = useState(() => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (!token) return false;
    instance.defaults.headers.common['authorization'] = `Bearer ${token}`;
    return true;
  });

  const [user, setUser] = useState<User | null>(null);

  const login = useCallback(async (email: string, password: string) => {
    const response = await instance.post('login', { email, password });
    const token = response?.data?.token;

    const userItem: User = await instance.get('admin/get-user-auth', {
      headers: { authorization: `Bearer ${token}` },
    });

    if (userItem?.data?.role_name === 'user') {
      toastError(t('insufficient_rights'), false);
      return;
    }

    localStorage.setItem(AUTH_TOKEN, token);
    instance.defaults.headers.common['authorization'] = `Bearer ${token}`;

    setUser(userItem);
    setAuthenticated(true);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem(AUTH_TOKEN);
    delete instance.defaults.headers.common['authorization'];
    setAuthenticated(false);
    setUser(null);
  }, []);

  useEffect(() => {
    const interceptor = instance.interceptors.response.use(
      response => response,
      error => {
        if (error?.code === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      instance.interceptors.request.eject(interceptor);
    };
  }, [logout]);

  useEffect(() => {
    if (!authenticated || user) {
      return;
    }
    instance.get('admin/get-user-auth').then((userItem: User) => {
      if (userItem.data.role_name === 'user') {
        logout();
      }
      setUser(userItem);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, user]);

  const loading = authenticated && !user;

  return (
    <AuthenticationContext.Provider
      value={{ authenticated, login, logout, user }}
    >
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <Spinner />
        </div>
      ) : (
        children
      )}
    </AuthenticationContext.Provider>
  );
}
