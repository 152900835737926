import cn from 'classnames';
import styles from './StockStatusBadge.module.scss';

interface IStockStatusBadgeProps {
  status: {
    id: number;
    name: string;
    type: string;
  };
}

const StockStatusBadge = ({ status }: IStockStatusBadgeProps) => {
  return (
    <div
      className={cn(
        styles.stockStatusBadge,
        status.type === 'active'
          ? styles.stockStatusBadgeActive
          : status.type === 'archived'
            ? styles.stockStatusBadgeArchive
            : styles.stockStatusBadgeFull
      )}
    >
      {status.name}
    </div>
  );
};

export default StockStatusBadge;
