import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import FileIcon from '~/assets/svg/newSvg/file.svg?react';
import cn from 'classnames';
import styles from './StorageFolderFiles.module.scss';
import { t } from 'i18next';

interface IStorageFiles {
  id: string;
}

interface IData {
  id: number;
  name: string;
  path: string;
  type: string;
  full_path: string;
}

const StorageFolderFiles = ({ id }: IStorageFiles) => {
  const toastError = useToastError();

  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/documents', {
        params: { asset_id: id },
      });
      return data;
    },
    queryKey: ['documents', id],
    onError: error => {
      toastError(error);
    },
  });

  return (
    <div className={styles.filesWrapper}>
      {data && !!data.data.length && !isLoading && (
        <ul className={styles.filesList}>
          {data?.data.map((item: IData) => (
            <li key={item.id} className={styles.fileItem}>
              <div
                className={cn(styles.imageWrapper, {
                  [styles.purpleBg]: item.type === 'xlsx',
                })}
              >
                {item.type === 'xlsx' ? (
                  <FileIcon />
                ) : (
                  <img src={item.full_path} className={styles.img} />
                )}
              </div>
              <p className={styles.fileName}>{`${item.name}.${item.type}`}</p>
            </li>
          ))}
        </ul>
      )}
      {data && !isLoading && data?.data?.length === 0 && (
        <p className={styles.empty}>{t('empty')}</p>
      )}
    </div>
  );
};

export default StorageFolderFiles;
