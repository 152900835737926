import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { BreadcrumbComponentType } from './useBreadcrumbs';
import i18next from 'i18next';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const Accommodation: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ name: string }>(
    ['accommodation', params.accommodationID],
    { enabled: false }
  );

  return data?.name;
};

export const Food: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ name: string }>(['food-item', params.foodID], {
    enabled: false,
  });

  return data?.name;
};

export const Dish: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ data: { wares_name: string } }>(
    ['dish', params.dishID],
    {
      enabled: false,
    }
  );

  return data?.data?.wares_name;
};

export const User: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ full_name: string }>(['user', params.userID], {
    enabled: false,
  });

  return data?.full_name;
};

export const Feedback: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ feedback: { formatted_created_at: string } }>(
    ['feedback', params.feedbackID],
    { enabled: false }
  );

  return `${i18next.t('response')} ${
    data?.feedback?.formatted_created_at || ''
  }`;
};

export const Notification: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ name: string }>(
    ['notification', params.notificationID],
    { enabled: false }
  );

  return data?.name;
};

export const Announcement: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ name: string }>(
    ['announcement', params.announcementID],
    { enabled: false }
  );

  return data?.name;
};

export const Request: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ tickets: { name: string } }>(
    ['request', params.requestID],
    { enabled: false }
  );

  return data?.tickets?.name;
};

export const Asset: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ name: string }>(['asset', params.assetID], {
    enabled: false,
  });

  return data?.name;
};

export const Transaction: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ id: number; created_at: string }>(
    ['transaction', params.transactionID],
    { enabled: false }
  );

  return data && `Заказ #${data.id} от ${data.created_at}`;
};

export const Questionnaire: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ questionnaire_name: string }>(
    ['questionnaireStats', params.questionnaireID],
    {
      enabled: true,
    }
  );

  return data?.questionnaire_name;
};

export const Knowledge: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ id: number; name: string }>(
    ['knowledge-base', params.id],
    {
      enabled: false,
    }
  );
  return data?.name;
};

export const Article: BreadcrumbComponentType = ({ params }) => {
  const queryClient = useQueryClient();
  const { data } = useQuery<{
    id: number;
    title: string;
    category: { name: string };
  }>(['knowledge-articles', params.editArticleId], {
    enabled: false,
  });

  queryClient.setQueryData(
    ['knowledge-base', params.categoryId],
    data?.category
  );

  return data?.title;
};

export const Entertainment: BreadcrumbComponentType = ({ params }) => {
  const { slotId } = useParams();
  const { data } = useQuery<{ id: number; name: string }>(
    ['entertainment', params.id],
    {
      enabled: false,
    }
  );
  if (!data) {
    const queryClient = useQueryClient();
    const data: any = queryClient.getQueriesData(['slot', slotId])?.[0]?.[1];
    if (!data) {
      const data: any = queryClient.getQueriesData(['slots'])?.[0]?.[1];
      return data?.entertainment_objects.name;
    }
    return data?.entertainment_object.name;
  }

  return data?.name;
};

export const EditSlot: BreadcrumbComponentType = ({ params }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const data: any = queryClient.getQueriesData([
    'slot',
    params.slotId,
  ])?.[0]?.[1];

  if (!data) {
    return '';
  }
  return (
    t('slot') +
    ' ' +
    dayjs(data?.date_slot_object.start_time, 'HH:mm').format('HH:mm') +
    '-' +
    dayjs(data?.date_slot_object.end_time, 'HH:mm').format('HH:mm') +
    ' ' +
    t('from') +
    ' ' +
    dayjs(data?.date_slot_object.date).format('DD.MM.YYYY')
  );
};

export const Stock: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ name: string }>(['promotion', params.stockId], {
    enabled: false,
  });

  return data?.name;
};

export const Level: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ name: string }>(['promotion-level', params.id], {
    enabled: false,
  });

  return data?.name;
};

export const StorageAsset: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ name: string }>(['asset', params.assetId], {
    enabled: false,
  });

  return data?.name;
};
