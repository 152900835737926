import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastError } from '~/utils/useToastError';
import { Button, IconButton, InputBar } from '~/ui';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';
import CloseIcon from '~/assets/svg/newSvg/close-cross.svg?react';
import PhotoIcon from '~/assets/svg/knowledge/photo.svg?react';
import { t } from 'i18next';
import { instance } from '~/utils/api/api';
import { useFormik } from 'formik';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useEffect, useMemo, useRef, useState } from 'react';
import { validate } from '~/utils/validateArticle';
import ReactQuill from 'react-quill';
import { Modal } from '~/components/Shared/Modal/Modal';
import ImageArtickeContainer from '~/components/CreateArticle/ImageArtickeContainer/ImageArtickeContainer';
import styles from './EditArticle.module.scss';
import classNames from 'classnames';
import ImageQuillContainer from '~/components/CreateArticle/ImageQuillContainer/ImageQuillContainer';

interface ICreateProps {
  title: string;
  category_id: number;
  content: string;
  image_ids: number[];
  main_photo_id: number | null;
}

interface IPhotos {
  full_photo_url: string;
  id: number;
  path: string;
}

interface IFormik {
  name: string;
  category: { id: number; name: string };
  content: string;
  mainPhoto: IPhotos;
  photos: IPhotos[] | [];
}

const EditArticle = () => {
  const { categoryId, editId } = useParams();
  const navigate = useNavigate();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const quillRef = useRef<ReactQuill | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuilModalOpen, setIsQuilModalOpen] = useState(false);

  const handleInsertImage = (imageUrl: string[]) => {
    const editor = quillRef.current?.getEditor();
    if (quillRef.current) {
      quillRef.current.focus();
    }
    if (editor) {
      const range = editor.getSelection();
      if (range) {
        const customImage = imageUrl
          .map(
            (item, idx) =>
              `<img
                    src="${item}"
                    alt="article image ${idx + 1}"
                  />`
          )
          .join('');
        editor.clipboard.dangerouslyPasteHTML(range.index, customImage);
      }
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ align: [] }],
          ['image'],
          ['link'],
        ],
        handlers: {
          image: () => setIsQuilModalOpen(true),
        },
      },
    }),
    []
  );

  const client = useQueryClient();

  const { mutateAsync: updateArticle } = useMutation(
    async ({
      title,
      category_id,
      content,
      image_ids,
      main_photo_id,
    }: ICreateProps) => {
      const response = await instance.put(
        `admin/knowledge-base-articles/${editId}`,
        {
          title,
          category_id,
          content,
          image_ids,
          main_photo_id,
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['knowledge-articles', editId]);
        toastSuccess(t('article_successfull_updated'));
        navigate(`/knowledge-base/${categoryId}`);
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(
        `admin/knowledge-base-articles/${editId}`
      );
      return data;
    },
    queryKey: ['knowledge-articles', editId],
    onError: error => {
      toastError(error);
    },
  });

  const { data: notificationModules } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/knowledge-base-categories');
      return response.data;
    },
    queryKey: ['base-modules'],
    onError: error => {
      toastError(error);
    },
  });

  const getModuleOptions = (data: {
    [key: string]: {
      id: number;
      name: string;
      status: string;
    };
  }) => {
    if (!data) return [];
    const modulesOptions = Object.values(data);
    return modulesOptions;
  };

  const modulesOptions = getModuleOptions(notificationModules?.data);

  const formik = useFormik<IFormik>({
    initialValues: {
      name: '',
      category: { id: 0, name: '' },
      content: '',
      photos: [],
      mainPhoto: {
        full_photo_url: '',
        id: 0,
        path: '',
      },
    },
    validate,
    onSubmit: values => {
      const image_ids = values.photos.map(item => item.id);
      const data = {
        title: values.name,
        content: values.content,
        category_id: values.category.id,
        image_ids: image_ids,
        main_photo_id: values.mainPhoto.id || null,
      };

      updateArticle(data);
    },
  });

  const handleContentChange = (values: string) => {
    const cleanedValue = /^<p>(<br>)?<\/p>$/.test(values) ? '' : values;
    formik.setFieldValue('content', cleanedValue);
  };

  const setValues = formik.setValues;

  useEffect(() => {
    if (!data && isLoading) return;

    const category = modulesOptions.find(item => item.id === data.category_id);

    setValues({
      name: data.title,
      category: { id: category?.id || 0, name: category?.name || '' },
      mainPhoto: data?.main_photo_url[0],
      content: data.content,
      photos: data.photos,
    });
  }, [editId, setValues, data, notificationModules]);

  return (
    <form onSubmit={formik.handleSubmit} className={styles.createArticle}>
      <div className={styles.inputLine}>
        <InputBar
          name="name"
          label={t('article_name')}
          placeholder={t('article_name_validate')}
          disabled={isLoading}
          value={formik.values.name}
          onChange={formik.handleChange}
          star
          errors={formik.errors.name}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
        />
        <CustomSelect
          label={t('select_category_validate')}
          disabled={isLoading}
          className={styles.categorySelect}
          placeholder={t('to_choose')}
          value={formik.values.category}
          options={modulesOptions}
          onChange={value => formik.setFieldValue('category', value)}
          required
        />
      </div>

      {formik.values.mainPhoto.id !== 0 ? (
        <div className={styles.imagesWrapper}>
          <p className={styles.imageTitle}>{t('cover')}</p>
          <div className={styles.imageItem}>
            <img
              src={formik.values.mainPhoto.full_photo_url}
              className={styles.image}
            />
            <button
              type="button"
              className={styles.deleteBtn}
              onClick={() =>
                formik.setFieldValue('mainPhoto', {
                  id: 0,
                  full_photo_url: '',
                  path: '',
                })
              }
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.addIconWrapper}>
          <IconButton
            onClick={() => setIsModalOpen(true)}
            className={styles.pickPhotoButton}
          >
            <PhotoIcon className={styles.icon} />
            <span className={styles.pickPhotoText}>
              {t('select_article_cover')}
            </span>
          </IconButton>
        </div>
      )}

      <div className={styles.quilWrapper}>
        <ReactQuill
          ref={quillRef}
          className={classNames('editor', styles.quill)}
          value={formik.values.content}
          onChange={handleContentChange}
          modules={modules}
        />
        {formik.touched.content && formik.errors.content && (
          <span className={styles.errorMsg}>{t('fill_field')}</span>
        )}
      </div>

      <Button
        text={t('save_n_finish')}
        className={styles.saveButton}
        type="submit"
        disabled={!!Object.keys(formik.errors).length}
      />

      <Modal
        modalStyles={styles.photosModal}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <ImageArtickeContainer
          selectedImage={formik.values.mainPhoto}
          onCloseModal={() => setIsModalOpen(false)}
          onSelectImages={values => formik.setFieldValue('mainPhoto', values)}
        />
      </Modal>
      <Modal
        modalStyles={styles.photosModal}
        isOpen={isQuilModalOpen}
        onClose={() => setIsQuilModalOpen(false)}
      >
        <ImageQuillContainer
          isOpen={isQuilModalOpen}
          onClose={() => setIsQuilModalOpen(false)}
          onInsertImage={handleInsertImage}
        />
      </Modal>
    </form>
  );
};

export default EditArticle;
