import i18next from 'i18next'
import { Dayjs } from 'dayjs'
import { numberValidate } from './regex'

export const validate = values => {
  const INT32_MAX = 2147483647
  const errors = {}

  // Name
  if (!values.name) {
    errors.name = i18next.t('validate_name')
  }

  // Code
  if (!values.code) {
    errors.code = i18next.t('validate_code')
  } else if (values.code && !numberValidate(values.code)){
    errors.code = i18next.t('must_be_number')
  } else if(values.code && numberValidate(values.code) && Number(values.code) > INT32_MAX){
    errors.code = i18next.t('too_big_number')
  }

  return errors
}
