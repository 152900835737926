import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, InputBar, Textarea } from '~/ui';
import CustomQuerySelect from '../Shared/CustomQuerySelect/CustomQuerySelect';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import PromotionConditionBoard from './PromotionConditionBoard/PromotionConditionBoard';
import { useEffect, useState } from 'react';
import { ICondition, IStock } from '../PromotionsBoard/StockBoard/StockBoard';
import PhotoIcon from '~/assets/svg/knowledge/photo.svg?react';
import CloseIcon from '~/assets/svg/newSvg/close-cross.svg?react';
import { Modal } from '../Shared/Modal/Modal';
import { useQueryClient } from '@tanstack/react-query';
import { ICreatePromotionProps } from '~/pages/CreatePromotion/CreatePromotion';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import AssetImageContainer from '../CreateArticle/AssetImageContainer/AssetImageContainer';
import { validate } from '~/utils/validateStock';

import cn from 'classnames';
import styles from './PromotionForm.module.scss';

interface IFormikValues {
  name: string;
  description: string;
  status: number | null | undefined;
  asset: { id: number; name: string };
  accommodations: { id: number; name: string }[];
  organizations: { id: number; name: string }[];
  date: { startDate: Dayjs | null; endDate: Dayjs | null };
  points: string;
  days_action: string;
  image: { id: number; full_photo_url: string; path: string };
  rules: ICondition[];
}

interface IPromotionFormProps {
  isEdit?: boolean;
  data?: IStock | undefined;
  onSubmit: (values: ICreatePromotionProps) => Promise<void>;
  deleteRule?: (rule_id: number) => void;
  updateRule?: (id: number, value: number, promo_loyalty_id: number) => void;
  createRule?: (rule_id: number, value: number) => void;
}

const STATUS = {
  active: 1,
  waiting: 2,
  archive: 3,
};

const PromotionForm = ({
  isEdit,
  data,
  onSubmit,
  deleteRule,
  updateRule,
  createRule,
}: IPromotionFormProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toastError = useToastError();
  const queryClient = useQueryClient();

  const {
    values,
    handleBlur,
    handleChange,
    setValues,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
  } = useFormik<IFormikValues>({
    initialValues: {
      name: '',
      description: '',
      status: 3,
      asset: { id: 0, name: '' },
      accommodations: [],
      organizations: [],
      date: { startDate: null, endDate: null },
      points: '',
      days_action: '',
      image: { id: 0, full_photo_url: '', path: '' },
      rules: [],
    },
    validate,
    onSubmit: values => {
      const start_date = dayjs(values.date.startDate).format('YYYY-MM-DD');
      const end_date = dayjs(values.date.endDate).format('YYYY-MM-DD');

      const accommodations = values.accommodations.map(item => item.id);
      const organizations = values.organizations.map(item => item.id);

      const rules = values.rules.map(item => {
        return {
          rule_id: item.description.id,
          value: Number(item.meaning),
        };
      });

      onSubmit({
        name: values.name,
        description: values.description,
        start_date,
        end_date,
        asset_id: values.asset.id,
        status_id: 1,
        burn_count_days: Number(values.days_action) || 0,
        calculated_points: Number(values.points) || 0,
        piwigo_image_id: values.image.id,
        accommodation_ids: accommodations,
        organization_ids: organizations,
        rules,
      });
    },
  });

  const loadAssetById = async (id: number | undefined) => {
    try {
      const { data } = await instance.get(`admin/assets/${id}`);
      queryClient.setQueryData(['asset', id], data);
      setFieldValue('asset', data);
    } catch (error) {
      toastError(error);
    }
  };

  const handleAddCondition = (condition: ICondition) => {
    if (isEdit && createRule) {
      createRule(condition.description.id, Number(condition.meaning));
    } else {
      setFieldValue('rules', [...values.rules, condition]);
    }
  };

  const handleDeleteCondition = (id: string, item: { rule_id: number }) => {
    if (isEdit && deleteRule) {
      deleteRule(item.rule_id);
    } else {
      setFieldValue(
        'rules',
        values.rules.filter(item => item.id !== id)
      );
    }
  };

  const handleEditCondition = (condition: ICondition) => {
    if (isEdit && updateRule) {
      updateRule(
        Number(condition.description.id),
        Number(condition.meaning),
        condition.department.id
      );
    } else {
      setFieldValue(
        'conditions',
        values.rules.map(item => {
          if (condition.id === item.id) {
            return condition;
          }
          return item;
        })
      );
    }
  };

  useEffect(() => {
    if (isEdit && data) {
      loadAssetById(data?.asset_id);

      const accommodations =
        data?.accommodations.map(item => item.accommodations ?? []) || [];

      const rules = data.rules.map(item => {
        return {
          id: String(item.id),
          department: {
            id: item.rule.module_id || 0,
            name: item.rule.module.name || '',
          },
          description: { id: item.rule_id, name: item.rule.description },
          meaning: String(item.value),
        };
      });

      setValues({
        name: data.name,
        description: data.description,
        status: data.status_id,
        asset: { id: 0, name: '' },
        accommodations: accommodations,
        organizations: data?.organizations,
        date: {
          startDate: dayjs(data.start_date),
          endDate: dayjs(data.end_date),
        },
        points: String(data.calculated_points),
        days_action: String(data.burn_count_days),
        image: !!data.photos.length
          ? data.photos[0]
          : { id: 0, path: '', full_photo_url: '' },
        rules: rules,
      });
    } else {
      return;
    }
  }, [data]);

  return (
    <div className={styles.formWrapper}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <InputBar
          label={t('name_of_action')}
          name="name"
          value={values.name}
          required
          star
          disabled={isEdit && values.status !== STATUS.waiting}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && Boolean(errors.name)}
          errors={errors.name}
        />

        <Textarea
          label={t('description')}
          rows={3}
          star={false}
          maxLength={1000}
          name="description"
          disabled={isEdit && values.status !== STATUS.waiting}
          value={values.description}
          onChange={handleChange}
          onBlur={() => setFieldTouched('description', true)}
          error={touched.description && Boolean(errors.description)}
          errors={errors.description}
        />

        <div className={styles.assetWrapper}>
          <CustomQuerySelect
            label={t('assets')}
            query="admin/assets"
            queryKey={['assets', {}]}
            placeholder={t('validate_asset')}
            params={null}
            value={values.asset}
            className={styles.querySelect}
            onChange={value => {
              setFieldValue('asset', value);
            }}
            required
            disabled={isEdit && values.status !== STATUS.waiting}
            onTouch={() => setFieldTouched('asset', true)}
            error={touched.asset ? (errors.asset as string) : ''}
          />

          <CustomQuerySelect
            label={t('accommodation_facility')}
            className={styles.querySelect}
            onChange={value => {
              setFieldValue('accommodations', value);
            }}
            value={values.accommodations}
            query="admin/accommodations"
            queryKey={[
              'accommodations-100',
              {
                sort: '',
                perPage: 100,
                field: '',
                page: 1,
                asset_id: values.asset.id,
              },
            ]}
            params={{
              sort: '',
              perPage: 100,
              field: '',
              page: 1,
              asset_id: values.asset.id,
            }}
            required
            placeholder={t('to_choose')}
            multiselect
            isSearchEnabled
            onTouch={() => setFieldTouched('accommodations', true)}
            error={
              touched.accommodations ? (errors.accommodations as string) : ''
            }
            disabled={
              (values.asset.id === 0 && !values.asset.name) ||
              (isEdit && values.status !== STATUS.waiting)
            }
          />

          <CustomQuerySelect
            label={t('organizations')}
            query="admin/organization"
            onChange={value => {
              setFieldValue('organizations', value);
            }}
            className={styles.querySelect}
            placeholder={t('validate_organizations')}
            value={values.organizations}
            isSearchEnabled
            queryKey={[
              'organizations',
              {
                sort: '',
                field: '',
                page: 1,
              },
            ]}
            params={{
              sort: '',
              field: '',
              page: 1,
            }}
            onTouch={() => setFieldTouched('organizations', true)}
            error={
              touched.organizations ? (errors.organizations as string) : ''
            }
            required
            multiselect
            disabled={isEdit && values.status !== STATUS.waiting}
          />
        </div>

        <div className={styles.valuesWrapper}>
          <label className={styles.dateWrapper}>
            <span className={styles.dateLabel}>{t('stock_date')}</span>
            <DatePicker.RangePicker
              className={styles.datepicker}
              format="DD.MM.YYYY"
              value={[values.date.startDate, values.date.endDate]}
              onChange={date => {
                if (date && date[0] && date[1]) {
                  setFieldValue('date.startDate', date[0]);
                  setFieldValue('date.endDate', date[1]);
                } else {
                  setFieldValue('date.startDate', dayjs());
                  setFieldValue('date.endDate', dayjs());
                }
              }}
              onBlur={() => setFieldTouched('date.startDate', true)}
              placeholder={[t('date'), t('date')]}
              disabledDate={current =>
                current && current.isBefore(dayjs(), 'day')
              }
              disabled={isEdit}
            />
          </label>
          <InputBar
            label={t('points_awarded_quantity')}
            name="points"
            value={String(values.points)}
            required
            star
            disabled={isEdit && values.status !== STATUS.waiting}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.points && Boolean(errors.points)}
            errors={errors.points}
          />
          <InputBar
            label={t('points_days_valid')}
            name="days_action"
            value={String(values.days_action)}
            required
            star
            disabled={isEdit && values.status !== STATUS.waiting}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.days_action && Boolean(errors.days_action)}
            errors={errors.days_action}
          />
        </div>

        {values.image.id !== 0 ? (
          <div className={styles.imagesWrapper}>
            <p className={styles.imageTitle}>{t('cover')}</p>
            <div className={styles.imageItem}>
              <img src={values.image.full_photo_url} className={styles.image} />
              {!isEdit && values.status !== STATUS.waiting && (
                <button
                  type="button"
                  className={styles.deleteBtn}
                  onClick={() => {
                    if (isEdit && values.status !== STATUS.waiting) return;
                    setFieldValue('image', {
                      id: 0,
                      full_photo_url: '',
                      path: '',
                    });
                  }}
                >
                  <CloseIcon />
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.addIconWrapper}>
            <IconButton
              onClick={() => {
                if (values.asset.id === 0) return;

                setIsModalOpen(true);
              }}
              className={cn(styles.pickPhotoButton, {
                [styles.disabledPhotoButton]: values.asset.id === 0,
              })}
              isDisabled={isEdit && values.status !== STATUS.waiting}
            >
              <PhotoIcon className={styles.icon} />
              <span className={styles.pickPhotoText}>
                {t('select_stock_cover')}
              </span>
            </IconButton>
            {!errors.image?.id && (
              <span className={styles.iconBtnError}>
                {t('choose_promotion_icon')}
              </span>
            )}
          </div>
        )}

        <PromotionConditionBoard
          handleAddPromotion={handleAddCondition}
          handleDeleteCondition={handleDeleteCondition}
          handleEditCondition={handleEditCondition}
          conditions={values.rules}
          isEdit={isEdit}
          disabled={isEdit && values.status !== STATUS.waiting}
        />

        <Button
          type="submit"
          text={t('save_n_finish')}
          className={styles.submitBtn}
          disabled={
            !values.name ||
            !values.description ||
            !values.asset.name ||
            !values.accommodations.length ||
            (!values.date.startDate && !values.date.endDate) ||
            !values.points ||
            !values.days_action ||
            !values.image.full_photo_url ||
            !values.organizations.length ||
            (isEdit && values.status !== STATUS.waiting)
          }
        />
      </form>

      {values.asset.id !== 0 && (
        <Modal
          modalStyles={styles.photosModal}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <AssetImageContainer
            path="promo-loyalty-images"
            queryKey="promotion-images"
            assetId={values.asset.id}
            selectedImage={values.image}
            onCloseModal={() => setIsModalOpen(false)}
            onSelectImages={values => setFieldValue('image', values)}
          />
        </Modal>
      )}
    </div>
  );
};

export default PromotionForm;
