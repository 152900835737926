import dayjs, { Dayjs } from 'dayjs';
import { InputBar } from '~/ui';
import { UserPromotionFormBoard } from './UserPromotionFormBoard/UserPromotionFormBoard';

import styles from './UserForm.module.scss';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useParams } from 'react-router-dom';
import { useToastError } from '~/utils/useToastError';
import { useState } from 'react';
import { t } from 'i18next';

export const UserPromotionForm = () => {
  const { userID } = useParams();
  const toastError = useToastError();
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null] | null>(null);
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });

  const params = {
    dates:
      dates && dates[0] && dates[1]
        ? [
            dayjs(dates[0]).startOf('day').format('YYYY-MM-DD'),
            dayjs(dates[1]).startOf('day').format('YYYY-MM-DD'),
          ]
        : [],
  };

  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(`admin/loyalty-history/${userID}`, {
        params,
      });
      return data;
    },
    queryKey: ['user-loyalty-history', userID, dates],
    onError: error => {
      toastError(error);
    },
  });

  const { data: userLevel } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(`admin/loyalty-level-user/${userID}`);
      return data;
    },
    queryKey: ['user-level', userID],
    onError: error => {
      toastError(error);
    },
  });

  const { data: userPoints } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(
        `admin/loyalty-points-user/${userID}`
      );
      return data;
    },
    queryKey: ['user-point', userID],
    onError: error => {
      toastError(error);
    },
  });

  return (
    <div className={styles.userPromotion}>
      <div className={styles.pointsDataWrapper}>
        <InputBar
          label={t('user_level')}
          name="level"
          value={userLevel?.name}
          disabled={true}
        />
        <InputBar
          label={t('total_points')}
          name="points"
          value={userPoints?.points}
          disabled={true}
          classNameInputWrapper={styles.input}
        />
      </div>
      <UserPromotionFormBoard
        data={data}
        dates={dates}
        setDates={setDates}
        isLoading={isLoading}
        sort={sort}
        setSort={setSort}
      />
    </div>
  );
};
