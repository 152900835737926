import styles from './CustomCheckbox.module.scss';
import Checkmark from './images/check.svg?react';
import cn from 'classnames';

interface ICustomCheckboxProps {
  isChecked: boolean;
  color?: string;
  onChange: () => void;
  onClick?: () => void;
}

const CustomCheckbox = ({
  isChecked,
  color,
  onChange,
  onClick,
}: ICustomCheckboxProps) => {
  return (
    <div className={styles.checkboxContainer}>
      <input
        id="custom_checkbox"
        name="custom_checkbox"
        onChange={onChange}
        onClick={onClick}
        className={styles.input}
        type="checkbox"
        checked={isChecked}
      />
      <label
        htmlFor="custom_checkbox"
        style={color && isChecked ? { backgroundColor: color } : {}}
        className={cn(
          styles.customCheckbox,
          isChecked && styles.customCheckboxChecked
        )}
      >
        {isChecked && <Checkmark className={styles.checkmark} />}
      </label>
    </div>
  );
};

export default CustomCheckbox;
