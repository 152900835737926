import { Accommodation } from '../Accommodations/AccommodationsBoard/AccommodationsTable/AccommodationsTable';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';
import { IconsContainer } from '../CreateCategory/IconsContainer/IconsContainer';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';
import { IAddedSlot } from './SlotBoard/SlotTable/SlotTable';
import DeleteSvg from '~/assets/svg/newSvg/delete.svg?react';
import { Badge, Button, IconButton, InputBar } from '~/ui';
import Heading from '~/components/Shared/Heading/Heading';
import TimeInputBar from '~/ui/TimeInputBar/TimeInputBar';
import { validate } from '~/utils/validateEntertainment';
import Page from '~/components/Shared/Layout/Page/Page';
import { Modal } from '~/components/Shared/Modal/Modal';
import PhotoIcon from './images/image-icon.svg?react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SlotBoard from './SlotBoard/SlotBoard';
import ReactDOMServer from 'react-dom/server';
import { useEffect, useState } from 'react';
import { svgIcons } from '~/utils/getIcons';
import { instance } from '~/utils/api/api';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import { DatePicker } from 'antd';
import i18n from '~/i18n';

import styles from './CreateEntertainment.module.scss';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useToastError } from '~/utils/useToastError';

interface Day {
  id: number;
  name: string;
  displayName: string;
}

const getDays = () => {
  const t = i18n.t;
  return [
    { id: 0, name: t('monday'), displayName: 'Monday' },
    { id: 1, name: t('tuesday'), displayName: 'Tuesday' },
    { id: 2, name: t('wednesday'), displayName: 'Wednesday' },
    { id: 3, name: t('thursday'), displayName: 'Thursday' },
    { id: 4, name: t('friday'), displayName: 'Friday' },
    { id: 5, name: t('saturday'), displayName: 'Saturday' },
    { id: 6, name: t('sunday'), displayName: 'Sunday' },
  ] as Day[];
};

export const getStatusOptions = () => {
  return [
    {
      id: 1,
      name: (
        <Badge className={styles.statusBadge} variant="success">
          Работает
        </Badge>
      ),
    },
    {
      id: 0,
      name: (
        <Badge className={styles.statusBadge} variant="error">
          Не работает
        </Badge>
      ),
    },
  ];
};

const CreateEntertainment = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const client = useQueryClient();
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();

  const days = getDays();

  const { data: objectStatuses } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(
        'admin/entertainment-objects-statuses'
      );
      return data;
    },
    queryKey: ['object-statuses'],
  });

  const handleSetIsModalOpen = () => {
    setIsModalOpen(false);
  };

  const {
    values,
    setFieldValue,
    errors,
    handleSubmit,
    touched,
    handleBlur,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      name: '',
      asset: {
        id: 0,
        name: '',
      },
      address: '',
      days: [],
      startTime: null,
      endTime: null,
      startDate: null,
      endDate: null,
      status: null,
      accommodations: [] as Accommodation[],
      organizations: [] as Accommodation[],
      icon: null,
      iconId: null,
      icon_string: '',
      slots: [] as IAddedSlot[],
    },

    validate,

    onSubmit: async values => {
      const stringIcon =
        values.icon !== null
          ? ReactDOMServer.renderToStaticMarkup(values.icon)
          : '';
      const organization_ids = values.organizations.map(item => item.id);
      const accommodation_ids = values.accommodations.map(
        accommodation => accommodation.id
      );

      try {
        await instance.post('admin/entertainment-objects', {
          entertainment_object: {
            name: values.name,
            asset_id: values.asset.id,
            start_time: values.startTime
              ? (values.startTime as Dayjs).format('HH:mm')
              : null,
            end_time: values.endTime
              ? (values.endTime as Dayjs).format('HH:mm')
              : null,
            start_date: values.startDate
              ? (values.startDate as Dayjs).format('YYYY-MM-DD')
              : null,
            end_date: values.endDate
              ? (values.endDate as Dayjs).format('YYYY-MM-DD')
              : null,
            address: values.address,
            accommodation_ids,
            organization_ids,
            svg: stringIcon,
            status_id: values.status
              ? (values.status as { id: number }).id
              : null,
            working_days: values.days,
          },
          entertainment_object_time_slots: values.slots.map(
            (slot: IAddedSlot) => {
              return {
                start_time: slot.startTime
                  ? (slot.startTime as Dayjs).format('HH:mm')
                  : null,
                end_time: slot.endTime
                  ? (slot.endTime as Dayjs).format('HH:mm')
                  : null,
                max_users_count: slot.slotsCount,
                status_id: slot.status ? slot.status.id : null,
              };
            }
          ),
        });
        client.invalidateQueries(['entertainments']);
        toastSuccess('entertainment_successfully_created');
        navigate('/entertainment');
      } catch (error) {
        toastError(error);
      }
    },
  });

  const handleAddSlot = (slot: IAddedSlot) => {
    setFieldValue('slots', [...values.slots, slot]);
  };

  const handleDeleteSlot = (id: string) => {
    setFieldValue(
      'slots',
      values.slots.filter(slot => slot.id !== id)
    );
  };

  const handleEditSlot = (slot: IAddedSlot) => {
    setFieldValue(
      'slots',
      values.slots.map(item => {
        if (slot.id === item.id) {
          return slot;
        }
        return item;
      })
    );
  };

  useEffect(() => {
    if (values.iconId === null) return;
    const Icon = svgIcons[values.iconId].icon;
    setFieldValue('icon', <Icon className={styles.icon} />);
  }, [values.iconId]);

  return (
    <Page heading={<Heading text={t('create_entertainment_title')} />}>
      <div className={styles.inputs}>
        <InputBar
          label="Название объекта"
          name="name"
          placeholder="GYM24"
          value={values.name}
          onChange={event => setFieldValue('name', event.target.value)}
          required
          star
          onBlur={handleBlur}
          error={touched.name && Boolean(errors.name)}
          errors={errors.name}
        />
        <CustomQuerySelect
          label={t('assets')}
          query="admin/assets"
          queryKey={['assets', {}]}
          placeholder={t('validate_asset')}
          params={null}
          value={values.asset}
          onChange={value => {
            setFieldValue('asset', value);
            setFieldValue('accommodations', []);
          }}
          required
          error={touched.asset ? (errors.asset as string) : ''}
          onTouch={() => setFieldTouched('asset', true)}
        />
        <div className={styles.timeCell}>
          <CustomSelect
            label={t('schedule')}
            options={days}
            value={values.days}
            onChange={value => setFieldValue('days', value)}
            multiselect
            placeholder={t('select_days')}
            required
            className={styles.scheduleSelect}
            onTouch={() => setFieldTouched('days', true)}
            error={touched.days ? (errors.days as string) : undefined}
          />
          <label className={styles.timeInput}>
            <span className={styles.inputLabel}>{t('repeat_schedule')}</span>
            <DatePicker.RangePicker
              className={styles.datepicker}
              format="DD.MM.YYYY"
              value={[values.startDate, values.endDate]}
              onChange={date => {
                if (date && date[0] && date[1]) {
                  setFieldValue('startDate', date[0]);
                  setFieldValue('endDate', date[1]);
                } else {
                  setFieldValue('startDate', dayjs());
                  setFieldValue('endDate', dayjs());
                }
              }}
              placeholder={[t('date'), t('date')]}
            />
          </label>
          <TimeInputBar
            label={t('object_working_time')}
            startTime={values.startTime}
            endTime={values.endTime}
            onStartTimeChange={value => setFieldValue('startTime', value)}
            onEndTimeChange={value => setFieldValue('endTime', value)}
            required
          />
        </div>
        <InputBar
          name="address"
          label={t('address')}
          placeholder="ул. Некрасова, 12"
          value={values.address}
          onChange={event => setFieldValue('address', event.target.value)}
          required
          star
          onBlur={handleBlur}
          error={touched.address && !!errors.address}
          errors={errors.address}
        />
        <CustomSelect
          label={t('status')}
          value={values.status}
          onChange={value => {
            setFieldValue('status', value);
          }}
          options={objectStatuses}
          placeholder={t('select_status')}
          required
          onTouch={() => setFieldTouched('status', true)}
          error={touched.status ? errors.status : ''}
        />
        <div className={styles.accommodationInput}>
          <CustomQuerySelect
            label={t('accommodation_facility')}
            onChange={value => {
              setFieldValue('accommodations', value);
            }}
            value={values.accommodations}
            query="admin/accommodations"
            queryKey={[
              'accommodations-100',
              {
                sort: '',
                perPage: 100,
                field: '',
                page: 1,
                asset_id: values.asset.id,
              },
            ]}
            params={{
              sort: '',
              perPage: 100,
              field: '',
              page: 1,
              asset_id: values.asset.id,
            }}
            required
            placeholder={t('to_choose')}
            multiselect
            isSearchEnabled
            onTouch={() => setFieldTouched('accommodations', true)}
            error={
              touched.accommodations ? (errors.accommodations as string) : ''
            }
            disabled={!values.asset.id && !values.asset.name}
          />
        </div>
        <div className={styles.organizationsWrapper}>
          <CustomQuerySelect
            label={t('organizations')}
            query="admin/organization"
            onChange={value => {
              setFieldValue('organizations', value);
            }}
            placeholder={t('validate_organizations')}
            value={values.organizations}
            isSearchEnabled
            queryKey={[
              'organizations',
              {
                sort: '',
                perPage: 100,
                field: '',
                page: 1,
              },
            ]}
            params={{
              sort: '',
              perPage: 100,
              field: '',
              page: 1,
            }}
            required
            multiselect
          />
        </div>
      </div>
      <div className={styles.iconBtnWrapper}>
        {values.icon !== null ? (
          <div className={styles.iconWrapper}>
            {values.icon}
            <button
              type="button"
              className={styles.deleteBtd}
              onClick={() => {
                setFieldValue('iconId', null);
                setFieldValue('icon', null);
              }}
            >
              <DeleteSvg />
            </button>
          </div>
        ) : (
          <div className={styles.iconBtnWrapper}>
            <IconButton
              onClick={() => setIsModalOpen(true)}
              className={styles.photoPickerButton}
            >
              <PhotoIcon />
              <span className={styles.photoPickerText}>
                {t('choose_category_icon')}
              </span>
            </IconButton>
            {errors.icon && (
              <span className={styles.iconBtnError}>
                {t('choose_category_icon')}
              </span>
            )}
          </div>
        )}
      </div>
      <SlotBoard
        handleAddSlot={handleAddSlot}
        handleDeleteSlot={handleDeleteSlot}
        handleEditSlot={handleEditSlot}
        slots={values.slots}
      />
      {errors.slots && (
        <span className={styles.slotErrorMsg}>{errors.slots as string}</span>
      )}
      <Button
        text={t('save_n_finish')}
        className={styles.saveButton}
        onClick={handleSubmit}
        disabled={
          !values.name ||
          !values.asset.name ||
          !values.days.length ||
          !values.address ||
          !values.status ||
          !values.accommodations.length ||
          !values.icon ||
          !values.slots.length
        }
      />

      <Modal
        modalStyles={styles.photosModal}
        isOpen={isModalOpen}
        onClose={handleSetIsModalOpen}
      >
        <IconsContainer
          onCloseClick={handleSetIsModalOpen}
          setSelectedId={value => {
            setFieldValue('iconId', value);
          }}
        />
      </Modal>
    </Page>
  );
};

export default CreateEntertainment;
