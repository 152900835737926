import { instance } from '~/utils/api/api';
import { Dayjs } from 'dayjs';
import { downloadFile } from './downloadFile';

export async function downloadReportFile(
  reportPath: string,
  reportName: string,
  startDate: Dayjs | null,
  endDate: Dayjs | null,
  isAllAccommodations?: boolean
) {
  const response = await instance.get(`admin/${reportPath}`, {
    params: {
      ...(reportPath.includes('food-wares-export')
        ? {
            dates: [
              startDate
                ?.hour(0)
                .minute(0)
                .second(1)
                .format('YYYY-MM-DD HH:mm:ss'),
              endDate?.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            ],
          }
        : {
            start_date: startDate
              ?.hour(0)
              .minute(0)
              .second(1)
              .format('YYYY-MM-DD HH:mm:ss'),
            end_date: endDate?.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          }),
      ...(isAllAccommodations ? { accommodation_all: 1 } : {}),
    },
    responseType: 'blob',
  });

  downloadFile(response.data, 'text/xlsx', `${reportName}.xlsx`);
}

export async function downloadMultipleAccommodationReportFile(
  reportPath: string,
  reportName: string,
  accommodationIDs: number[],
  startDate: Dayjs | null,
  endDate: Dayjs | null
) {
  const response = await instance.get(`admin/${reportPath}`, {
    params: {
      start_date: startDate?.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      end_date: endDate?.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      accommodation_ids: accommodationIDs,
    },
    responseType: 'blob',
  });

  downloadFile(response.data, 'text/xlsx', `${reportName}.xlsx`);
}

export async function downloadAllAccommodationReportFile(
  reportPath: string,
  reportName: string,
  accommodationIDs: number[],
  startDate: Dayjs | null,
  endDate: Dayjs | null
) {
  const response = await instance.get(`admin/${reportPath}`, {
    params: {
      start_date: startDate?.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      end_date: endDate?.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      accommodation_ids: accommodationIDs,
    },
    responseType: 'blob',
  });

  downloadFile(response.data, 'text/xlsx', `${reportName}.xlsx`);
}
