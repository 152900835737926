import { Root, Trigger, List, Content } from '@radix-ui/react-tabs';
import { IStorageProps } from '../Storage';
import FolderCard from './FolderCard/FolderCard';

import styles from './StorageFolderList.module.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StorageFolderFiles from '../StorageFolderFiles/StorageFolderFiles';
import { t } from 'i18next';

const StorageFolderList = ({ assets }: IStorageProps) => {
  const [activeTab, setActiveTab] = useState('');
  const navigate = useNavigate();

  const handleTabChange = (value: string) => {
    setActiveTab(value);
    navigate(`/storage/${value}`);
  };

  useEffect(() => {
    const currentPath = location.pathname
      .split('/')
      .filter(item => item !== '');

    if (currentPath.length < 2) {
      setActiveTab('');
    } else {
      setActiveTab(currentPath[currentPath.length - 1]);
    }
  }, []);

  return (
    <div className={styles.listWrapper}>
      <Root
        value={activeTab}
        onValueChange={handleTabChange}
        className={styles.root}
      >
        <div className={styles.foldersWrapper}>
          <p className={styles.foldersTitle}>{t('folders')}</p>
          <List className={styles.list}>
            {assets.map(item => (
              <Trigger
                value={String(item.id)}
                key={item.id}
                className={styles.item}
              >
                {<FolderCard asset={item} />}
              </Trigger>
            ))}
          </List>
        </div>

        {activeTab && (
          <div className={styles.filesWrapper}>
            <p className={styles.filesTitle}>{t('files')}</p>
            <Content value={activeTab}>
              <StorageFolderFiles id={activeTab} />
            </Content>
          </div>
        )}
      </Root>
    </div>
  );
};

export default StorageFolderList;
