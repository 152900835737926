import { Button, Pagination, Spinner } from '~/ui';
import { t } from 'i18next';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useEffect, useState } from 'react';
import SelectedIcon from './icoms/selected-icon.svg?react';
import styles from './ImageArtickeContainer.module.scss';
import classNames from 'classnames';

interface IImage {
  id: number;
  full_photo_url: string;
  path: string;
}

interface IArticlesProps {
  multiselect?: boolean;
  selectedImage: IImage;
  onCloseModal: () => void;
  onSelectImages: (values: IImage) => void;
  onSelectImagesArray?: (values: IImage[] | []) => void;
}

const ImageArtickeContainer = ({
  selectedImage,
  onCloseModal,
  onSelectImages,
}: IArticlesProps) => {
  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState<IImage>(selectedImage);
  const toastError = useToastError();

  const handleSubmit = () => {
    onSelectImages(selectedId);
    onCloseModal();
  };

  const handleImageSelect = (value: IImage) => {
    setSelectedId(value);
  };

  const handleClose = () => {
    onCloseModal();
    setSelectedId(selectedImage);
  };

  const { data: images, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(
        'admin/knowledge-base-articles-get-images',
        { params: { perPage: 9, page } }
      );
      return data;
    },
    queryKey: ['articles-images', page],
    onError: error => {
      toastError(error);
    },
  });

  useEffect(() => {
    setSelectedId(selectedImage);
  }, [selectedImage]);

  return (
    <div className={styles.photoContainer}>
      <div className={styles.headingWrapper}>
        <p className={styles.title}>{t('choose_photo')}</p>
        <div className={styles.closeBtnWrapper}>
          {selectedId.full_photo_url && (
            <Button
              text={t('discard')}
              className={styles.resetBtn}
              onClick={() =>
                setSelectedId({ id: 0, full_photo_url: '', path: '' })
              }
            />
          )}
          <Button
            text={t('close')}
            className={styles.closeBtn}
            onClick={handleClose}
          />
        </div>
      </div>
      <div className={styles.imagesWrapper}>
        <ul className={!isLoading ? styles.imagesList : styles.loadingList}>
          {images && !isLoading ? (
            images.data.map((item: IImage) => (
              <li key={item.id} className={styles.imagesItem}>
                <button
                  type="button"
                  onClick={() => handleImageSelect(item)}
                  onDoubleClick={e => e.preventDefault()}
                  className={classNames(
                    styles.imageBtn,
                    selectedId.id === item.id && styles.activeImage
                  )}
                >
                  <img
                    src={item.full_photo_url}
                    alt={item.path}
                    className={classNames(styles.image)}
                    onDoubleClick={e => e.preventDefault()}
                  />
                  {selectedId.id === item.id && (
                    <SelectedIcon className={styles.selectedIcon} />
                  )}
                </button>
              </li>
            ))
          ) : (
            <Spinner />
          )}
        </ul>
        <Pagination
          pageCount={images?.last_page}
          onPageChange={(selectedPage: { selected: number }) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
      <Button
        text={t('confirm')}
        className={styles.submitBtn}
        type="button"
        onClick={handleSubmit}
        disabled={selectedId.full_photo_url ? false : true}
      />
    </div>
  );
};

export default ImageArtickeContainer;
