import { Button, IconButton, InputBar } from '~/ui';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { Modal } from '~/components/Shared/Modal/Modal';
import { useTranslation } from 'react-i18next';
import PhotoIcon from './images/image-icon.svg?react';
import { useContext, useEffect, useState } from 'react';
import { validate } from '~/utils/validateKnowledge';
import { useToastError } from '~/utils/useToastError';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';
import { instance } from '~/utils/api/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { IconsContainer } from './IconsContainer/IconsContainer';
import { svgIcons } from '~/utils/getIcons';
import DeleteSvg from '~/assets/svg/newSvg/delete.svg?react';
import styles from './CreateCategory.module.scss';
import ReactDOMServer from 'react-dom/server';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';

export interface IFormik {
  name: string;
  assets: {
    id: number;
    name: string;
  };
  organizations: { id: number; name: string }[];
  iconId: number | null;
  icon: null | JSX.Element;
}

export interface ICreateProps {
  name: string;
  assets: { id: number; name: string };
  organizations: number[];
  icon: string;
}

const CreateCategory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { user } = useContext(AuthenticationContext);
  const isSuperAdmin = checkIfUserSuperAdmin(user?.data.role_name);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSetIsModalOpen = () => {
    setIsModalOpen(false);
  };

  const client = useQueryClient();

  const { mutateAsync: createCategory } = useMutation(
    async ({ name, assets, icon, organizations }: ICreateProps) => {
      const response = await instance.post('admin/knowledge-base-categories', {
        name,
        asset_id: assets.id,
        svg_icon: icon,
        organization_ids: organizations,
      });

      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['knowledge-base']);
        navigate('/knowledge-base');
        toastSuccess(t('new_category_successfully_created'));
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { data: assetsArray, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/assets');
      return data.data;
    },
    queryKey: ['assets'],
    onError: error => {
      toastError(error);
    },
  });

  const formik = useFormik<IFormik>({
    initialValues: {
      name: '',
      assets: {
        id: 0,
        name: '',
      },
      organizations: [],
      iconId: null,
      icon: null,
    },
    validate,
    onSubmit: values => {
      const stringIcon =
        values.icon !== null
          ? ReactDOMServer.renderToStaticMarkup(values.icon)
          : '';

      const organizations = values.organizations.map(item => item.id);
      const data = {
        name: values.name,
        assets: values.assets,
        organizations,
        icon: stringIcon,
      };
      createCategory(data);
    },
  });

  useEffect(() => {
    if (formik.values.iconId === null) return;
    const Icon = svgIcons[formik.values.iconId].icon;
    formik.setFieldValue('icon', <Icon className={styles.icon} />);
  }, [formik.values.iconId]);

  useEffect(() => {
    if (isLoading && !assetsArray) return;
    if (isSuperAdmin) return;
    formik.setFieldValue('assets.id', assetsArray[0].id);
    formik.setFieldValue('assets.name', assetsArray[0].name);
  }, []);

  return (
    <Page
      heading={
        <Heading
          text={t('create_new_category')}
          classNameText={styles.createCategoryHeading}
        />
      }
    >
      <form className={styles.createCategory} onSubmit={formik.handleSubmit}>
        <InputBar
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          classNameInput={styles.input}
          label={t('category_name')}
          placeholder={t('category_name')}
          star
          errors={formik.errors.name}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
        />

        <div className={styles.selectWrapper}>
          {!isLoading && (
            <CustomSelect
              placeholder={t('to_choose')}
              onChange={value => {
                formik.setFieldValue('assets', value);
              }}
              label={t('asset')}
              value={formik.values.assets}
              className={styles.select}
              disabled={!isSuperAdmin || isLoading}
              options={assetsArray}
              error={
                formik.errors.assets ? formik.errors.assets.name : undefined
              }
              onTouch={() => formik.setFieldTouched('assets', true)}
              required
            />
          )}

          <CustomQuerySelect
            label={t('organizations')}
            query="admin/organization"
            onChange={value => {
              formik.setFieldValue('organizations', value);
            }}
            placeholder={t('validate_organizations')}
            value={formik.values.organizations}
            className={styles.select}
            isSearchEnabled
            queryKey={[
              'organizations',
              {
                sort: '',
                perPage: 100,
                field: '',
                page: 1,
              },
            ]}
            params={{
              sort: '',
              perPage: 100,
              field: '',
              page: 1,
            }}
            required
            multiselect
            error={formik.errors.organizations as string}
          />
        </div>

        {formik.values.icon !== null ? (
          <div className={styles.iconWrapper}>
            {formik.values.icon}
            <button
              type="button"
              className={styles.deleteBtd}
              onClick={() => {
                formik.setFieldValue('iconId', null);
                formik.setFieldValue('icon', null);
              }}
            >
              <DeleteSvg />
            </button>
          </div>
        ) : (
          <div className={styles.iconBtnWrapper}>
            <IconButton
              onClick={() => setIsModalOpen(true)}
              className={styles.photoPickerButton}
            >
              <PhotoIcon />
              <span className={styles.photoPickerText}>
                {t('choose_category_icon')}
              </span>
            </IconButton>
            {formik.errors.icon && (
              <span className={styles.iconBtnError}>{t('select_icon')}</span>
            )}
          </div>
        )}
        <Button
          type="submit"
          text={t('save_n_exit')}
          className={styles.submitButton}
          disabled={
            !formik.values.name ||
            !formik.values.icon ||
            !formik.values.assets.name ||
            !formik.values.organizations.length
          }
        />
      </form>

      <Modal
        modalStyles={styles.photosModal}
        isOpen={isModalOpen}
        onClose={handleSetIsModalOpen}
      >
        <IconsContainer
          onCloseClick={handleSetIsModalOpen}
          setSelectedId={value => formik.setFieldValue('iconId', value)}
        />
      </Modal>
    </Page>
  );
};

export default CreateCategory;
