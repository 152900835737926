import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButton } from '~/ui';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';

import styles from './StockTable.module.scss';
import { useNavigate } from 'react-router-dom';
import ModalConfirm from '~/components/Shared/ModalConfirm';
import StockStatusBadge from './StockStatusBadge/StockStatusBadge';
import dayjs from 'dayjs';
import { IStock } from '../StockBoard';

interface IStockProps {
  stocks: IStock[];
  sort: any;
  setSort: ({ field, direction }: any) => void;
  selectedItems: number[];
  handleToggle: (id: number) => void;
  deleteStock: (id: number) => void;
}

const StockTable = ({ stocks, sort, setSort, deleteStock }: IStockProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead field="name">{t('name_of_action')}</TableCellHead>
          <TableCellHead field="start_date">{t('start_date')}</TableCellHead>
          <TableCellHead field="end_date">{t('end_date')}</TableCellHead>
          <TableCellHead field="status_id">{t('status')}</TableCellHead>
          <TableCellHead field="actions" sortable={false}>
            {t('actions')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {stocks?.map(item => {
            return (
              <TableRow key={item?.id} style={styles.row}>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  {dayjs(item.start_date).format('DD.MM.YYYY')}
                </TableCell>
                <TableCell>
                  {dayjs(item.end_date).format('DD.MM.YYYY')}
                </TableCell>
                <TableCell className={styles.statusCell}>
                  <StockStatusBadge status={item.status} />
                </TableCell>
                <TableCell>
                  <div className={styles.entertainmentTableActions}>
                    <IconButton
                      // @ts-ignore
                      onClick={event => {
                        event.stopPropagation();
                        navigate(`/promotions/${item.id}`);
                      }}
                      className={styles.entertainmentTableBtnLink}
                    >
                      <Edit />
                    </IconButton>
                    <ModalConfirm
                      title={`${t('sure_to_delete_promotion')} ${item.name}?`}
                      description={t('imposible_return_stock')}
                      onConfirm={() => deleteStock(item.id)}
                    >
                      {open => (
                        <IconButton
                          // @ts-ignore
                          onClick={event => {
                            event.stopPropagation();
                            open();
                          }}
                        >
                          <Basket />
                        </IconButton>
                      )}
                    </ModalConfirm>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default StockTable;
