import { useTranslation } from 'react-i18next';

export const languageData = () => {
  const { t } = useTranslation();

  const language: {
    id: number;
    name: string;
    type: string;
  }[] = [
    { id: 1, name: t('russian'), type: 'ru' },
    { id: 2, name: t('english'), type: 'en' },
  ];

  return language;
};
