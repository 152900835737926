import cn from 'classnames';
import styles from './PointsBadge.module.scss';

interface IPointsBadgeProps {
  point: number;
}

const PointsBadge = ({ point }: IPointsBadgeProps) => {
  const isLessZero = point < 0;

  return (
    <div className={cn(styles.point, { [styles.less]: isLessZero })}>
      {!isLessZero ? `+${point}` : point}
    </div>
  );
};

export default PointsBadge;
