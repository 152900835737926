import i18next from 'i18next'
import { numberValidate } from './regex'

export const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = i18next.t('fill_field')
    }

    if (!values.category.name) {
        errors.category = { name: i18next.t('validate_asset') }
    }

    if (!values.mainPhoto.path) {
        errors.mainPhoto = i18next.t('choose_category')
    }

    if (!values.content) {
        errors.content = i18next.t('fill_field')
    }
    return errors
}