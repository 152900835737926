import { useTranslation } from 'react-i18next';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';
import { Button } from '~/ui';
import styles from './StorageSubmitInfo.module.scss';

interface IStorageInfoProps {
  currentAsset: { id: number; name: string };
  setCurrentAsset: ({ id, name }: { id: number; name: string }) => void;
  handleSubmit: () => void;
  handleCloseBtn: () => void;
  allAssets: { id: number; name: string }[];
  isSuperAdmin: boolean;
}

const StorageSubmitInfo = ({
  currentAsset,
  setCurrentAsset,
  handleSubmit,
  handleCloseBtn,
  allAssets,
  isSuperAdmin,
}: IStorageInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <p className={styles.title}>{t('who_will_see_files')}</p>
      <div className={styles.selectWrapper}>
        <p className={styles.label}>{t('asset')}</p>
        <CustomSelect
          value={currentAsset}
          onChange={value => {
            if (!Array.isArray(value) && typeof value.name === 'string') {
              setCurrentAsset({
                id: Number(value.id),
                name: value.name,
              });
            }
          }}
          options={allAssets}
          className={styles.select}
          required
          disabled={!isSuperAdmin || !allAssets.length}
          error={currentAsset.id === 0 ? t('validate_asset') : ''}
          placeholder={t('validate_asset')}
        />
      </div>
      <div className={styles.btnWrapper}>
        <Button
          text={t('close')}
          type="button"
          onClick={handleCloseBtn}
          className={styles.closeBtn}
        />{' '}
        <Button
          text={t('apply')}
          type="button"
          onClick={handleSubmit}
          className={styles.submitBtn}
          disabled={currentAsset.id === 0}
        />
      </div>
    </div>
  );
};

export default StorageSubmitInfo;
