import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { useTranslation } from 'react-i18next';
import CreateArticle from '~/components/CreateArticle/CreateArticle';

const CreateArticlePage = () => {
  const { t } = useTranslation();

  return (
    <Page heading={<Heading text={t('create_article')} />}>
      <CreateArticle />
    </Page>
  );
};

export default CreateArticlePage;
