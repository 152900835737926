import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Board } from '~/components/Shared/Layout/Board/Board';
import { InputBar, Pagination, Spinner } from '~/ui';
import PointsTable from './PointsTable/PointsTable';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';

import styles from './PointsBoard.module.scss';
import { useDebounce } from '@uidotdev/usehooks';

interface SelectedPage {
  selected: number;
}

const PointsBoard = () => {
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null] | null>(null);
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const params = {
    page,
    perPage: 15,
    query: debouncedSearchTerm,
    sort: sort.direction,
    field: sort.field,
    dates:
      dates && dates[0] && dates[1]
        ? [
            dayjs(dates[0]).startOf('day').format('DD-MM-YYYY HH:mm:ss'),
            dayjs(dates[1]).startOf('day').format('DD-MM-YYYY HH:mm:ss'),
          ]
        : [],
  };

  const { data: points, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/loyalty-history', {
        params,
      });
      return data;
    },
    queryKey: ['loyalty-history', params],
    keepPreviousData: true,
  });

  return (
    <Board classNames={styles.board}>
      <div className={styles.boardHeading}>
        <InputBar
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
            setPage(1);
          }}
          value={searchTerm}
          placeholder={t('search_by_code_n_np')}
          classNameInput={styles.input}
          classNameInputWrapper={styles.inputWrapper}
        />
        <div className={styles.datePickerWrapper}>
          <DatePicker.RangePicker
            disabled={isLoading}
            placeholder={[t('date_validate'), t('date_validate')]}
            className={styles.datePicker}
            value={dates}
            onChange={date => setDates(date ? [date[0], date[1]] : null)}
            format="DD.MM.YYYY"
          />
        </div>
      </div>
      {!points && isLoading ? (
        <Spinner />
      ) : (
        <PointsTable
          points={points?.data || []}
          setSort={setSort}
          sort={sort}
        />
      )}
      <div className={styles.pointsTablePagination}>
        <TableAmountPage
          firstRow={points?.from}
          lastRow={points?.to}
          total={points?.total}
        />
        {points && (
          <Pagination
            pageCount={points?.last_page}
            onPageChange={(selectedPage: SelectedPage) => {
              setPage(selectedPage.selected + 1);
            }}
          />
        )}
      </div>
    </Board>
  );
};

export default PointsBoard;
