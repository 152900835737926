import { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { useToastError } from '~/utils/useToastError';
import { Button, InputBar, Textarea } from '~/ui/index';
import UploadPhoto, {
  Photo,
  UploadPhotosValue,
} from '~/components/UploadPhoto/UploadPhoto';
import styles from './DishForm.module.scss';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '../Authentication/AuthenticationProvider';
import {
  checkIfUserAssetAdmin,
  checkIfUserSuperAdmin,
} from '~/utils/getUserRole';
import CustomSelect from '../Shared/CustomSelect/CustomSelect';

export interface DishItem {
  id: number;
  wares_name: string;
  ingredients: string;
  price_list: { price: string };
  weight: string;
  photos: Photo[];
  measure: string;
}

export interface DishFormValues {
  wares_name: string;
  ingredients: string;
  price: string;
  weight: string;
  measure: string | { id: number; name: string };
  uploadPhotosValue: UploadPhotosValue;
}

interface DishFormProps {
  onSubmit: (values: DishFormValues) => void;
  dishItem?: DishItem;
  foodMenuId?: number;
  storageKey?: string;
}

export const DishForm = ({
  onSubmit,
  dishItem,
  foodMenuId,
  storageKey,
}: DishFormProps): JSX.Element => {
  const toastError = useToastError();
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const isEnoughAccess =
    checkIfUserSuperAdmin(user?.data.role_name) ||
    checkIfUserAssetAdmin(user?.data.role_name);

  const isSuperAdmin = checkIfUserSuperAdmin(user?.data.role_name);
  const isAssetAdmin = checkIfUserAssetAdmin(user?.data.role_name);

  const { initialValues, storedInitialValues, clear, create } =
    useLocalStorage<DishFormValues>({
      initialValues: {
        wares_name: '',
        ingredients: '',
        price: '',
        weight: '',
        measure: { id: 1, name: t('g') },
        uploadPhotosValue: { photos: [], files: [] },
      },
      key: storageKey && `${storageKey}-v1`,
      exclude: ['uploadPhotosValue'],
    });

  const formik = useFormik<DishFormValues>({
    initialValues: storedInitialValues,

    onSubmit: async (values: DishFormValues) => {
      const correctValues = {
        ...values,
        measure:
          typeof values.measure === 'object' && values.measure !== null
            ? values.measure.name
            : values.measure,
      };
      try {
        await onSubmit(correctValues);
        clear();
        formik.resetForm({ values: initialValues });
      } catch (error) {
        toastError(error);
      }
    },
  });

  create(formik.values);

  const setValues = formik.setValues;
  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    if (!dishItem) return;
    setFieldValue('food_menus_id', foodMenuId);

    setValues({
      wares_name: dishItem.wares_name,
      ingredients: dishItem.ingredients,
      measure:
        dishItem.measure.toLowerCase() === 'г'
          ? { id: 1, name: t('g') }
          : dishItem.measure.toLowerCase() === 'мл'
            ? { id: 2, name: t('ml') }
            : { id: 3, name: t('pcs') },
      price: dishItem?.price_list?.price || '',
      weight: dishItem.weight || '',
      uploadPhotosValue: {
        photos: dishItem.photos || [],
        files: [],
      },
    });
  }, [foodMenuId, setValues, dishItem, setFieldValue]);

  return (
    <div className={styles.formWrapper}>
      <form
        className={styles['form']}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.formItems}>
          <InputBar
            name="wares_name"
            value={formik.values.wares_name}
            label={t('name')}
            star={true}
            placeholder={t('dish_name')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={!isEnoughAccess}
          />
          <Textarea
            rows={4}
            label={t('composition')}
            placeholder={t('dish_description')}
            maxLength={600}
            value={formik.values.ingredients}
            onChange={formik.handleChange}
            name="ingredients"
            star={false}
            disabled={!isEnoughAccess}
          />
          <div className={styles.priceWrapper}>
            <InputBar
              name="price"
              value={formik.values.price}
              label={`${t('price')} ₽`}
              star={true}
              placeholder="XXX"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled
            />
            <InputBar
              type="numeric"
              name="weight"
              value={formik.values.weight}
              label={`${t('quantity_full')}`}
              star={false}
              placeholder="XXX"
              onChange={event => {
                const weight = event.target.value;
                formik.setFieldValue('weight', weight);
              }}
              onBlur={formik.handleBlur}
            />
            <CustomSelect
              className={styles.measure}
              label={t('measure')}
              value={formik.values.measure}
              options={[
                { id: 1, name: t('g') },
                { id: 2, name: t('ml') },
                { id: 3, name: t('pcs') },
              ]}
              disabled={!isSuperAdmin && !isAssetAdmin}
              placeholder={`${t('g')}, ${t('ml')}, ${t('pcs')}`}
              onChange={value => formik.setFieldValue('measure', value)}
            />
          </div>

          <UploadPhoto
            onChange={uploadPhotosValue => {
              formik.setFieldValue('uploadPhotosValue', uploadPhotosValue);
            }}
            isUploadBlocked={!isEnoughAccess}
            disabled={!isEnoughAccess}
            values={formik.values.uploadPhotosValue}
            label={t('Photo')}
          />
        </div>

        <Button
          type="submit"
          text={t('save_n_exit')}
          className={styles.formBtn}
          disabled={!((formik.isValid && formik.dirty) || formik.isSubmitting)}
        />
      </form>
    </div>
  );
};
