import i18next from 'i18next'
import { Dayjs } from 'dayjs'

export const validate = values => {
  const errors = {}

  // Name validation
  if (!values.name) {
    errors.name = i18next.t('validate_name')
  } else if (values.name.length < 2) {
    errors.name = i18next.t('at_least_two_characters')
  } else if (values.name.length > 50) {
    errors.name = i18next.t('name_too_long')
  }

  // Asset validation
  if (!values.asset || !values.asset.id) {
    errors.asset = i18next.t('validate_asset')
  }

  // Address validation
  if (!values.address) {
    errors.address = i18next.t('validate_address')
  } else if (values.address.length < 5) {
    errors.address = i18next.t('address_too_short')
  }

  // Slots count validation
  if (!values.slots.length) {
    errors.slots = i18next.t('add_slot')
  }

  // Working days validation
  if (!values.days || values.days.length === 0) {
    errors.days = i18next.t('select_working_days')
  }

  // Time validation
  if (values.startTime && values.endTime) {
    if (values.startTime.isAfter(values.endTime)) {
      errors.endTime = i18next.t('end_time_must_be_after_start_time')
    }
  }

  // Date validation
  if (values.startDate && values.endDate) {
    if (values.startDate.isAfter(values.endDate,)) {
      errors.endDate = i18next.t('end_date_must_be_after_start_date')
    }
  }

  // Status validation
  if (!values.status) {
    errors.status = i18next.t('select_status')
  }

  // Accommodations validation
  if (!values.accommodations || values.accommodations.length === 0) {
    errors.accommodations = i18next.t('select_accommodation')
  }

  // Icon validation
  if (!values.icon && !values.iconId) {
    errors.icon = i18next.t('select_icon')
  }

  return errors
}
