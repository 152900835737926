import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { useToastError } from '~/utils/useToastError';
import { instance } from '~/utils/api/api';
import { getUsers } from '~/utils/api/queries';
import dayjs, { Dayjs } from 'dayjs';
import { useDebounce } from '@uidotdev/usehooks';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { getModules, ModuleItem, getCheckIn, getCheckOut } from './utils';
import { PaginatedResponse } from '~/utils/types/common';
import { UserItem } from '../UserForm';
import { getTimezonesList } from './getTimeZoneList';
import { TimezoneSelect } from './TimezoneSelect';
import { validate } from '~/utils/validateNotification';
import { Button, RadioInput, Textarea, QuerySelect, Select } from '~/ui/index';
import { DatePicker, Space } from 'antd';
import cn from 'classnames';
import '~/assets/css/datepicker.css';
import styles from './NotificationForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';
import CustomQuerySelect from '../Shared/CustomQuerySelect/CustomQuerySelect';

interface User {
  id: number;
  full_name: string;
  uniqueId: string;
}

export interface NotificationItem {
  id: number;
  name: string;
  module: string;
  notification_module: IModule | undefined;
  comment: string;
  accommodation: {
    id: number;
    name: string;
  };
  accommodation_id: number | null;
  status_id: number;
  category_id: number;
  group_send: string;
  condition: string;

  user_id?: User[] | number[];

  time?: string;
  date?: string;

  time_zone_correct?: string;
  time_zone?: string;
  scenario_id?: number | null;
  display?: string;
  notification_organizations: { id: number; name: string }[];
}

export interface IModule {
  id: number;
  name: string;
}

export interface NotificationValues {
  name: string;
  notification_module?: IModule;
  module: string;
  comment: string;
  accommodation?: {
    id: number;
    name: string;
  };
  accommodation_id: number | null;
  status_id: number;
  category_id: number;
  group_send: string;
  condition: string;

  organization_ids?: number[];
  notification_organizations?: { id: number; name: string }[];

  delete_user_id?: number[];
  user_id?: number[];

  date?: Dayjs | null;
  time?: string;
  time_zone?: string;

  scenario_id?: number | null;
  display?: string;
}

interface NotificationFormProps {
  onSubmit: (values: NotificationValues) => void;
  notification?: NotificationItem;
  storageKey?: string;
}

export const NotificationForm = ({
  onSubmit,
  notification,
  storageKey,
}: NotificationFormProps) => {
  const [conditionList, setConditionList] = useState<ModuleItem[]>([]);
  const [selectedUser, setSelectedUser] = useState<number[] | []>([]);
  const [searchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { t } = useTranslation();
  const checkIn = getCheckIn();
  const checkOut = getCheckOut();

  const modules = getModules();

  const selectedUserIds = useMemo(() => {
    if (!notification || !notification.user_id) return [];

    return notification.user_id.map(item =>
      typeof item === 'number' ? item : item?.id
    );
  }, [notification]);

  const params = {
    sort: '',
    perPage: 100,
    field: '',
    query: debouncedSearchTerm,
    page: 1,
  };

  const { data: notificationModules } = useQuery({
    queryFn: async () => {
      const response = await instance.get('notification-modules');
      return response.data;
    },
    queryKey: ['notification-modules'],
    onError: error => {
      toastError(error);
    },
  });

  const getModuleOptions = (data: {
    [key: string]: {
      id: number;
      name: string;
      status: string;
    };
  }) => {
    if (!data) return [];
    const modulesOptions = Object.values(data);
    return modulesOptions;
  };

  const modulesOptions = getModuleOptions(notificationModules?.data);

  const getUserById = async (userID: number): Promise<UserItem> => {
    const response = await instance.get(`admin/users/${userID}`);
    return response.data.users.data;
  };

  const { data: timezones } = useQuery({
    queryFn: async () => {
      const response = await instance.get('timezones');
      return response.data;
    },
    queryKey: ['timezones'],
    onError: error => {
      toastError(error);
    },
  });

  const timezonesList = getTimezonesList(timezones);

  const { data: scenario } = useQuery({
    queryFn: async () => {
      const response = await instance.get('notification-scenario');
      return response.data;
    },
    queryKey: ['scenario'],
    onError: error => {
      toastError(error);
    },
  });

  const { initialValues, storedInitialValues, clear, create } =
    useLocalStorage<NotificationValues>({
      initialValues: {
        name: '',
        notification_module: { id: 0, name: '' },
        module: '',
        comment: '',
        accommodation: {
          id: 0,
          name: '',
        },
        accommodation_id: null,
        status_id: 1,
        category_id: 1,
        user_id: [],
        group_send: '1',
        condition: '2',
        date: null,
        time: '',
        time_zone: '',
        scenario_id: 1,
        display: '1',
        notification_organizations: [],
      },
      key: storageKey && `${storageKey}-v1`,
      exclude: [],
    });

  const formik = useFormik<NotificationValues>({
    initialValues: storedInitialValues,

    validate,

    onSubmit: async (values: NotificationValues) => {
      try {
        await onSubmit(values);
        clear();
        formik.resetForm({ values: initialValues });
        toastSuccess(t('notification_created'));
      } catch (error) {
        toastError(error);
      }
    },
  });

  create(formik.values);

  const setFieldValue = formik.setFieldValue;
  const setValues = formik.setValues;

  useEffect(() => {
    if (!notification) return;

    setValues({
      accommodation: notification.accommodation,
      name: notification.name,
      notification_module: notification.notification_module,
      module: notification.module,
      comment: notification.comment,
      accommodation_id: notification.accommodation_id,
      status_id: notification.status_id,
      category_id: notification.category_id,
      user_id: selectedUserIds,
      group_send: notification.group_send,
      condition: notification.condition,
      date: dayjs(notification.date),
      time: notification.time,
      time_zone: notification.time_zone_correct,
      display: notification.display,
      notification_organizations: notification.notification_organizations || [],
      scenario_id:
        notification?.scenario_id !== null
          ? notification?.scenario_id
          : undefined,
    });
  }, [notification, storedInitialValues, selectedUserIds, setValues]);

  useEffect(() => {
    if (formik.values.scenario_id === null) return setConditionList([]);

    if (formik.values.scenario_id === 1) {
      setConditionList(checkIn);
    } else if (formik.values.scenario_id === 2) {
      setConditionList(checkOut);
    } else {
      return;
    }
  }, [formik.values.scenario_id]);

  useEffect(() => {
    if (formik.values.condition === '1') {
      setConditionList([]);
      setFieldValue('display', t('to_choose'));
      setFieldValue('scenario_id', null);
    } else if (formik.values.condition === '3') {
      setFieldValue('date', null);
      setFieldValue('time', null);
      setFieldValue('time_zone', t('to_choose'));
    }
  }, [formik.values.condition, setFieldValue]);

  const disabledDate = (current: Dayjs) => {
    if (!formik.values.date) {
      return current && current < dayjs().startOf('day');
    }
    return current && current < dayjs(formik.values.date).startOf('day');
  };

  useEffect(() => {
    if (!selectedUserIds.length) return;
    setSelectedUser(selectedUserIds);
  }, []);

  return (
    <form
      className={styles.form}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <div className={styles.formItems}>
        <div className={styles.radioBtnCategory}>
          <div className={styles.radioBtnGroup}>
            <span className={styles.labelText}>
              {t('send_group')}
              <span className={styles.labelStar}>*</span>
            </span>
            <div className={styles.radioBtnCategoryWrapper}>
              <RadioInput
                label={t('recipients_all')}
                name="group_send"
                value="1"
                onChange={formik.handleChange}
                checkValue={formik.values.group_send}
              />
              <RadioInput
                label={t('selective_recipients')}
                name="group_send"
                value="2"
                onChange={formik.handleChange}
                checkValue={formik.values.group_send}
              />
              <RadioInput
                label={t('organization_selection')}
                name="group_send"
                value="3"
                onChange={formik.handleChange}
                checkValue={formik.values.group_send}
              />
              <RadioInput
                label={t('to_the_first_labors')}
                name="group_send"
                value="4"
                onChange={formik.handleChange}
                checkValue={formik.values.group_send}
              />
            </div>
          </div>
          {formik.values.group_send === '2' && selectedUser && (
            <>
              <QuerySelect
                onChange={(value: number[]) => {
                  formik.setFieldValue('user_id', value);
                  setSelectedUser(value);
                }}
                value={selectedUser}
                queryFn={({
                  pageParam = 1,
                  meta,
                }): Promise<PaginatedResponse<UserItem>> =>
                  getUsers({
                    sort: '',
                    perPage: 100,
                    field: '',
                    query: meta?.searchQuery as any,
                    page: pageParam,
                  })
                }
                queryKey={['users', params]}
                star={true}
                label={t('select_recipient')}
                multiple={true}
                getItems={getUserById}
                error={formik.errors.user_id}
              />
            </>
          )}
          {formik.values.group_send === '3' && (
            <CustomQuerySelect
              label={t('organizations')}
              query="admin/organization"
              onChange={value => {
                formik.setFieldValue('notification_organizations', value);
              }}
              placeholder={t('validate_organizations')}
              value={formik.values.notification_organizations}
              isSearchEnabled
              queryKey={[
                'organizations',
                {
                  sort: '',
                  perPage: 100,
                  field: '',
                  page: 1,
                },
              ]}
              params={{
                sort: '',
                perPage: 100,
                field: '',
                page: 1,
              }}
              required
              multiselect
            />
          )}
        </div>
        {notificationModules && (
          <Select
            label={t('module')}
            value={Number(formik.values.notification_module?.id)}
            onChange={value => {
              formik.setFieldValue(
                'notification_module',
                notificationModules?.data[value]
              );
              formik.setFieldValue(
                'module',
                notificationModules?.data[value].name
              );
            }}
            options={modulesOptions ? modulesOptions : modules}
            star={false}
            size="small"
            search={false}
            multiple={false}
          />
        )}

        <div className={styles.accommodationWrapper}>
          <CustomQuerySelect
            label={t('accommodation_facility')}
            onChange={value => {
              formik.setFieldValue('accommodation', value);
            }}
            placeholder={t('select_accommodation_facility')}
            value={formik.values.accommodation}
            query="admin/accommodations"
            queryKey={['accommodations', params]}
            params={params}
            isSearchEnabled
            required
          />
        </div>

        <div className={styles.radioBtnCondition}>
          <span className={styles.labelText}>
            {t('notification_sent_terms')}
            <span className={styles.labelStar}>*</span>
          </span>
          <div className={styles.radioBtnConditionWrapper}>
            <RadioInput
              label={t('notification_send_now')}
              name="condition"
              value="2"
              onChange={formik.handleChange}
              checkValue={formik.values.condition}
            />
            <RadioInput
              label={t('notification_send_select_date')}
              name="condition"
              value="1"
              onChange={formik.handleChange}
              checkValue={formik.values.condition}
            />
            <RadioInput
              label={t('notification_send_plan')}
              name="condition"
              value="3"
              onChange={formik.handleChange}
              checkValue={formik.values.condition}
            />
          </div>
          {formik.values.condition === '1' && (
            <div
              className={cn(styles.dateConditionWrapper, styles.datesWrapper)}
            >
              <div className={cn(styles.labelWrapper, styles.labelWrapperDate)}>
                <span className={styles.labelText}>
                  {t('send_date')}
                  <span className={styles.labelStar}>*</span>
                </span>
                <Space direction="vertical" className="ant-space">
                  <DatePicker
                    className={cn('datepicker', styles.date)}
                    format="DD.MM.YYYY"
                    value={
                      formik.values.date ? dayjs(formik.values.date) : null
                    }
                    onChange={date => {
                      formik.setFieldValue('date', date ? date : null);
                    }}
                    placeholder={t('date')}
                    disabledDate={disabledDate}
                  />
                </Space>
              </div>
              <div
                className={cn(
                  styles.labelWrapper,
                  styles.labelWrapperDate,
                  styles.labelWrapperDateTime
                )}
              >
                <span className={styles.labelText}>
                  {t('send_time_label')}
                  <span className={styles.labelStar}>*</span>
                </span>
                <DatePicker.TimePicker
                  name="time"
                  className={styles.date}
                  format="HH:mm"
                  placeholder={t('time_select')}
                  onChange={dateString => {
                    formik.setFieldValue('time', dateString);
                  }}
                  value={
                    formik.values.time
                      ? dayjs(formik.values.time, 'HH:mm')
                      : null
                  }
                />
              </div>

              {timezonesList && (
                <TimezoneSelect
                  label={t('time_zone')}
                  options={timezonesList}
                  value={formik.values.time_zone || ''}
                  onChange={value => {
                    formik.setFieldValue('time_zone', value);
                  }}
                  star={true}
                />
              )}
            </div>
          )}

          {formik.values.condition === '3' && (
            <div
              className={cn(styles.dateConditionWrapper, styles.datesWrapper)}
            >
              {scenario && (
                <Select
                  label={t('script')}
                  options={scenario}
                  value={Number(formik.values.scenario_id)}
                  onChange={value => {
                    formik.setFieldValue('scenario_id', value);
                  }}
                  star={false}
                  size="small"
                  search={false}
                />
              )}

              {conditionList && (
                <Select
                  label={t('show_terms')}
                  options={conditionList}
                  value={Number(formik.values.display)}
                  onChange={value => {
                    formik.setFieldValue('display', value.toString());
                  }}
                  star={false}
                  size="small"
                  search={false}
                />
              )}
            </div>
          )}
        </div>
        <Textarea
          showCounter
          rows={4}
          label={t('comment')}
          placeholder={t('text')}
          maxLength={1000}
          value={formik.values.comment}
          onChange={formik.handleChange}
          name="comment"
          onBlur={() => formik.setFieldTouched('comment', true)}
          star={true}
          error={formik.touched.comment && Boolean(formik.errors.comment)}
          errors={formik.errors.comment}
        />
      </div>
      <Button
        type="submit"
        text={t('save_n_exit')}
        className={styles.formBtn}
        disabled={!(formik.isValid && formik.dirty)}
      />
    </form>
  );
};
