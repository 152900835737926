import { Board } from '~/components/Shared/Layout/Board/Board';
import { UserPromotionFormTable } from './UserPromotionFormTable/UserPromotionFormTable';
import styles from './UserPromotionFormBoard.module.scss';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import { Spinner } from '~/ui';

interface IUserPromotionProps {
  dates: [Dayjs | null, Dayjs | null] | null;
  data: { points: number; created_at: string }[];
  setDates: (dates: [Dayjs | null, Dayjs | null] | null) => void;
  isLoading: boolean;
  sort: { field: string; direction: string };
  setSort: ({ field, direction }: { field: string; direction: string }) => void;
}

export const UserPromotionFormBoard = ({
  data,
  isLoading,
  dates,
  setDates,
  sort,
  setSort,
}: IUserPromotionProps) => {
  const { t } = useTranslation();

  return (
    <Board classNames={styles.board}>
      <div className={styles.dateWrapper}>
        <DatePicker.RangePicker
          disabled={isLoading || data?.length === 0}
          placeholder={[t('date_validate'), t('date_validate')]}
          className={styles.datePicker}
          value={dates}
          onChange={date => setDates(date ? [date[0], date[1]] : null)}
          format="DD.MM.YYYY"
        />
      </div>
      {data && !isLoading ? (
        <UserPromotionFormTable data={data} sort={sort} setSort={setSort} />
      ) : (
        <Spinner />
      )}
    </Board>
  );
};
