import i18next from 'i18next';
import { numberValidate } from './regex';

export const validate = values => {
  const errors = {};

  //date
  if (!values.dates.start_date && values.dates.end_date) {
    const start_date = i18next.t('specify_start_date');
    errors.dates = { start_date };
  } else if (values.dates.start_date && !values.dates.end_date) {
    const end_date = i18next.t('specify_end_date');
    errors.dates = { end_date };
  }

  // Count
  if (values.login_count && !numberValidate(values.login_count)) {
    errors.login_count = i18next.t('value_must_be_number');
  } else if (
    values.login_count &&
    numberValidate(values.login_count) &&
    Number(values.login_count) < 0
  ) {
    errors.login_count = `${i18next.t('value_must_not_be_less')} 0`;
  }

  return errors;
};
