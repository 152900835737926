import { useInfiniteQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { instance } from '~/utils/api/api';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ErrorMessage } from '~/components/Shared/ErrorMessage/ErrorMessage';
import { Board } from '~/components/Shared/Layout/Board/Board';
import { TableSelect } from '~/ui/TableSelect/TableSelect';
import { Button, BUTTON_STYLES, InputBar, Spinner } from '~/ui';
import styles from './TransactionsFoodAdminBoard.module.scss';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Modal } from '~/components/Shared/Modal/Modal';
import { TransactionsFoodAdminTable } from './TransactionsFoodAdminTable/TransactionsFoodAdminTable';
import TransactionsFoodAdminModal from './TransactionsFoodAdminModal/TransactionsFoodAdminModal';
import '~/assets/css/select-table.css';
import { FoodWares } from '../TransactionForm/TransactionForm';
import QrScanner from '~/components/Shared/QrScanner/QrScanner';
import FoodAdminBoardActions from './FoodAdminBoardActions/FoodAdminBoardActions';
import { useToastError } from '~/utils/useToastError';
import ConfirmedTransactionsBoard from './ConfirmedTransactionsBoard/ConfirmedTransactionsBoard';

interface FoodPoints {
  page: number;
}

export interface ITransaction {
  id: number;
  user_name: string;
  user_id: number;
  created_at: string;
  status_type: string;
  total_sum: string;
  food_name: string;
  has_cancel_wares: boolean;
  food_wares: FoodWares[];
  status: number;
  food_orders_id: number;
  can_cancel: boolean;
  date_time: string;
  qty: number;
  status_title: string;
  total_qty: number;
  delivery_date: string;
  user: {
    uniqueId: string;
  };
  comment: string;
}

export const TransactionsFoodAdminBoard = () => {
  const { initialValues, storedInitialValues, create } =
    useLocalStorage<FoodPoints>({
      initialValues: { page: 15 },
      key: 'transactions-points',
      exclude: [],
    });
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [perPage, setPerPage] = useState(
    storedInitialValues?.page || initialValues.page
  );
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [date, setDate] = useState<Dayjs | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmedTransactions, setConfirmedTransactions] = useState<
    ITransaction[]
  >([]);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [activeTransaction, setActiveTransaction] = useState<number | null>(
    null
  );
  const { t } = useTranslation();
  const toastError = useToastError();

  const params = {
    sort: sort.direction,
    ...(date ? { date: dayjs(date).format('YYYY-MM-DD') } : null),
    perPage,
    field: sort.field,
    query: debouncedSearchTerm,
    page,
  };

  const fetchTransactions = async ({ pageParam = 1 }) => {
    const response = await instance.get(`admin/food-orders`, {
      params: { ...params, page: pageParam },
    });
    return response;
  };

  const handleScan = async (scan: { format: string; rawValue: string }[]) => {
    try {
      const { data } = await instance.post('admin/food-orders-confirm-qr', {
        ...JSON.parse(scan[0].rawValue),
      });
      onScannerClose();
      setIsConfirmModalOpen(true);
      setConfirmedTransactions(data);
    } catch (error) {
      toastError(error);
    }
  };

  const handleErrorScan = () => {
    toastError(t('qr_scan_error'));
  };

  const {
    data: transactions,
    isError,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryFn: fetchTransactions,
    queryKey: ['transactions-food-point', params],
    getNextPageParam: lastPage => {
      const nextPage = lastPage.data.current_page + 1;

      return nextPage <= lastPage.data.last_page ? nextPage : undefined;
    },
    keepPreviousData: false,
  });

  let content: React.ReactNode;

  if (transactions?.pages[0]?.data.data) {
    const allTransactions = transactions.pages.flatMap(page => page.data);
    content = (
      <TransactionsFoodAdminTable
        transactions={allTransactions?.flatMap(data => data.data)}
        sort={sort}
        setSort={setSort}
        onRowClick={(id: number) => {
          setActiveTransaction(id);
          setIsModalOpen(true);
        }}
      />
    );
  } else if (isError) {
    content = <ErrorMessage error={error} />;
  } else {
    content = <Spinner />;
  }

  const onModalClose = () => {
    setActiveTransaction(null);
    setIsModalOpen(false);
  };

  const onScannerClose = () => {
    setIsScannerOpen(false);
  };

  useEffect(() => {
    create({ page: perPage });
  }, [perPage]);

  useEffect(() => {
    refetch();
  }, [date]);

  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isFetching || !hasNextPage) return;

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
        }
      },
      { threshold: 1.0 }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [isFetching, hasNextPage, fetchNextPage]);

  return (
    <Board classNames={styles.transactionsBoard}>
      <div className={styles.transactionsOptions}>
        <div className={styles.tableHeadLeftWrapper}>
          <TableSelect perPage={perPage} setPerPage={setPerPage} />
          <div className={styles.datePickerWrapper}>
            <DatePicker
              disabled={isFetching}
              placeholder={t('date_validate')}
              className={styles.datePicker}
              value={date ? dayjs(date) : null}
              onChange={date => {
                setDate(date ? date : null);
              }}
              format="DD.MM.YYYY"
            />
          </div>
          <InputBar
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(e.target.value);
              setPage(1);
            }}
            value={searchTerm}
            placeholder={t('search_by_id_n_order_np')}
            classNameInput={styles.input}
            label={`${t('search')}:`}
            star={false}
            classNameInputWrapper={styles.inputWrapper}
          />
          <FoodAdminBoardActions onQrPress={() => setIsScannerOpen(true)} />
          <div className={styles.tableHeadRightWrapper}></div>
        </div>
      </div>
      {content}
      <div ref={loadMoreRef}></div>
      <div>{isFetchingNextPage ? <Spinner /> : ''}</div>
      <Modal
        size="full"
        modalStyles={styles.modal}
        onClose={onModalClose}
        isOpen={isModalOpen}
      >
        <TransactionsFoodAdminModal
          onCloseClick={onModalClose}
          isTransactionsLoading={isFetching}
          transaction={
            activeTransaction
              ? transactions?.pages
                  .flatMap(page => {
                    return page.data;
                  })
                  .flatMap(data => data.data)
                  .find(
                    (transaction: ITransaction) =>
                      transaction.id === activeTransaction
                  )
              : {}
          }
        />
      </Modal>
      <Modal
        size="full"
        modalStyles={styles.modal}
        onClose={() => setIsConfirmModalOpen(false)}
        isOpen={isConfirmModalOpen}
      >
        <ConfirmedTransactionsBoard
          sort={sort}
          setSort={setSort}
          transactions={confirmedTransactions}
          onCloseClick={() => {
            setIsConfirmModalOpen(false);
            setIsScannerOpen(true);
          }}
        />
      </Modal>
      <Modal
        modalStyles={styles.scannerModal}
        onClose={onScannerClose}
        isOpen={isScannerOpen}
      >
        <span className={styles.scannerTitle}>{t('scan_qr_title')}</span>
        <div className={styles.scannerWrapper}>
          {isScannerOpen ? (
            <QrScanner
              handleErrorScan={handleErrorScan}
              handleSuccessScan={handleScan}
            />
          ) : (
            ''
          )}
        </div>
        <Button
          className={styles.closeButton}
          text={t('close')}
          buttonStyle={BUTTON_STYLES.EMPTY}
          onClick={onScannerClose}
        />
      </Modal>
    </Board>
  );
};
