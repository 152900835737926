import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '~/components/Shared/Modal/Modal';
import s from '~/theme/ui/modal.module.scss';
import { Button } from '~/ui';

interface ModalConfirmProps {
  children: (open: () => void) => JSX.Element;
  title: string;
  description?: string;
  onConfirm: () => void;
}

const ModalConfirm = (props: ModalConfirmProps) => {
  const { children, title, description, onConfirm } = props;
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const open = () => setOpen(true);
  const close = () => setOpen(false);

  const handleConfirm = async () => {
    await onConfirm();
    close();
  };

  return (
    <>
      {children(open)}
      <Modal isOpen={isOpen} onClose={close}>
        <div className={s.container}>
          <span className={s.title}>{title}</span>
          {description && <p className={s.description}>{description}</p>}
          <div className={s.actions}>
            <Button text={t('yes')} color="red" onClick={handleConfirm} />
            <Button text={t('no')} color="gray" onClick={close} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalConfirm;
