import { useTranslation } from 'react-i18next';
import CategoryList from '~/components/CategoryList/CategoryList';
import { useToastError } from '~/utils/useToastError';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import styles from './MobileKnowledgeBasePage.module.scss';
import MobileHeading from '~/components/MobileHeading/MobileHeading';
import { useSearchParams } from 'react-router-dom';
import SpinnerCircle from '~/ui/SpinnerCircle/SpinnerCircle';

const MobuleKnowledgeBasePage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { t } = useTranslation();
  const toastError = useToastError();

  const { data: knowledge, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/knowledge-base-categories', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data.data;
    },
    queryKey: ['knowledge-base'],
    onError: error => {
      toastError(error);
    },
  });

  return (
    <main className={styles.mainContainer}>
      <MobileHeading title={t('knowledge_base')} />
      <div className={styles.row} />
      <div className={styles.categoriesWrapper}>
        {isLoading || !knowledge ? (
          <SpinnerCircle />
        ) : (
          <CategoryList data={knowledge} mobile={true} />
        )}
      </div>
    </main>
  );
};

export default MobuleKnowledgeBasePage;
