import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { useToastError } from '~/utils/useToastError';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { DatePicker } from 'antd';
import { useFormik } from 'formik';
import { validate } from '~/utils/validateAnnouncement';
import dayjs, { Dayjs } from 'dayjs';
import CloseIcon from '~/assets/svg/newSvg/close-cross.svg?react';
import { Button, InputBar, IconButton } from '~/ui/index';
import PhotoIcon from './images/photo-icon.svg?react';
import '~/assets/css/datepicker-announcement.css';
import styles from './AnnouncementForm.module.scss';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Shared/Modal/Modal';
import PhotoPickerContainer, {
  IPhoto,
} from './PhotoPickerContainer/PhotoPickerContainer';
import CustomQuerySelect from '../Shared/CustomQuerySelect/CustomQuerySelect';
import { AuthenticationContext } from '../Authentication/AuthenticationProvider';
import {
  checkIfUserAdmin,
  checkIfUserAssetAdmin,
  checkIfUserSuperAdmin,
} from '~/utils/getUserRole';
import ReactQuill from 'react-quill';
import TextEditor from '~/ui/TextEditor/TextEditor';

interface AnnouncementAccommodation {
  id: number;
  name: string;
  pivot: {
    accommodation_id: number;
    announcement_id: number;
  };
}

export interface AnnouncementItem {
  module: string;
  announcement_accommodations: AnnouncementAccommodation[];
  announcement_organizations: { id: number; name: string }[];
  status_id: number | null;
  name: string;
  text: string;
  start_date: string;
  end_date: string;
  photos: IPhoto[];
  deletedPhotoIds: number[];
}

export interface AnnouncementValues {
  module?: number | null;
  status_id: number | null;
  name: string;
  text: string;
  start_date: Dayjs | null;
  end_date: Dayjs | null;
  announcement_organizations: { id: number; name: string }[];
  announcement_accommodations: AnnouncementAccommodation[];
  photos: IPhoto[];
  deletedPhotoIds: number[];
}

interface AnnouncementFormProps {
  announcement?: AnnouncementItem;
  onSubmit: (values: AnnouncementValues) => void;
  storageKey?: string;
  isLoading?: boolean;
}

export const AnnouncementForm = ({
  isLoading,
  onSubmit,
  announcement,
  storageKey,
}: AnnouncementFormProps): JSX.Element => {
  const { user } = useContext(AuthenticationContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const debouncedSearchTerm = useDebounce('', 500);
  const toastError = useToastError();
  const { t } = useTranslation();
  const isSuperAdmin = checkIfUserSuperAdmin(user?.data.role_name);
  const isAssetAdmin = checkIfUserAssetAdmin(user?.data.role_name);
  const isAdmin = checkIfUserAdmin(user?.data.role_name);
  const quillRef = useRef<ReactQuill | null>(null);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline', 'link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ align: [] }],
        ],
      },
    }),
    []
  );

  const { initialValues, storedInitialValues, clear, create } =
    useLocalStorage<AnnouncementValues>({
      initialValues: {
        module: 1,
        status_id: 1,
        name: '',
        text: '',
        start_date: null,
        end_date: null,
        announcement_accommodations: [],
        announcement_organizations: [],
        photos: [],
        deletedPhotoIds: [],
      },
      key: storageKey && `${storageKey}-v1`,
      exclude: ['deletedPhotoIds'],
    });

  const formik = useFormik<AnnouncementValues>({
    initialValues: storedInitialValues,

    validate,

    onSubmit: async (values: AnnouncementValues) => {
      try {
        await onSubmit(values);
        clear();
        formik.resetForm({ values: initialValues });
      } catch (error) {
        toastError(error);
      }
    },
  });

  create(formik.values);

  const setValues = formik.setValues;

  const onImagesChange = (image: IPhoto) => {
    formik.values.deletedPhotoIds.filter(
      deletedPhotoId => deletedPhotoId !== image.id
    );
    if (formik.values.photos?.find(photo => photo.id === image.id)) {
      formik.setFieldValue(
        'photos',
        formik.values.photos?.filter(photo => photo.id !== image.id)
      );
    } else if (formik.values.photos.length < 5) {
      formik.setFieldValue('photos', [...formik.values.photos, image]);
    } else {
      return;
    }
  };

  const handleRemoveImage = (id: number) => {
    formik.setFieldValue(
      'photos',
      formik.values.photos.filter(photo => photo.id !== id)
    );
    formik.setFieldValue('deletedPhotoIds', [
      ...formik.values.deletedPhotoIds,
      id,
    ]);
  };

  useEffect(() => {
    if (!announcement) return;

    setValues({
      module: Number(announcement.module),
      status_id: announcement.status_id,
      name: announcement.name,
      text: announcement.text,
      start_date: dayjs(announcement.start_date),
      end_date: dayjs(announcement.end_date),
      photos: announcement.photos,
      deletedPhotoIds: [],
      announcement_accommodations: announcement.announcement_accommodations,
      announcement_organizations: announcement.announcement_organizations,
    });
  }, [announcement, storedInitialValues, setValues]);

  const handleResetImages = () => {
    formik.setFieldValue('photos', announcement ? announcement.photos : []);
  };

  return (
    <div className={styles.formWrapper}>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.formItems}>
          <CustomQuerySelect
            label={t('accommodation_facility')}
            onChange={value => {
              formik.setFieldValue('announcement_accommodations', value);
            }}
            value={formik.values.announcement_accommodations}
            query="admin/accommodations"
            placeholder={t('accommodations_validate')}
            isSearchEnabled
            disabled={
              (formik.values.status_id !== 1 &&
                formik.values.status_id === 3) ||
              (isAssetAdmin && isSuperAdmin && isAdmin) ||
              isLoading
            }
            queryKey={['announcement_accommodations', debouncedSearchTerm]}
            params={{
              sort: '',
              perPage: 100,
              field: '',
              page: 1,
            }}
            required
            multiselect
          />
          <CustomQuerySelect
            label={t('organizations')}
            query="admin/organization"
            onChange={value => {
              formik.setFieldValue('announcement_organizations', value);
            }}
            placeholder={t('validate_organizations')}
            value={formik.values.announcement_organizations}
            isSearchEnabled
            queryKey={[
              'organizations',
              {
                sort: '',
                perPage: 100,
                field: '',
                page: 1,
              },
            ]}
            disabled={
              (formik.values.status_id !== 1 &&
                formik.values.status_id === 3) ||
              (isAssetAdmin && isSuperAdmin && isAdmin) ||
              isLoading
            }
            params={{
              sort: '',
              perPage: 100,
              field: '',
              page: 1,
            }}
            required
            multiselect
          />
          <InputBar
            name="name"
            value={formik.values.name}
            disabled={
              (formik.values.status_id !== 1 &&
                formik.values.status_id === 3) ||
              (isAssetAdmin && isSuperAdmin && isAdmin) ||
              isLoading
            }
            label={t('head')}
            star={true}
            placeholder={t('enter_head')}
            onChange={formik.handleChange}
            errors={formik.errors.name}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
          />

          <TextEditor
            ref={quillRef}
            label={t('announcement_text')}
            star={true}
            name="text"
            value={formik.values.text}
            placeholder={t('text')}
            modules={modules}
            showCounter
            maxLength={1000}
            onChange={formik.setFieldValue}
            disabled={
              (formik.values.status_id !== 1 &&
                formik.values.status_id === 3) ||
              (isAssetAdmin && isSuperAdmin && isAdmin) ||
              isLoading
            }
            error={formik.touched.text && Boolean(formik.errors.text)}
            errors={formik.errors.text}
          />

          <div className={styles.imagesContainer}>
            <ul className={styles.imagesList}>
              {formik.values?.photos?.map(image => (
                <div className={styles.imageWrapper}>
                  <img
                    key={image.id}
                    className={styles.image}
                    src={image.full_photo_url}
                    alt="Announcement"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveImage(image.id)}
                    className={styles.deleteImageButton}
                  >
                    <CloseIcon />
                  </button>
                </div>
              ))}
            </ul>
            <IconButton
              onClick={() => setIsModalOpen(true)}
              className={styles.pickPhotoButton}
            >
              <PhotoIcon />
              <span className={styles.pickPhotoText}>
                {t('choose_photo_for_announcement')}
              </span>
            </IconButton>
          </div>
          <div className={styles.dateWrapper}>
            <div className={styles.labelWrapper}>
              <span className={styles.labelText}>
                {t('date')}
                <span className={styles.labelStar}>*</span>
              </span>
              <DatePicker.RangePicker
                name="end_date"
                className="datepicker"
                format="DD.MM.YYYY"
                value={[formik.values.start_date, formik.values.end_date]}
                onChange={dates => {
                  if (dates) {
                    formik.setFieldValue('start_date', dates[0]);
                    formik.setFieldValue('end_date', dates[1]);
                  } else {
                    formik.setFieldValue('start_date', null);
                    formik.setFieldValue('end_date', null);
                  }
                }}
                placeholder={[t('begin_activity'), t('end_activity')]}
                onBlur={() => formik.setFieldTouched('end_date', true)}
                disabledDate={d => d.isBefore(dayjs(formik.values.start_date))}
                disabled={
                  (formik.values.status_id !== 1 &&
                    formik.values.status_id === 3) ||
                  (isAssetAdmin && isSuperAdmin && isAdmin) ||
                  isLoading
                }
              />
              <div className={styles.dateErrors}>
                <span className={styles.errorText}>
                  {formik.touched.end_date &&
                    Boolean(formik.errors.end_date) &&
                    t('begin_activity_validate')}
                </span>
                <span className={styles.errorText}>
                  {formik.touched.end_date &&
                    Boolean(formik.errors.end_date) &&
                    t('end_activity_validate')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <Button
          type="submit"
          text={t('send')}
          className={styles.formBtn}
          disabled={!((formik.isValid && formik.dirty) || formik.isSubmitting)}
        />
        <Modal
          modalStyles={styles.photosModal}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <PhotoPickerContainer
            initialPhotos={announcement ? announcement.photos : []}
            selectedImages={formik.values.photos}
            onImageChange={onImagesChange}
            onCloseClick={() => setIsModalOpen(false)}
            onResetClick={handleResetImages}
          />
        </Modal>
      </form>
    </div>
  );
};
