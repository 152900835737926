import i18next from 'i18next';
import { phraseValidate } from '../utils/regex';

export const validate = values => {
  const errors = {};

  // name

  if (!values.name) {
    errors.name = i18next.t('name_validate_secondary');
  } else if (values.name.length < 2) {
    errors.name = i18next.t('at_least_two_characters');
  } else if (values.name.length >= 32) {
    errors.name = i18next.t('no_more_thirty_characters');
  }

  //   asset

  if (values.assets === null) {
    errors.assets = i18next.t('validate_asset');
  }

  // timezone
  if (values.timezone.id === '') {
    errors.timezone = i18next.t('timezone_validate');
  }

  //   description

  if (!values.description) {
    errors.description = i18next.t('description_validate');
  } else if (values.description.length < 2) {
    errors.description = i18next.t('at_least_two_characters');
  }

  //   address

  if (!values.address) {
    errors.address = i18next.t('address_validate');
  } else if (values.address.length < 2) {
    errors.address = i18next.t('at_least_two_characters');
  } else if (values.address.length >= 150) {
    errors.address = i18next.t('no_more_than_150');
  }

  if (!values.food_accommodation?.length) {
    errors.food_accommodation = i18next.t('select_accommodation');
  }

  // Food point type

  if (!values.pointObject.id) {
    errors.pointObject = i18next.t('select_food_point_type');
  }

  // Box_office

  if(values.box_office.id === 0) {
    errors.box_office = i18next.t('cash_register_placeholder')
  }

  return errors;
};
