import i18next from 'i18next'
import { Dayjs } from 'dayjs'
import { numberValidate } from './regex'

export const validate = values => {
    const errors = {}

    // Asset
    if (values.asset.id === 0) {
        errors.asset = { name: i18next.t('validate_asset')}
    }

    // Name
    if (!values.level_name) {
        errors.level_name = i18next.t('validate_name')
    }

    // Points
    if (!values.points) {
        errors.points = i18next.t('validate_points_quantity')
    } else if(values.points && !numberValidate(values.points)){
        errors.points = i18next.t('value_must_be_number')
    }else if(values.points && numberValidate(values.points) && Number(values.points)<=0){
        errors.points = `${i18next.t('value_must_not_be_less')} 0`
    }

    // Discount
    if (!values.discount) {
        errors.discount = i18next.t('validate_discount_quantity')
    } else if(values.discount && !numberValidate(values.discount)){
        errors.discount = i18next.t('value_must_be_number')
    } else if(values.discount && numberValidate(values.discount) && Number(values.discount)>100){
        errors.discount = `${i18next.t('level_should_not_exceed')} 100%`
    }else if(values.discount && numberValidate(values.discount) && Number(values.discount)<=0){
        errors.discount = `${i18next.t('value_must_not_be_less')} 0%`
    }

    // Color
    if (!values.color.id) {
        errors.color = { name: i18next.t('validate_color') }
    }

    return errors
}
