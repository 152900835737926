import { useTranslation } from 'react-i18next';
import CreateLevel from '~/components/CreateLevel/CreateLevel';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';

const CreateLevelPage = () => {
  const { t } = useTranslation();

  return (
    <Page heading={<Heading text={t('creating_level')} />}>
      <CreateLevel />
    </Page>
  );
};

export default CreateLevelPage;
