import DeleteIcon from '~/assets/svg/newSvg/basket.svg?react';
import ModalConfirm from '~/components/Shared/ModalConfirm';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { IconButton } from '~/ui';
import ArrowIcon from '~/assets/svg/newSvg/arrow-select.svg?react';

import styles from './Category.module.scss';
import { IKnowledge } from '../CategoryList';
import { SvgIcon } from '~/ui/SvgIcon/SvgIcon';
import classNames from 'classnames';

interface ICategoryProps {
  data: IKnowledge;
  onClick: (id: number) => void;
  mobile?: boolean;
}

const Category = ({ data, onClick, mobile = false }: ICategoryProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const articleCountTitle =
    data.articles_count === 1
      ? t('article')
      : data.articles_count > 1 && data.articles_count < 5
        ? t('less_five_articles')
        : t('articles');

  const handleConfirm = () => {
    onClick(data.id);
  };

  const handleNavigate = () => {
    navigate(`/knowledge-base/${data.id}/edit`);
  };

  return (
    <li className={styles.category}>
      <Link
        className={styles.categoryLink}
        to={
          mobile
            ? `/categories/${data.id}/articles?token=${token}`
            : `/knowledge-base/${data.id}`
        }
      >
        <div className={styles.svgContainer}>
          <SvgIcon svgString={data.svg_icon} className={styles.icon} />
        </div>
        <div className={classNames(styles.info, mobile && styles.infoMobile)}>
          <strong className={styles.title}>{data.name}</strong>
          {!data.articles_count ? (
            <span className={styles.articlesAmount}>{t('empty')}</span>
          ) : (
            <span className={styles.articlesAmount}>
              {data.articles_count} {articleCountTitle}
            </span>
          )}
        </div>
      </Link>
      {!mobile ? (
        <div className={styles.controls}>
          <IconButton onClick={handleNavigate}>
            <Edit />
          </IconButton>
          <ModalConfirm
            title={t('delete_category_confirm')}
            description={t('delete_category_description')}
            onConfirm={handleConfirm}
          >
            {open => (
              <IconButton onClick={open}>
                <DeleteIcon />
              </IconButton>
            )}
          </ModalConfirm>
        </div>
      ) : (
        <Link
          to={`/categories/${data.id}/articles?token=${token}`}
          className={styles.arrowLink}
        >
          <ArrowIcon className={styles.categoryArrow} />
        </Link>
      )}
    </li>
  );
};

export default Category;
