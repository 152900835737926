import i18next from 'i18next'
import { Dayjs } from 'dayjs'

export const validateCondition = values => {
    const errors = {}

    // Department
    if (values.department.id === 0) {
        errors.department = i18next.t('validate_chaptert')
    }

    // Description
    if (values.description.id === 0) {
        errors.description = i18next.t('validate_condition')
    }

    // Meaning
    if (!values.meaning) {
        errors.meaning = i18next.t('validate_meaning')
    } else if (!/^\d+$/.test(values.meaning)) {
        errors.meaning = i18next.t('must_be_number')
    } else if (parseInt(values.meaning) <= 0) {
        errors.meaning = i18next.t('must_be_greater_than_zero')
    }

    return errors
}
