import React, { ReactNode } from 'react';
import StatusIcons from '../PreviewPhone/images/status.svg?react';
import StarIcon from '~/assets/svg/newSvg/options/star.svg?react';
import ArrowRightIcon from '../PreviewPhone/images/arrow-right-icon.svg?react';
import HomeIcon from '../PreviewPhone/images/home-icon.svg?react';
import OrdersIcon from '../PreviewPhone/images/orders-icon.svg?react';
import FoodsIcon from '../PreviewPhone/images/foods-icon.svg?react';
import FeedbackIcon from '../PreviewPhone/images/feedback-icon.svg?react';
import OrderIcon from '~/assets/svg/newSvg/aside/transactions.svg?react';
import AnaliticIcon from '~/assets/svg/newSvg/aside/kitchen.svg?react';
import LoyaltyIcon from '~/assets/svg/newSvg/aside/star.svg?react';
import BusinessTripIcon from '~/assets/svg/newSvg/aside/calendar.svg?react';
import ellipse from '../PreviewPhone/images/ellipse.png';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import styles from './PreviewCardPhone.module.scss';

interface IPreviewPhoneProps {
  color: string | undefined;
  textColor: string | undefined;
  children: ReactNode;
  username: string | undefined;
  company: string | undefined;
  level: string;
  discount: number;
  balance: number;
  points: number;
  cardId: number;
}

const PreviewCardPhone: React.FC<IPreviewPhoneProps> = ({
  color,
  textColor,
  username,
  company,
  balance,
  discount,
  level,
  points,
  children,
  cardId,
}) => {
  const { t } = useTranslation();
  const value = 2645;

  const progressStyle = {
    background: `linear-gradient(to right, #ffcc00 0%, #ffcc00 ${
      (value - 2000) / 20
    }%, #e0e0e0 ${(value - 2000) / 20}%)`,
  };

  return (
    <div className={styles.previewPhone}>
      <div className={styles.previewPhoneHead}>
        <span className={styles.previewPhoneHeadTime}>
          {dayjs(new Date()).format('HH:mm')}
        </span>
        <div className={styles.previewPhoneHeadBlock} />
        <StatusIcons className={styles.previewPhoneHeadIcons} />
      </div>
      <div className={styles.previewScrollable}>
        <div className={styles.heading}>
          <ArrowRightIcon className={styles.headingArrowSvg} />
          <p className={styles.headihgTitle}>{t('personal_account')}</p>
          <div>{'?'}</div>
        </div>
        <div className={styles.userInfoWrapper}>
          <div className={styles.company}>
            <img src="" alt="logo" />
          </div>
          <div className={styles.userInfo}>
            <span className={styles.username}>{username}</span>
            <span className={styles.company}>
              {company ? company : 'company'}
            </span>
          </div>
        </div>
        <div className={styles.cardWrapper}>
          <div
            className={styles.card}
            style={{ background: `linear-gradient(to right, ${color})` }}
          >
            <div className={styles.cardHeading}>
              <div className={styles.balanceWrapper}>
                <span
                  className={styles.balanceTitle}
                  style={{ color: textColor }}
                >
                  {t('cumulative_total')}
                </span>
                <p className={styles.balance} style={{ color: textColor }}>
                  {balance}
                </p>
              </div>
              <div className={styles.cardInfo}>
                <div className={styles.levelWrapper}>
                  <p className={styles.levelInfo} style={{ color: textColor }}>
                    {level}level
                  </p>
                  <span className={styles.discount}>{`${
                    discount ? discount : 0
                  }%`}</span>
                </div>
                <p className={styles.points} style={{ color: textColor }}>
                  <StarIcon
                    className={styles.pointsIcon}
                    style={{ stroke: textColor }}
                  />{' '}
                  {points ? points : 0} {t('points')}
                </p>
              </div>
            </div>
            <div className={styles.cardFooter}>
              <p className={styles.user} style={{ color: textColor }}>
                {username}
              </p>
              <span className={styles.cardId} style={{ color: textColor }}>
                {cardId ? cardId : '0000'}
              </span>
            </div>
            <img
              src={ellipse}
              alt="bg-ellipse"
              className={styles.bgEllipseTop}
            />
            <img
              src={ellipse}
              alt="bg-ellipse"
              className={styles.bgEllipseBottom}
            />
          </div>
        </div>
        <div className={styles.progressBarWrapper}>
          <p className={styles.progressTitle}>{t('for_next_status')}</p>
          <div className={styles.progressWrapper}>
            <input
              type="range"
              min={2000}
              max={4000}
              value={2645}
              className={styles.progressBar}
              style={progressStyle}
            />
            <div className={styles.progressValueWrapper}>
              <span>2000</span>
              <span>4000</span>
            </div>
          </div>
        </div>

        <div className={styles.menuWrapper}>
          <ul className={styles.menuList}>
            <li className={styles.menuItem}>
              <div className={styles.menuItemWrapper}>
                <div className={styles.iconWrapper}>
                  <OrderIcon className={styles.menuIcon} />
                </div>
                <p className={styles.itemTitle}>{t('transaction_history')}</p>
                <ArrowRightIcon className={styles.itemArrow} />
              </div>
            </li>
            <li className={styles.menuItem}>
              <div className={styles.menuItemWrapper}>
                <div className={styles.iconWrapper}>
                  <AnaliticIcon className={styles.menuIcon} />
                </div>
                <p className={styles.itemTitle}>{t('food_analytics')}</p>
                <ArrowRightIcon className={styles.itemArrow} />
              </div>
            </li>
            <li className={styles.menuItem}>
              <div className={styles.menuItemWrapper}>
                <div className={styles.iconWrapper}>
                  <BusinessTripIcon className={styles.menuIcon} />
                </div>
                <p className={styles.itemTitle}>{t('my_shifts')}</p>
                <ArrowRightIcon className={styles.itemArrow} />
              </div>
            </li>
            <li className={styles.menuItem}>
              <div className={styles.menuItemWrapper}>
                <div className={styles.iconWrapper}>
                  <LoyaltyIcon className={styles.menuIcon} />
                </div>
                <p className={styles.itemTitle}>{t('promotions')}</p>
                <ArrowRightIcon className={styles.itemArrow} />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.bottomContainer}>
        <div className={styles.navigation}>
          <button
            style={{ backgroundColor: color }}
            className={cn(styles.tab, styles.tabActive)}
          >
            <HomeIcon stroke={textColor} />
            <span style={{ color: textColor }} className={styles.tabName}>
              {t('MainTitle')}
            </span>
          </button>
          <button className={styles.tab}>
            <OrdersIcon />
            <span className={styles.tabName}>{t('applications')}</span>
          </button>
          <button className={styles.tab}>
            <FoodsIcon />
            <span className={styles.tabName}>{t('nutrition')}</span>
          </button>
          <button className={styles.tab}>
            <FeedbackIcon />
            <span className={styles.tabName}>{t('feedback_shortened')}</span>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default PreviewCardPhone;
