import { NavLink } from 'react-router-dom';
import ArrowRight from '~/assets/svg/newSvg/arrow-right.svg?react';
import styles from './AsideList.module.scss';
import { ReactNode, useContext } from 'react';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfFoodAdmin, checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useToastError } from '~/utils/useToastError';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import Notifications from '../../Header/NotificationsContainer/Notifications/Notifications';

interface AsideItem {
  id: number;
  path: string;
  text: string;
  icon: ReactNode;
  sectionId: number | null;
  type: string;
}

interface AsideListProps {
  isExpanded: boolean;
  list: AsideItem[];
}

const Content = ({
  path,
  isExpanded,
}: {
  path: string;
  isExpanded: boolean;
}) => {
  const toastError = useToastError();

  const { data: notifications } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/new-records');
      return data;
    },
    queryKey: ['notifications-count'],
    onError: error => {
      toastError(error);
    },
  });

  const currentNotification =
    path === '/requests'
      ? notifications?.new_tickets_count
      : path === '/transactions'
        ? notifications?.new_orders_count
        : path === '/feedback'
          ? notifications?.new_feedbacks_count
          : '';

  return (
    <div className={styles.asideRight}>
      {<Notifications count={currentNotification} />}
      {isExpanded ? <ArrowRight className={styles.arrow} /> : ''}
    </div>
  );
};

const AsideList = ({ list, isExpanded }: AsideListProps): JSX.Element => {
  const { user } = useContext(AuthenticationContext);
  const isSuperAdminAccess = checkIfUserSuperAdmin(user?.data.role_name);
  const isFoodAdminAccess = checkIfFoodAdmin(user?.data.role_name);
  const allowedSections = user?.data.sections.map(item => item.id);

  const filteredList = list.filter(item => {
    if (item.path === '/settings') {
      return true;
    }
    if (isFoodAdminAccess) {
      return false;
    }

    if (isSuperAdminAccess) {
      return true;
    }

    return item.sectionId !== null && allowedSections?.includes(item.sectionId);
  });

  const { t } = useTranslation();

  return (
    <ul
      className={cn(
        styles.aside__list,
        isExpanded ? styles.aside__listExpanded : undefined
      )}
    >
      {filteredList.map(({ id, path, text, icon }) => {
        return (
          <li key={id} className={styles.aside__item}>
            <NavLink
              to={path}
              className={({ isActive }) =>
                `${isActive ? styles.active : ''} ${styles.aside__link}`
              }
            >
              <span className={styles.aside__icon}>{icon}</span>

              <div className={styles.aside__text}>
                {isExpanded ? <span>{t(text)}</span> : ''}
                {isFoodAdminAccess ? (
                  ''
                ) : (
                  <Content path={path} isExpanded={isExpanded} />
                )}
              </div>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export default AsideList;
