import { Root, List, Trigger, Content } from '@radix-ui/react-tabs';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StockBoard from './StockBoard/StockBoard';

import cn from 'classnames';
import styles from './PromotionsBoard.module.scss';
import PointsBoard from './PointsBoard/PointsBoard';
import LevelsBoard from './LevelsBoard/LevelsBoard';

const ACTIVE_TABS = {
  STOCK: 'stock',
  POINTS: 'points',
  LEVELS: 'levels',
};

const PromotionsBoard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(ACTIVE_TABS.STOCK);

  const handleTabChange = (value: string) => {
    setActiveTab(value);
    navigate(`/promotions/${value}`);
  };

  useEffect(() => {
    const currentPath = location.pathname
      .split('/')
      .filter(item => item !== '');

    if (currentPath.length < 2) {
      setActiveTab(ACTIVE_TABS.STOCK);
    } else {
      setActiveTab(currentPath[currentPath.length - 1]);
    }
  }, []);

  return (
    <div className={styles.mainContaine}>
      <Root
        value={activeTab}
        onValueChange={handleTabChange}
        className={styles.roots}
      >
        <List className={styles.rootsList}>
          <Trigger
            value={ACTIVE_TABS.STOCK}
            className={cn(styles.rootsTrigger, {
              [styles.activeTrigger]: activeTab === ACTIVE_TABS.STOCK,
            })}
          >
            {t('stock')}
          </Trigger>
          <Trigger
            value={ACTIVE_TABS.POINTS}
            className={cn(styles.rootsTrigger, {
              [styles.activeTrigger]: activeTab === ACTIVE_TABS.POINTS,
            })}
          >
            {t('points_history')}
          </Trigger>
          <Trigger
            value={ACTIVE_TABS.LEVELS}
            className={cn(styles.rootsTrigger, {
              [styles.activeTrigger]: activeTab === ACTIVE_TABS.LEVELS,
            })}
          >
            {t('levels')}
          </Trigger>
        </List>

        <Content value={ACTIVE_TABS.STOCK}>
          <StockBoard />
        </Content>
        <Content value={ACTIVE_TABS.POINTS}>
          <PointsBoard />
        </Content>
        <Content value={ACTIVE_TABS.LEVELS}>
          <LevelsBoard />
        </Content>
      </Root>
    </div>
  );
};

export default PromotionsBoard;
