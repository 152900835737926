import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import Block from '~/assets/svg/newSvg/block.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import ModalConfirm from '../../Shared/ModalConfirm';
import { IconButton, IconButtonLink } from '~/ui';
import { ISlot } from '../SlotsBoard';

import styles from './SlotsTable.module.scss';
import dayjs from 'dayjs';
import EntertainmentStatusBadge from '~/components/EntertainmentBoard/EntertainmentTable/EntertainmentStatusBadge/EntertainmentStatusBadge';
import { useParams } from 'react-router-dom';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';

interface IEntertainmentProps {
  refetch: () => void;
  places: ISlot[];
  sort: any;
  setSort: ({ field, direction }: any) => void;
  selectedItems: number[];
  handleToggleAll: () => void;
  handleToggle: (id: number) => void;
}

const SlotsTable = ({
  refetch,
  places,
  sort,
  setSort,
  selectedItems,
  handleToggleAll,
  handleToggle,
}: IEntertainmentProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const client = useQueryClient();

  const toastSuccess = useToastSuccess();

  const { mutate: blockSlot } = useMutation(
    async (itemId: string) => {
      const response = await instance.delete(
        `admin/entertainment-date-slots/${itemId}`
      );
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['entertainment-date-slots']);
        refetch();
        toastSuccess(t('accommodation_removed'));
      },
    }
  );

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead
            className={styles.tableCellHead}
            sortable={false}
            field="checkbox"
          >
            <CustomCheckbox
              isChecked={
                selectedItems.length === places?.length && !!places?.length
              }
              onChange={handleToggleAll}
            />
          </TableCellHead>
          <TableCellHead field="start_time">{t('time')}</TableCellHead>
          <TableCellHead field="users_book_count">
            {t('records_amount')}
          </TableCellHead>
          <TableCellHead field="max_users_count">
            {t('available_records')}
          </TableCellHead>
          <TableCellHead field="status_id">{t('status')}</TableCellHead>
          <TableCellHead field="actions" sortable={false}>
            {t('actions')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {places?.map(item => {
            return (
              <TableRow key={item?.id}>
                <TableCell>
                  <CustomCheckbox
                    isChecked={selectedItems.includes(item.id)}
                    onChange={() => handleToggle(item.id)}
                  />
                </TableCell>
                <TableCell>
                  {dayjs(item.start_time, 'HH:mm').format('HH:mm')}-
                  {dayjs(item.end_time, 'HH:mm').format('HH:mm')}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {item.users_count}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {item.max_users_count}
                </TableCell>
                <TableCell className={styles.statusCell}>
                  <EntertainmentStatusBadge status={item.status} />
                </TableCell>
                <TableCell>
                  <div className={styles.entertainmentTableActions}>
                    <IconButtonLink
                      to={`/entertainment/${id}/slots/${item.id}`}
                      className={styles.entertainmentTableBtnLink}
                    >
                      <Edit />
                    </IconButtonLink>
                    {item.status.is_active && (
                      <ModalConfirm
                        title={t('block_slot_question')}
                        description={t('impossible_restore_slot')}
                        onConfirm={() => {
                          blockSlot(String(item.id));
                        }}
                      >
                        {open => (
                          <IconButton onClick={open}>
                            <Block />
                          </IconButton>
                        )}
                      </ModalConfirm>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default SlotsTable;
