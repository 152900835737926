import EntertainmentBoard from '~/components/EntertainmentBoard/EntertainmentBoard';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { useTranslation } from 'react-i18next';

const Entertainment = () => {
  const { t } = useTranslation();

  return (
    <Page heading={<Heading text={t('entertainment')} />}>
      <EntertainmentBoard />
    </Page>
  );
};

export default Entertainment;
