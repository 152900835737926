import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButton, Textarea } from '~/ui';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';
import SlotsUserStatusBadge from './SlotsUserStatusBadge/SlotsUserStatusBadge';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Checkmark from '~/assets/svg/newSvg/mark-icon.svg?react';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Block from '~/assets/svg/newSvg/block.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import { validate } from '~/utils/validateSlotRecord';
import { useToastError } from '~/utils/useToastError';
import { IAppointment } from '../SlotUsersBoard';
import { useParams } from 'react-router-dom';
import { instance } from '~/utils/api/api';
import { useFormik } from 'formik';
import { useState } from 'react';

import styles from './SlotUsersTable.module.scss';
import ModalConfirm from '~/components/Shared/ModalConfirm';

interface IEntertainmentProps {
  appointments: { data: IAppointment[] };
  sort: any;
  isAdding: boolean;
  setIsAdding: (isAdding: boolean) => void;
  setSort: ({ field, direction }: any) => void;
}

const SlotUsersTable = ({
  appointments,
  sort,
  isAdding,
  setSort,
  setIsAdding,
}: IEntertainmentProps) => {
  const { t } = useTranslation();
  const toastError = useToastError();
  const [editingId, setEditingId] = useState<null | number>(null);
  const { slotId } = useParams();
  const client = useQueryClient();

  const { values, setFieldValue, resetForm, setValues, handleSubmit, errors } =
    useFormik({
      initialValues: {
        user: { id: 0, name: '', full_name: '', uniqueId: '', code: '' },
        comment: '',
        status: { id: 0, type: '', name: '' },
      },
      validate,
      onSubmit: () => {
        editingId ? handleEditAppointment() : handleCreateAppointment();
      },
    });

  const params = {
    sort: '',
    perPage: 100,
    field: '',
    page: 1,
  };

  const handleCreateAppointment = async () => {
    try {
      await instance.post('admin/entertainment-date-book', {
        comment: values.comment,
        user_id: values.user.id,
        date_slot_id: Number(slotId),
      });
      client.invalidateQueries(['slot', slotId]);
      resetForm();
      setIsAdding(false);
    } catch (error) {
      toastError(error);
    }
  };

  const handleEditAppointment = async () => {
    try {
      await instance.put(`admin/entertainment-date-book/${editingId}`, {
        comment: values.comment,
        status_id: values.status.id,
      });
      resetForm();
      setEditingId(null);
      client.invalidateQueries(['slot', slotId]);
    } catch (error) {
      toastError(error);
    }
  };

  const handleBlockAppointment = async (id: number) => {
    try {
      await instance.post(`admin/entertainment-date-book-block/${id}`);
      client.invalidateQueries(['slot', slotId]);
    } catch (error) {
      toastError(error);
    }
  };

  const { data: bookStatuses } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(
        'admin/entertainment-date-book-statuses'
      );
      return data;
    },
    queryKey: ['entertainment-date-book-statuses'],
  });

  const handleResetEditing = () => {
    resetForm();
    setIsAdding(false);
    setEditingId(null);
  };

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead field="user_id">{t('employee')}</TableCellHead>
        <TableCellHead field="comment">{t('comment')}</TableCellHead>
        <TableCellHead field="status_id">{t('status')}</TableCellHead>
        <TableCellHead field="actions" sortable={false}>
          {t('actions')}
        </TableCellHead>
      </TableHead>
      <TableBody>
        {isAdding && (
          <TableRow style={styles.editRow}>
            <TableCell className={styles.statusCell}>
              <CustomQuerySelect
                placeholder={t('validate_employee')}
                value={values.user}
                query="admin/users-new-format"
                queryKey={['users-new-format', params]}
                params={params}
                onChange={value => setFieldValue('user', value)}
                isSearchEnabled
                error={errors.user as string}
                className={styles.userInput}
              />
            </TableCell>
            <TableCell className={styles.tableCell}>
              <Textarea
                className={styles.textArea}
                arreaClassName={styles.textAreaHeight}
                star
                name=""
                rows={1}
                maxLength={86}
                placeholder=""
                label=""
                showCounter
                value={values.comment}
                onChange={event => setFieldValue('comment', event.target.value)}
              />
            </TableCell>
            <TableCell className={styles.statusCell}>
              <SlotsUserStatusBadge
                status={bookStatuses?.find(
                  (item: { type: string }) => item.type === 'active'
                )}
              />
            </TableCell>
            <TableCell>
              <div className={styles.slotsUsersTableActions}>
                <IconButton
                  onClick={handleSubmit}
                  className={styles.slotsUsersTableBtnLink}
                >
                  <Checkmark />
                </IconButton>
                <IconButton onClick={handleResetEditing}>
                  <Basket />
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        )}
        {appointments?.data.map(item => {
          if (editingId === item.id) {
            return (
              <TableRow key={item.id} style={styles.editRow}>
                <TableCell className={styles.statusCell}>
                  <span className={styles.name}>{item.user.full_name}</span>{' '}
                  <span>{item.user.code}</span>
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <Textarea
                    className={styles.textArea}
                    star
                    name=""
                    rows={1}
                    maxLength={86}
                    placeholder=""
                    label=""
                    showCounter
                    value={values.comment}
                    onChange={event =>
                      setFieldValue('comment', event.target.value)
                    }
                  />
                </TableCell>
                <TableCell className={styles.statusCell}>
                  <SlotsUserStatusBadge status={values.status} />
                </TableCell>
                <TableCell>
                  <div className={styles.slotsUsersTableActions}>
                    <IconButton
                      onClick={handleSubmit}
                      className={styles.slotsUsersTableBtnLink}
                    >
                      <Checkmark />
                    </IconButton>
                    <IconButton onClick={handleResetEditing}>
                      <Basket />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            );
          }
          return (
            <TableRow key={item?.id}>
              <TableCell>
                <span className={styles.name}>{item.user.full_name}</span>{' '}
                <span>{item.user.code}</span>
              </TableCell>
              <TableCell className={styles.tableCell}>{item.comment}</TableCell>
              <TableCell className={styles.statusCell}>
                <SlotsUserStatusBadge status={item.status} />
              </TableCell>
              <TableCell className={styles.tableWrapper}>
                <div className={styles.slotsUsersTableActions}>
                  <IconButton
                    onClick={() => {
                      setEditingId(item.id);
                      setValues({
                        user: item.user,
                        status: item.status,
                        comment: item.comment,
                      });
                    }}
                    className={styles.slotsUsersTableBtnLink}
                  >
                    <Edit />
                  </IconButton>
                  {item.status.id === 1 && (
                    <ModalConfirm
                      title={t('recording_cancelled_warning')}
                      onConfirm={() => {
                        handleBlockAppointment(item.id);
                      }}
                    >
                      {open => (
                        <IconButton
                          // @ts-ignore
                          onClick={() => {
                            open();
                          }}
                        >
                          <Block />
                        </IconButton>
                      )}
                    </ModalConfirm>
                  )}
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default SlotUsersTable;
