import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import '~/assets/css/toastify.css';
import { instance } from './api/api';
import { useToastError } from './useToastError';
import AlertIcon from '../assets/svg/newSvg/alert-info.svg?react';
import SuccessIcon from '../assets/svg/newSvg/success-alert.svg?react';
import WarningIcon from '../assets/svg/newSvg/alert-octagon.svg?react';
import i18next from 'i18next';
import { downloadFile } from './downloadFile';

export function useToastFoodOrdersUpload() {
  const [isFinished, setIsFinished] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isError, setIsError] = useState(false);
  const toastError = useToastError();
  const t = i18next.t;

  const updateProgress = async () => {
    try {
      const { data } = await instance.get('/admin/food-orders-export-status');
      if (data.status === 'active') {
        toast.update('download-orders-toast', {
          render: () => (
            <div
              style={{
                width: '100%',
                textAlign: 'left',
                fontFamily: 'SF Pro Text',
                fontWeight: 500,
                letterSpacing: '-0.5px',
              }}
            >
              {t('food_export_status_active')}
            </div>
          ),
          autoClose: false,
          type: 'warning',
          style: {
            backgroundColor: '#f4e4d6',
            color: '#F0A83B',
            fontWeight: 500,
            fontFamily: 'SF Pro Text',
          },
          toastId: 'download-orders-toast',
          icon: <WarningIcon />,
        });
      }

      if (data.status === 'ready') {
        toast.update('download-orders-toast', {
          render: () => (
            <div
              style={{
                width: '100%',
                textAlign: 'left',
                fontWeight: 500,
                fontFamily: 'SF Pro Text',
                whiteSpace: 'nowrap',
                letterSpacing: '-0.5px',
              }}
            >
              {t('food_export_status_ready')}
            </div>
          ),
          autoClose: 4000,
          type: 'success',
          style: {
            backgroundColor: '#d1ebdd',
            color: '#1CBE63',
            fontWeight: 500,
            fontFamily: 'SF Pro Text',
            width: 'fit-content',
          },
          icon: <SuccessIcon />,
          toastId: 'download-orders-toast',
        });
        const { data: file } = await instance.get(
          'admin/food-orders-export-file',
          { responseType: 'blob' }
        );
        downloadFile(file, 'xlsx', 'food-order.xlsx');
        localStorage.removeItem('isFoodOrdersLoading');
        return 'finished';
      }
      if (data.status === 'success') {
        localStorage.removeItem('isFoodOrdersLoading');
        toast.dismiss();
        return 'finished';
      }
      return data;
    } catch (error) {
      toast.dismiss();
      setIsError(true);
      toastError(t('food_export_status_error'));
    }
  };

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    if (isActive) {
      toast.info(
        <div className="toastContent">
          <span className="toastContentTitle">
            {t('food_export_status_prepared')}
          </span>
        </div>,
        {
          style: {
            whiteSpace: 'nowrap',
            fontFamily: 'SF Pro Text',
          },
          progress: 0,
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          autoClose: false,
          isLoading: false,
          toastId: 'download-orders-toast',
          progressStyle: { backgroundColor: '#28a745' },
          icon: <AlertIcon />,
        }
      );

      interval = setInterval(async () => {
        const data = await updateProgress();

        if (data === 'finished' || isError) {
          clearInterval(interval);
          setIsFinished(true);
          setIsActive(false);
        }
      }, 4000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isActive]);

  const startToast = () => {
    localStorage.setItem('isFoodOrdersLoading', JSON.stringify(true));
    setIsActive(true);
  };

  return { startToast, isActive, isFinished };
}
