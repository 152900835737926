import { IUpdateLevelData } from '~/utils/types/promotion';
import LevelForm from '../LevelForm/LevelForm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useTranslation } from 'react-i18next';

const CreateLevel = () => {
  const { t } = useTranslation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();

  const { mutateAsync: updateLevel } = useMutation(
    async ({
      name,
      points,
      color_id,
      asset_id,
      discount_percent,
    }: IUpdateLevelData) => {
      const response = await instance.post('admin/loyalty-level', {
        name,
        points,
        color_id,
        asset_id,
        discount_percent,
      });
      return response;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['promotion-levels']);
        toastSuccess(t('level_successfull_created'));
        navigate('/promotions/levels');
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <div>
      <LevelForm updateLevel={updateLevel} />
    </div>
  );
};

export default CreateLevel;
