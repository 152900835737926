import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import dayjs from 'dayjs';

import styles from './UserPromotionFormTable.module.scss';

interface IPromotionTableProps {
  data: { points: number; created_at: string }[];
  sort: { field: string; direction: string };
  setSort: ({ field, direction }: { field: string; direction: string }) => void;
}

export const UserPromotionFormTable = ({
  data,
  sort,
  setSort,
}: IPromotionTableProps) => {
  const { t } = useTranslation();

  const PointQuantity = ({ point }: { point: number }) => {
    const isPositive = point > 0;

    return (
      <div className={isPositive ? styles.positive : styles.negative}>
        {isPositive ? `+${point} ${t('points')}` : `${point} ${t('points')}`}
      </div>
    );
  };

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead field="created_at">{t('date')}</TableCellHead>
        <TableCellHead field="points">{t('write_off_accrual')}</TableCellHead>
      </TableHead>
      <TableBody>
        {data.map((item, idx) => (
          <TableRow key={idx}>
            <TableCell>{dayjs(item.created_at).format('DD-MM-YYYY')}</TableCell>
            <TableCell>
              <PointQuantity point={item.points} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
