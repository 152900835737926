import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { instance } from '~/utils/api/api';
import PromotionForm from '~/components/PromotionForm/PromotionForm';
import { useTranslation } from 'react-i18next';
import { useToastError } from '~/utils/useToastError';
import { Spinner } from '~/ui';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { ICreatePromotionProps } from '../CreatePromotion/CreatePromotion';

interface IRulesProps {
  id: number;
  value: number;
  promo_loyalty_id: number;
}

const EditPromotionPage = () => {
  const { stockId } = useParams();
  const navigate = useNavigate();
  const client = useQueryClient();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { t } = useTranslation();

  const { mutateAsync: updatePromotionMethod } = useMutation(
    async ({
      name,
      description,
      start_date,
      end_date,
      burn_count_days,
      calculated_points,
      piwigo_image_id,
      accommodation_ids,
      organization_ids,
    }: ICreatePromotionProps) => {
      const response = await instance.put(`admin/promo-loyalty/${stockId}`, {
        name,
        description,
        burn_count_days,
        start_date,
        end_date,
        calculated_points,
        piwigo_image_id,
        accommodation_ids,
        organization_ids,
      });

      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['promotion']);
        toastSuccess(t('stock_successfull_updated'));
        navigate(`/promotions`);
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { data, isLoading, refetch } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(`admin/promo-loyalty/${stockId}`);
      return data;
    },
    queryKey: ['promotion', stockId],
    onError: error => {
      toastError(error);
    },
  });

  const { mutateAsync: createRule } = useMutation(
    async ({ rule_id, value }: { rule_id: number; value: number }) => {
      const response = await instance.post(`admin/promo-loyalty-rules`, {
        rule_id,
        promo_loyalty_id: stockId,
        value,
      });

      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['promo-rules', stockId]);
        toastSuccess(t('condition_successfull_created'));
        refetch();
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { mutateAsync: updateRule } = useMutation(
    async ({ id, value }: IRulesProps) => {
      const response = await instance.put(`admin/promo-loyalty-rules/${id}`, {
        value,
        promo_loyalty_id: stockId,
      });

      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['promo-rules', stockId]);
        toastSuccess(t('condition_successfull_updated'));
        refetch();
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { mutateAsync: deleteRule } = useMutation(
    async (rule_id: number) => {
      const response = await instance.delete(`admin/promo-loyalty-rules`, {
        params: { rule_id, promo_loyalty_id: stockId },
      });

      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['promo-rules', stockId]);
        toastSuccess(t('condition_successfull_deleted'));
        refetch();
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page heading={<Heading text={t('edit_promotion')} />}>
      {!isLoading ? (
        <PromotionForm
          isEdit
          data={data}
          onSubmit={updatePromotionMethod}
          deleteRule={(rule_id: number) => deleteRule(rule_id)}
          updateRule={(id: number, value: number, promo_loyalty_id: number) =>
            updateRule({ id, value, promo_loyalty_id })
          }
          createRule={(rule_id: number, value: number) =>
            createRule({ rule_id, value })
          }
        />
      ) : (
        <Spinner />
      )}
    </Page>
  );
};

export default EditPromotionPage;
