import i18next from 'i18next'
import { numberValidate } from './regex'

export const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = i18next.t('fill_field')
    }

    if (!values.assets.name) {
        errors.assets = { name: i18next.t('validate_asset') }
    }
    
    if (!values.organizations.length) {
        errors.organizations = i18next.t('validate_organizations')
    }

    if (!values.icon) {
        errors.icon = i18next.t('choose_icon')
    }
    return errors
}