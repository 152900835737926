import i18next from 'i18next'
import { Dayjs } from 'dayjs'

export const validateSlot = values => {
  const errors = {}

  // Start Time validation
  if (!values.startTime) {
    errors.startTime = i18next.t('enter_start_time')
  }

  // End Time validation
  if (!values.endTime) {
    errors.endTime = i18next.t('enter_end_time')
  }

  // Slots Count validation
  if (!values.slotsCount) {
    errors.slotsCount = i18next.t('enter_available_seats')
  } else if (!/^\d+$/.test(values.slotsCount)) {
    errors.slotsCount = i18next.t('must_be_number')
  } else if (parseInt(values.slotsCount) <= 0) {
    errors.slotsCount = i18next.t('must_be_greater_than_zero')
  }

  // Status validation
  if (!values.status || values.status.id === null) {
    errors.status = i18next.t('select_status_validate')
  }

  return errors
}
