import Page from '~/components/Shared/Layout/Page/Page';
import { useTranslation } from 'react-i18next';
import Heading from '~/components/Shared/Heading/Heading';
import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import { Link } from 'react-router-dom';
import styles from './KnowledgeBasePage.module.scss';
import CategoryList from '~/components/CategoryList/CategoryList';
import { useToastError } from '~/utils/useToastError';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { Spinner } from '~/ui';

const KnowledgeBasePage = () => {
  const { t } = useTranslation();
  const toastError = useToastError();

  const { data: knowledge, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/knowledge-base-categories');
      return data.data;
    },
    queryKey: ['knowledge-base'],
    onError: error => {
      toastError(error);
    },
  });

  return (
    <Page
      heading={
        <Heading
          text={t('knowledge_base')}
          actions={
            <Link to="/knowledge-base/create" className={styles.createBtn}>
              <AddIcon />
              {t('create_category')}
            </Link>
          }
        />
      }
    >
      {isLoading || !knowledge ? (
        <Spinner />
      ) : knowledge.length ? (
        <CategoryList data={knowledge} />
      ) : (
        <span className={styles.emptyPlaceholder}>
          {t('knowledge_base_categories_empty')}
        </span>
      )}
    </Page>
  );
};

export default KnowledgeBasePage;
