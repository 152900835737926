import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import { Board } from '~/components/Shared/Layout/Board/Board';
import SlotUsersTable from './SlotUsersTable/SlotUsersTable';
import { TableSelect } from '~/ui/TableSelect/TableSelect';
import { useTranslation } from 'react-i18next';
import { Action } from '~/ui/Actions';
import { useState } from 'react';
import { Pagination } from '~/ui';

import styles from './SlotUsersBoard.module.scss';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import Tooltip from '~/components/Shared/Tooltip/Tooltip';

interface SelectedPage {
  selected: number;
}

export interface ISlot {
  id: number;
  startTime: string;
  endTime: string;
  name: string;
  address: string;
  status: { id: number; name: string; type: string };
}

export interface IAppointment {
  id: number;
  user: {
    id: number;
    name: string;
    full_name: string;
    uniqueId: string;
    code: string;
  };
  comment: string;
  status: { id: number; type: string; name: string };
  name: string;
}

interface ISlotUsersBoardProps {
  appointments: {
    data: {
      from: number;
      to: number;
      last_page: number;
      total: number;
      data: IAppointment[];
    };
    date_slot_object: {
      status_id: number;
    };
    max_users_count: string;
    users_count: number;
  };
  sort: any;
  setSort: ({ field, direction }: any) => void;
  setPage: (page: number) => void;
  perPage: number;
  setPerPage: (perPage: number) => void;
}

const SlotUsersBoard = ({
  appointments,
  sort,
  setSort,
  setPage,
  perPage,
  setPerPage,
}: ISlotUsersBoardProps) => {
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState(false);

  const handleSetIsAdding = () => {
    setIsAdding(true);
  };

  const status = appointments?.date_slot_object?.status_id;
  const isMaxCount =
    appointments?.users_count >= Number(appointments?.max_users_count);

  return (
    <Board>
      <div className={styles.slotsTableOptions}>
        <div className={styles.slotsHeaderLeft}>
          <TableSelect perPage={perPage} setPerPage={setPerPage} />
        </div>
        <div className={styles.tableHeadRightWrapper}>
          {isMaxCount ? (
            <Tooltip
              tooltipStyle={styles.transactionTooltip}
              title={t('not_available_slot_tooltip')}
              position="top"
            >
              <Action
                variant="success"
                onClick={handleSetIsAdding}
                disabled={status !== 1 || isMaxCount}
              >
                <AddIcon /> {t('add_appointment')}
              </Action>
            </Tooltip>
          ) : (
            <Action
              variant="success"
              onClick={handleSetIsAdding}
              disabled={status !== 1 || isMaxCount}
            >
              <AddIcon /> {t('add_appointment')}
            </Action>
          )}
        </div>
      </div>
      <SlotUsersTable
        appointments={appointments?.data}
        sort={sort}
        setSort={setSort}
        isAdding={isAdding}
        setIsAdding={setIsAdding}
      />
      <div className={styles.tablePagination}>
        <TableAmountPage
          firstRow={appointments?.data.from}
          lastRow={appointments?.data.to}
          total={appointments?.data.total}
        />
        <Pagination
          pageCount={appointments?.data.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
    </Board>
  );
};

export default SlotUsersBoard;
