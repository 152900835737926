import { Action } from '~/ui/Actions';
import { Board } from '../Shared/Layout/Board/Board';
import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import RegisterTable, { IAddedRegister } from './RegisterTable/RegisterTable';
import { useTranslation } from 'react-i18next';
import styles from './RegisterBoard.module.scss';
import { useState } from 'react';

interface IRegisterBoardProps {
  registers: IAddedRegister[];
  handleAddSlot: (slot: IAddedRegister) => void;
  handleDeleteSlot: (id: string) => void;
  handleEditSlot: (slot: IAddedRegister) => void;
}

const RegisterBoard = ({
  handleAddSlot,
  handleDeleteSlot,
  handleEditSlot,
  registers,
}: IRegisterBoardProps) => {
  const { t } = useTranslation();
  const [sort, setSort] = useState({ field: '', direction: '' });

  const [isAddingSlot, setIsAddingSlot] = useState(false);

  const toggleAddingSlot = () => {
    setIsAddingSlot(prevState => !prevState);
  };

  return (
    <Board classNames={styles.board}>
      <div className={styles.slotBoardHeader}>
        <Action
          variant="success"
          className={styles.addBtn}
          onClick={toggleAddingSlot}
        >
          <AddIcon /> {t('add_cash_register')}
        </Action>
      </div>
      <RegisterTable
        registers={registers}
        sort={sort}
        isAdding={isAddingSlot}
        setSort={setSort}
        handleAddSlot={handleAddSlot}
        handleDeleteSlot={handleDeleteSlot}
        handleEditSlot={handleEditSlot}
        onDeleteAddingSlotClick={toggleAddingSlot}
      />
    </Board>
  );
};

export default RegisterBoard;
