import { useFormik } from 'formik';
import { Button, InputBar } from '~/ui';
import { CLOSE_BUTTON_STYLE, Modal } from '~/components/Shared/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { InputBarColor } from '~/ui/InputBarColor/InputBarColor';
import ColorSelect from '../Shared/ColorSelect/ColorSelect';
import PhoneIcon from '~/assets/svg/newSvg/phone.svg?react';
import { instance } from '~/utils/api/api';
import { IColorScheme } from '~/pages/CreateAsset/CreateAsset';
import { AuthenticationContext } from '../Authentication/AuthenticationProvider';
import { useToastError } from '~/utils/useToastError';
import PreviewCardPhone from '../Shared/PreviewCardPhone/PreviewCardPhone';
import { IUpdateLevelData } from '~/utils/types/promotion';
import { validate } from '~/utils/validatePromotionLevel';

import styles from './LevelForm.module.scss';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import CustomSelect from '../Shared/CustomSelect/CustomSelect';
import { useQuery } from '@tanstack/react-query';

interface ILevelData {
  id: number;
  name: string;
  points: number;
  discount_percent: number;
  color: { id: number; name: string; color: string };
  asset_id: number;
}

interface ILevelForm {
  updateLevel: (values: IUpdateLevelData) => void;
  isEdit?: boolean;
  leveldata?: ILevelData | undefined;
}

const LevelForm = ({ isEdit, leveldata, updateLevel }: ILevelForm) => {
  const { user } = useContext(AuthenticationContext);
  const [colors, setColors] = useState<IColorScheme[]>([]);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const isSuperAdmin = checkIfUserSuperAdmin(user?.data.role_name);
  const { t } = useTranslation();
  const toastError = useToastError();

  const loadColors = async () => {
    try {
      const { data } = await instance.get('admin/loyalty-level-color');
      setColors(data);
    } catch (error) {
      toastError(error);
    }
  };

  const { data: assetsArray, isLoading: isAssetsLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/assets');
      return data.data;
    },
    queryKey: ['assets'],
    onError: error => {
      toastError(error);
    },
  });

  const {
    values,
    errors,
    handleBlur,
    touched,
    setFieldTouched,
    setValues,
    setFieldValue,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: {
      asset: {
        id: 0,
        name: '',
      },
      level_name: '',
      points: 0,
      discount: 0,
      color: { id: 3, name: '', color: '' },
    },
    validate,
    onSubmit: () => {
      updateLevel({
        name: values.level_name,
        points: values.points,
        discount_percent: values.discount,
        color_id: values.color.id,
        asset_id: values.asset.id,
      });
    },
  });

  const handleOpenPreview = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  const handleChangeColor = (id: number) => {
    setFieldValue('color.id', id);
  };

  const selectedColorObject = colors.find(
    colorScheme => colorScheme.id === values.color.id
  );

  useEffect(() => {
    loadColors();

    if (!isSuperAdmin) {
      values.asset.id = user?.data.asset_id || 0;
    }

    if (!isEdit && !assetsArray && isAssetsLoading) return;

    if (leveldata) {
      const currentAsset = assetsArray?.find(
        (item: { id: number }) => item.id === leveldata.asset_id
      );

      setValues({
        asset: {
          id: leveldata.asset_id,
          name: currentAsset?.name || '',
        },
        level_name: leveldata.name,
        points: leveldata.points,
        discount: leveldata.discount_percent,
        color: leveldata.color,
      });
    }
  }, [isEdit, assetsArray, leveldata, isAssetsLoading]);

  return (
    <div className={styles.formWrapper}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.inputWrapper}>
          {isSuperAdmin && !isAssetsLoading && (
            <CustomSelect
              placeholder={t('to_choose')}
              onChange={value => {
                setFieldValue('asset', value);
              }}
              label={t('asset')}
              value={values.asset}
              className={styles.select}
              disabled={!isSuperAdmin || isAssetsLoading || isEdit}
              options={assetsArray}
              error={errors.asset ? errors.asset?.name : undefined}
              onTouch={() => setFieldTouched('asset', true)}
              required
            />
          )}

          <InputBar
            disabled={isEdit}
            placeholder={t('placeholder_level_name')}
            label={t('level_name')}
            name="level_name"
            value={values.level_name}
            star
            required
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.level_name}
            error={touched.level_name && Boolean(errors.level_name)}
          />
          <InputBar
            label={t('points_quantity')}
            name="points"
            placeholder={t('validate_points_quantity')}
            value={values.points === 0 ? '' : String(values.points)}
            star
            required
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.points}
            error={touched.points && Boolean(errors.points)}
          />
          <InputBar
            label={t('discount_level')}
            name="discount"
            placeholder={t('placeholder_discount')}
            value={values.discount === 0 ? '' : String(values.discount)}
            star
            required
            onChange={handleChange}
            errors={errors.discount}
            onBlur={handleBlur}
            error={touched.discount && Boolean(errors.discount)}
          />
        </div>

        <InputBarColor label={t('color_palette')} star>
          <div className={styles.colorWrapper}>
            <ColorSelect
              isGradient
              name="color"
              options={colors}
              value={values.color.id}
              onChange={handleChangeColor}
              checkColor={selectedColorObject?.text_color}
            />
            <button
              type="button"
              className={styles.previewButton}
              onClick={handleOpenPreview}
            >
              <PhoneIcon />
              {t('preview')}
            </button>
          </div>
        </InputBarColor>
        <Button
          type="submit"
          text={t('save_n_finish')}
          disabled={
            !values.level_name ||
            !values.points ||
            !values.discount ||
            values.asset.id === 0
          }
          className={styles.submitBrn}
        />
      </form>

      <Modal
        modalStyles={styles.previewModal}
        isCloseButtonShown
        isOpen={isPreviewOpen}
        onClose={handleClosePreview}
        closeButtonStyle={CLOSE_BUTTON_STYLE.SIMPLE}
      >
        <PreviewCardPhone
          level={values.level_name}
          discount={values.discount}
          balance={0}
          points={values.points}
          cardId={0}
          username={user?.data.full_name}
          company={user?.data.company_name}
          color={selectedColorObject?.color}
          textColor={selectedColorObject?.text_color}
        >
          <div className={styles.colorPreviewContainer}>
            <ColorSelect
              isGradient
              name="color"
              options={colors}
              value={values.color.id}
              onChange={handleChangeColor}
              checkColor={selectedColorObject?.text_color}
            />
          </div>
        </PreviewCardPhone>
      </Modal>
    </div>
  );
};

export default LevelForm;
