import React, { useContext, useState } from 'react';
import { Button, InputBar } from '~/ui';
import styles from './CreateAssetForm.module.scss';
import ColorSelect from '~/components/Shared/ColorSelect/ColorSelect';
import { IColorScheme } from '../CreateAsset';
import { InputBarColor } from '~/ui/InputBarColor/InputBarColor';
import UploadPhoto from '~/components/Shared/UploadPhoto/UploadPhoto';
import { FormikValues } from 'formik';
import PhoneIcon from '~/assets/svg/newSvg/phone.svg?react';
import PreviewPhone from '~/components/Shared/PreviewPhone/PreviewPhone';
import { CLOSE_BUTTON_STYLE, Modal } from '~/components/Shared/Modal/Modal';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import CloseIcon from '~/assets/svg/newSvg/delete.svg?react';
import { useTranslation } from 'react-i18next';
import RegisterBoard from '~/components/RegisterBoard/RegisterBoard';
import { IAddedRegister } from '~/components/RegisterBoard/RegisterTable/RegisterTable';

interface ICreateAssetFormProps {
  colorSchemes: IColorScheme[];
  formik: FormikValues;
}

const MAX_IMAGE_SIZE_MB = 2;

const CreateAssetForm: React.FC<ICreateAssetFormProps> = ({
  formik,
  colorSchemes,
}) => {
  const { user } = useContext(AuthenticationContext);
  const [photoLink, setPhotoLink] = useState<string | undefined>();
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleOpenPreview = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  const selectedColorObject = colorSchemes.find(
    colorScheme => colorScheme.id === formik.values.color_id
  );

  const handleChangePhoto = (value: FileList | null) => {
    if (value === null) {
      return;
    }
    if (value[0].size / 1024 / 1024 > MAX_IMAGE_SIZE_MB) {
      formik.setErrors({ logo: t('exceeds_2_mb') });
      return;
    }
    setPhotoLink(URL.createObjectURL(value[0]));
    formik.setFieldValue('logo', value[0]);
  };

  const handleChangeColor = (id: number) => {
    formik.setFieldValue('color_id', id);
  };

  const removeLogo = () => {
    formik.setFieldValue('logo', null);
    setPhotoLink(undefined);
  };

  const handleAddSlot = (slot: IAddedRegister) => {
    formik.setFieldValue('registers', [...formik.values.registers, slot]);
  };

  const handleDeleteSlot = (id: string) => {
    formik.setFieldValue(
      'registers',
      formik.values.registers.filter((item: { id: string }) => item.id !== id)
    );
  };

  const handleEditSlot = (slot: IAddedRegister) => {
    formik.setFieldValue(
      'registers',
      formik.values.registers.map((item: { id: string }) => {
        if (slot.id === item.id) {
          return slot;
        }
        return item;
      })
    );
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.formItems}>
        <div className={styles.inputsLine}>
          <InputBar
            name="name"
            value={formik.values.name}
            disabled={false}
            label={t('name')}
            star
            placeholder={t('asset_name_placeholder')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
          />
          <InputBar
            name="description"
            value={formik.values.description}
            disabled={false}
            label={t('description')}
            placeholder={t('asset_description_placeholder')}
            star
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.description}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
          />
        </div>
        <div className={styles.inputsLine}>
          <InputBar
            name="inn"
            value={formik.values.inn}
            disabled={false}
            label={t('tin')}
            placeholder={t('asset_inn_placeholder')}
            star
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.inn}
            error={formik.touched.inn && Boolean(formik.errors.inn)}
          />
          <InputBar
            name="address"
            value={formik.values.address}
            disabled={false}
            label={t('address')}
            placeholder={t('asset_address_placeholder')}
            star
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.address}
            error={formik.touched.address && Boolean(formik.errors.address)}
          />
          <InputBar
            name="prefix"
            value={formik.values.prefix}
            disabled={false}
            label={t('prefix')}
            placeholder={t('prefix')}
            star
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.prefix}
            error={formik.touched.prefix && Boolean(formik.errors.prefix)}
          />
        </div>
        <div className={styles.inputsLine}></div>

        <RegisterBoard
          registers={formik.values.registers}
          handleAddSlot={handleAddSlot}
          handleDeleteSlot={handleDeleteSlot}
          handleEditSlot={handleEditSlot}
        />

        <InputBarColor label={t('color_palette')} star>
          <div className={styles.colorWrapper}>
            <ColorSelect
              name="color"
              options={colorSchemes}
              value={formik.values.color_id}
              onChange={handleChangeColor}
              checkColor={selectedColorObject?.text_color}
            />
            <button
              type="button"
              className={styles.previewButton}
              onClick={handleOpenPreview}
            >
              <PhoneIcon />
              {t('preview')}
            </button>
          </div>
        </InputBarColor>
        <div className={styles.uploadWrapper}>
          <span className={styles.uploadLabel}>{t('logo')}</span>
          {photoLink && (
            <div className={styles.imageLogoWrapper}>
              <img
                className={styles.imageLogo}
                src={photoLink}
                alt="Uploaded logo"
              />
              <button
                type="button"
                onClick={removeLogo}
                className={styles.removeImageButton}
              >
                <CloseIcon />
              </button>
            </div>
          )}
          {!photoLink && (
            <UploadPhoto
              onChange={handleChangePhoto}
              error={formik.errors.logo}
            />
          )}
          <span className={styles.imageUploadError}>{formik.errors.logo}</span>
        </div>
      </div>
      <Button
        type="submit"
        text={t('save')}
        className={styles.submitButton}
        disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
      />
      <Modal
        modalStyles={styles.previewModal}
        isCloseButtonShown
        isOpen={isPreviewOpen}
        onClose={handleClosePreview}
        closeButtonStyle={CLOSE_BUTTON_STYLE.SIMPLE}
      >
        <PreviewPhone
          username={user?.data.full_name}
          color={selectedColorObject?.color}
          textColor={selectedColorObject?.text_color}
        >
          <div className={styles.colorPreviewContainer}>
            <ColorSelect
              name="color"
              options={colorSchemes}
              value={formik.values.color_id}
              onChange={handleChangeColor}
              checkColor={selectedColorObject?.text_color}
            />
          </div>
        </PreviewPhone>
      </Modal>
    </form>
  );
};

export default CreateAssetForm;
