import DeleteIcon from '~/assets/svg/newSvg/basket.svg?react';
import { IconButton } from '~/ui';

import styles from './Article.module.scss';
import ModalConfirm from '~/components/Shared/ModalConfirm';
import { useTranslation } from 'react-i18next';
import { IArticle } from '~/utils/types/knowledgeTypes';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useToastSuccess } from '~/utils/useToastSuccess';
import dayjs from 'dayjs';
import classNames from 'classnames';

interface IArticleProps {
  data: IArticle;
  mobile?: boolean;
}

const Article = ({ data, mobile = false }: IArticleProps) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { t } = useTranslation();
  const { id, title, content, main_photo_url, created_at } = data;
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();

  const date = dayjs(created_at).locale('ru').format('D MMMM YYYY');

  const handleDelete = () => {
    deleteArticle(id);
  };

  const client = useQueryClient();

  const { mutateAsync: deleteArticle } = useMutation(
    async (id: number) => {
      const response = await instance.delete(
        `admin/knowledge-base-articles/${id}`
      );

      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['knowledge-articles']);
        toastSuccess(t('article_successfull_removed'));
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <li className={styles.article}>
      <Link
        to={
          mobile
            ? `/articles/${id}?token=${token}`
            : `/knowledge-base/${params.id}/${id}`
        }
        className={styles.link}
      >
        <img
          className={styles.image}
          src={main_photo_url[0]?.full_photo_url}
          alt=""
        />
        <h6 className={styles.categoryTitle}>{title}</h6>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className={styles.categoryDescription}
        />
        {mobile && (
          <span className={classNames(styles.date, styles.mobileDate)}>
            {date}
          </span>
        )}
      </Link>
      {!mobile && (
        <div className={styles.controls}>
          <span className={styles.date}>{date}</span>
          <ModalConfirm
            title={t('delete_article_confirm')}
            onConfirm={handleDelete}
          >
            {open => (
              <IconButton onClick={open}>
                <DeleteIcon />
              </IconButton>
            )}
          </ModalConfirm>
        </div>
      )}
    </li>
  );
};

export default Article;
