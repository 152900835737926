import Dropdown from '~/components/Shared/Dropdown';
import Actions, { Action } from '~/ui/Actions';
import DownloadIcon from '~/assets/svg/newSvg/actions/download.svg?react';
import { useToastError } from '~/utils/useToastError';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import LoaderSpinner from '../Shared/LoaderSpinner/LoaderSpinner';
import styles from './FoodExportActions.module.scss';
import DeleteIcon from './images/delete-icon.svg?react';
import { Dayjs } from 'dayjs';
import { useToastFoodOrdersUpload } from '~/utils/useToastDownloadOrdersFood';
import { downloadReportFoodFile } from './downloadReportFoodFile';
import { useToastFoodWaresUpload } from '~/utils/useToastDownloadOrderWaresFood';

interface XlsFoodExportActions {
  dates?: [Dayjs | null, Dayjs | null];
  foodID?: number;
  onOpenDishesClick?: () => void;
}

const FoodExportActions = ({
  dates,
  foodID,
  onOpenDishesClick,
}: XlsFoodExportActions) => {
  const toastError = useToastError();

  const startDate = dates ? dates[0] : null;
  const endDate = dates ? dates[1] : null;
  const query = foodID ? `&food_id=${foodID}` : '';
  const nameSuffix = foodID ? `-${foodID}` : '';
  const ordersPath = 'food-orders-export?sort' + query;
  const waresPath = 'food-wares-export?sort' + query;
  const ordersName = `food-orders` + nameSuffix;
  const waresName = `food-wares` + nameSuffix;
  const [isLoading, setIsLoading] = useState<{
    isOrdersLoading: boolean;
    isDishesLoading: boolean;
  }>({
    isOrdersLoading: false,
    isDishesLoading: false,
  });
  const { t } = useTranslation();

  const { startToast, isActive } = useToastFoodOrdersUpload();
  const { startWaresToast, isWaresActive } = useToastFoodWaresUpload();

  const downloadFileReport = async (reportPath: string, reportName: string) => {
    if (reportName.includes('orders')) {
      setIsLoading({ ...isLoading, isOrdersLoading: true });
    } else {
      setIsLoading({ ...isLoading, isDishesLoading: true });
    }
    try {
      if (reportName.includes('wares')) {
        startWaresToast();
      } else {
        startToast();
      }
      await downloadReportFoodFile(reportPath, startDate, endDate);
      close();
    } catch (e) {
      toastError(e);
    } finally {
      if (reportName.includes('orders')) {
        setIsLoading({ ...isLoading, isOrdersLoading: false });
      } else {
        setIsLoading({ ...isLoading, isDishesLoading: false });
      }
    }
  };

  return (
    <>
      <Dropdown caption={t('actions')}>
        {closeDropdown => (
          <Actions>
            <Action
              onClick={() => {
                downloadFileReport(ordersPath, ordersName);
              }}
            >
              <div className={styles.actionWrapper}>
                {(isLoading.isOrdersLoading || isActive) && (
                  <div className={styles.loaderWrapper}>
                    <LoaderSpinner />
                  </div>
                )}
                <div
                  style={
                    isLoading.isOrdersLoading || isActive ? { opacity: 0 } : {}
                  }
                  className={styles.text}
                >
                  <DownloadIcon />
                  {`${t('download')} ${t('order_report')}`}
                </div>
              </div>
            </Action>
            <Action
              onClick={() => {
                downloadFileReport(waresPath, waresName);
              }}
            >
              <div className={styles.actionWrapper}>
                {(isLoading.isDishesLoading || isWaresActive) && (
                  <div className={styles.loaderWrapper}>
                    <LoaderSpinner />
                  </div>
                )}
                <div
                  style={
                    isLoading.isDishesLoading || isWaresActive
                      ? { opacity: 0 }
                      : {}
                  }
                  className={styles.text}
                >
                  <DownloadIcon />
                  {`${t('download')} ${t('dish_report')}`}
                </div>
              </div>
            </Action>
            {onOpenDishesClick ? (
              <Action
                variant="danger"
                onClick={() => {
                  closeDropdown();
                  onOpenDishesClick();
                }}
              >
                <DeleteIcon />
                {t('cancel_dishes_title')}
              </Action>
            ) : (
              ''
            )}
          </Actions>
        )}
      </Dropdown>
    </>
  );
};

export default FoodExportActions;
