import { useState } from 'react';
import { Board } from '../Shared/Layout/Board/Board';
import { TableSelect } from '~/ui/TableSelect/TableSelect';
import { Button, Pagination } from '~/ui';
import { useTranslation } from 'react-i18next';
import styles from './SlotsBoard.module.scss';
import { useParams } from 'react-router-dom';
import SlotsTable from './SlotsTable/SlotsTable';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import Arrow from '~/assets/svg/newSvg/select-arrow.svg?react';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { useToastError } from '~/utils/useToastError';

interface SelectedPage {
  selected: number;
}

export interface ISlot {
  id: number;
  start_time: string;
  end_time: string;
  max_users_count: number;
  address: string;
  users_count: string;
  status: { id: number; name: string; type: string; is_active: boolean };
}

const SlotsBoard = () => {
  const [selectedSlots, setSelectedSlots] = useState<number[]>([]);
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [perPage, setPerPage] = useState(15);
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const [date, setDate] = useState<Dayjs | null>(dayjs());
  const { id } = useParams();
  const toastError = useToastError();

  const params = {
    sort: sort.direction,
    field: sort.field,
    perPage,
    page: page,
    date: date?.format('YYYY-MM-DD'),
    entertainment_object_id: id,
  };

  const { data: slots, refetch } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/entertainment-date-slots', {
        params,
      });
      return data;
    },
    queryKey: ['slots', params],
    keepPreviousData: true,
  });

  const handleSelect = (id: number) => {
    if (selectedSlots.includes(id)) {
      const filteredUsers = selectedSlots.filter(
        selectedId => selectedId !== id
      );
      setSelectedSlots(filteredUsers);
    } else {
      setSelectedSlots([...selectedSlots, id]);
    }
  };

  const handleToggleAll = () => {
    if (selectedSlots.length === slots?.date_slots.length) {
      setSelectedSlots([]);
    } else {
      const userIdArray = slots?.map((user: any) => user.id);
      setSelectedSlots(userIdArray);
    }
  };

  const handleBlockSlots = async () => {
    try {
      await instance.delete('admin/entertainment-date-slots', {
        data: {
          date_slot_ids: selectedSlots,
        },
      });
      setSelectedSlots([]);
      refetch();
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <Board>
      <div className={styles.slotsTableOptions}>
        <TableSelect perPage={perPage} setPerPage={setPerPage} />
        <div className={styles.tableHeadRightWrapper}>
          <div className={styles.deleteButtonContainer}>
            {!!selectedSlots.length && (
              <Button
                text={t('block_slots')}
                color="red"
                onClick={handleBlockSlots}
                className={styles.deleteButton}
              />
            )}
          </div>
          <div>
            <DatePicker
              value={date}
              format="DD.MM.YYYY"
              onChange={date => setDate(date)}
              className={styles.datePicker}
              suffixIcon={<Arrow />}
            />
          </div>
        </div>
      </div>
      <SlotsTable
        places={slots?.date_slots.data}
        refetch={refetch}
        sort={sort}
        setSort={setSort}
        selectedItems={selectedSlots}
        handleToggle={handleSelect}
        handleToggleAll={handleToggleAll}
      />
      <div className={styles.tablePagination}>
        <TableAmountPage
          firstRow={slots?.date_slots.from}
          lastRow={slots?.date_slots.to}
          total={slots?.date_slots.total}
        />
        <Pagination
          pageCount={slots?.date_slots.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
    </Board>
  );
};

export default SlotsBoard;
