import React from 'react';
import { IColorScheme } from '~/pages/CreateAsset/CreateAsset';
import CheckIcon from '~/assets/svg/newSvg/black-check.svg?react';

import styles from './ColorSelect.module.scss';
import cn from 'classnames';

interface IColorSelectProps {
  name: string;
  options: IColorScheme[];
  isGradient?: boolean;
  onChange: (id: number) => void;
  value: number;
  checkColor?: string;
}

const fakeOptions = Array.from(Array(7).keys());

const ColorSelect: React.FC<IColorSelectProps> = ({
  name,
  options,
  isGradient = false,
  onChange,
  value = 1,
  checkColor,
}) => {
  return (
    <div className={styles.colorSelect}>
      {!options.length ? (
        <div className={styles.colorSelectLoader}>
          {fakeOptions.map(option => (
            <label
              htmlFor={name}
              key={option}
              className={styles.radioLabelLoaderWrapper}
            >
              <div className={styles.labelBlockLoader} />
            </label>
          ))}
        </div>
      ) : (
        ''
      )}
      {options.map(option => {
        return (
          <label
            htmlFor={name}
            key={option.id}
            className={cn(
              styles.radioLabel,
              option.id === value && styles.radioLabelSelected
            )}
            style={
              option.id === value
                ? { border: `1px solid ${option.color}` }
                : { border: 'none' }
            }
          >
            <div
              className={styles.labelBlock}
              style={
                isGradient
                  ? {
                      background: `linear-gradient(to right, ${option.color})`,
                    }
                  : { backgroundColor: option.color }
              }
            >
              <input
                onChange={e => onChange(Number(e.target.value))}
                className={styles.radioInput}
                value={option.id}
                name={name}
                type="radio"
              />
              {option.id === value && (
                <CheckIcon fill={checkColor} className={styles.checkIcon} />
              )}
            </div>
          </label>
        );
      })}
    </div>
  );
};

export default React.memo(ColorSelect);
