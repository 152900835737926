import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButton, IconButtonLink } from '~/ui';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import styles from './UsersTable.module.scss';
import ModalConfirm from '~/components/Shared/ModalConfirm';
import { IUserBusinessTrip } from '~/components/UserForm';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useToastError } from '~/utils/useToastError';
import Tooltip from '~/components/Shared/Tooltip/Tooltip';
import InfoIcon from '~/assets/svg/newSvg/info-icon.svg?react';
import { IBlockCategory } from '../UsersBoard';

export interface User {
  id: number;
  name: string;
  patronymic: string;
  start_date: string;
  end_date: string;
  uniqueId: string;
  code: string | null;
  accommodation: { id: number; name: string };
  business_trips: IUserBusinessTrip[];
  active_business_trip: IUserBusinessTrip;
  company_name: string;
  block_list: {
    id: number;
    user_id: number;
    categories: IBlockCategory;
  }[];
}

interface UsersTableProps {
  users: User[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  selectedItems: number[];
  handleToggleAll: () => void;
  handleToggle: (id: number) => void;
}

export const UsersTable = ({
  users,
  sort,
  setSort,
  selectedItems,
  handleToggleAll,
  handleToggle,
}: UsersTableProps) => {
  const client = useQueryClient();
  const { t } = useTranslation();
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();

  const { mutate: deleteUser } = useMutation(
    async (id: string) => {
      return await instance.delete(`admin/users/${id}`);
    },
    {
      onSuccess: () => {
        toastSuccess(t('employee_deleted'));
        client.invalidateQueries(['users']);
        client.invalidateQueries(['users-statistics']);
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead
            className={styles.tableCellHead}
            sortable={false}
            field="checkbox"
          >
            <CustomCheckbox
              isChecked={
                selectedItems.length === users.length && !!users.length
              }
              onChange={handleToggleAll}
            />
          </TableCellHead>
          <TableCellHead field="name">
            {`${t('first_name')} ${t('patronymic')}`}
          </TableCellHead>
          <TableCellHead field="start_date">{t('start_date')}</TableCellHead>
          <TableCellHead field="end_date">{t('end_date')}</TableCellHead>
          <TableCellHead field="code">{t('code')}</TableCellHead>
          <TableCellHead field="company_name">{t('company')}</TableCellHead>
          <TableCellHead field="accommodation">
            {t('accommodation_facility')}
          </TableCellHead>
          <TableCellHead sortable={false} field="actions">
            {t('action')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {users.map(
            ({
              id,
              name,
              patronymic,
              code,
              business_trips,
              company_name,
              block_list,
              active_business_trip,
            }: User) => {
              const lasBusinessTrip = business_trips[business_trips.length - 1];
              const activeBusinessTrip = active_business_trip;
              return (
                <TableRow key={id}>
                  <TableCell>
                    <CustomCheckbox
                      isChecked={selectedItems.includes(id)}
                      onChange={() => handleToggle(id)}
                    />
                  </TableCell>
                  <TableCell className={styles.nameCell}>
                    {name} {patronymic}
                    {block_list.length ? (
                      <Tooltip
                        tooltipStyle={styles.tooltipStyle}
                        className={styles.transactionsTooltip}
                        position="top"
                        title={`${t('blocked_in')} ${block_list
                          .map(blockItem => blockItem.categories.display_name)
                          .join(', ')}`}
                      >
                        <InfoIcon
                          color="green"
                          className={styles.tooltipIcon}
                        />
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>
                    {dayjs(
                      activeBusinessTrip
                        ? activeBusinessTrip?.start_date
                        : lasBusinessTrip?.start_date
                    ).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>
                    {dayjs(
                      activeBusinessTrip
                        ? activeBusinessTrip?.end_date
                        : lasBusinessTrip?.end_date
                    ).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>{code}</TableCell>
                  <TableCell>{company_name || '-'}</TableCell>
                  <TableCell>
                    {activeBusinessTrip
                      ? activeBusinessTrip?.accommodation?.name
                      : lasBusinessTrip?.accommodation?.name}
                  </TableCell>
                  <TableCell>
                    <div className={styles.usersTableActions}>
                      <ModalConfirm
                        title={`${t('sure_to_delete_employee')} «${name}»?`}
                        onConfirm={() => deleteUser(id.toString())}
                      >
                        {open => (
                          <IconButton onClick={open}>
                            <Basket />
                          </IconButton>
                        )}
                      </ModalConfirm>
                      <IconButtonLink
                        to={`/user/${id}`}
                        className={styles.usersTableBtnLink}
                      >
                        <Edit />
                      </IconButtonLink>
                    </div>
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </div>
  );
};
