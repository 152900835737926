import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import AssetsBoard from './AssetsBoard/AssetsBoard';
import { instance } from '~/utils/api/api';
import { useTranslation } from 'react-i18next';
import { useToastError } from '~/utils/useToastError';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

export interface IAsset {
  id: number;
  name: string;
  description: string;
  address: string;
  image_path: null | string;
  inn: string;
  color_id: number;
}

const Assets = (): JSX.Element => {
  const { t } = useTranslation();
  const toastError = useToastError();
  const [page, setPage] = useState<number>();

  const params = {
    page,
    perPage: 15,
  };

  const { data: assets, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/assets', {
        params,
      });
      return data;
    },
    queryKey: ['assets', params],
    onError: error => {
      toastError(error);
    },
  });

  return (
    <Page heading={<Heading text={t('assets')} />}>
      <AssetsBoard
        assets={assets}
        setPage={setPage}
        isAssetsLoading={isLoading}
      />
    </Page>
  );
};

export default Assets;
