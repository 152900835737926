import { useParams } from 'react-router-dom';
import { InputBar, QuerySelect } from '~/ui';
import styles from './QuestionnarieStats.module.scss';
import { useEffect, useState } from 'react';
import BigBadge from '../Shared/BigBadge/BigBadge';
import AnquetteIcon from '~/assets/svg/questionnaries/anquette-stats-icon.svg?react';
import AnswersIcon from '~/assets/svg/questionnaries/answers-icon.svg?react';
import PercentageIcon from '~/assets/svg/questionnaries/percentage-icon.svg?react';
import { useToastError } from '~/utils/useToastError';
import { instance } from '~/utils/api/api';
import AnswerList from './AnswerList/AnswerList';
import { IOpenAnswer } from './AnswerList/OpenAnswerStats/OpenAnswerStats';
import { IEstimateAnswer } from './AnswerList/EstimateAnswerStats/EstimateAnswerStats';
import { ICloseAnswer } from './AnswerList/ClosedAnswerStats/ClosedAnswerStats';
import { getUsers } from '~/utils/api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

interface IQuestionnaireStats {
  sent: number;
  answered: number;
  answer_percent: number;
  answers: IOpenAnswer[] | IEstimateAnswer[] | ICloseAnswer[];
  questionnaire_name: string;
}

interface IQuestionnarieStatsProps {
  onUserChange: (value: number | null) => void;
  selectedUser: number | null;
}

const QuestionnarieStats = ({
  onUserChange,
  selectedUser,
}: IQuestionnarieStatsProps) => {
  const { questionnaireID } = useParams<{ questionnaireID: string }>();
  const [questionnaireStats, setQuestionnaireStats] =
    useState<IQuestionnaireStats | null>(null);
  const toastError = useToastError();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const loadQuestionnaireStats = async () => {
    try {
      const { data } = await instance.get(
        `admin/questionnaires-analytics?questionnaire_id=${questionnaireID}`
      );
      queryClient.setQueryData(['questionnaireStats', questionnaireID], data);
      setQuestionnaireStats(data);
    } catch (error) {
      toastError(error);
    }
  };

  const loadQuestionnaireUserStats = async () => {
    try {
      const { data } = await instance.get(
        `admin/questionnaires-user-analytics?questionnaire_id=${questionnaireID}&user_id=${selectedUser}`
      );
      setQuestionnaireStats(data);
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    loadQuestionnaireStats();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      loadQuestionnaireUserStats();
    } else {
      loadQuestionnaireStats();
    }
  }, [selectedUser]);

  return (
    <div className={styles.questionnarieStats}>
      <div className={styles.inputs__container}>
        <InputBar
          label={t('anquette_name')}
          value={questionnaireStats?.questionnaire_name || ''}
          disabled
        />
        <QuerySelect
          label={t('employee')}
          star
          topOption={{ id: 0, name: t('all_employees'), section: null }}
          onChange={value => onUserChange(value)}
          value={selectedUser}
          placeholder={t('all_employees')}
          queryFn={({ pageParam = 1, meta }) =>
            getUsers({
              sort: '',
              perPage: 50,
              field: '',
              query: meta?.searchQuery as any,
              page: pageParam,
              questionnaire_id: Number(questionnaireID),
            })
          }
          queryKey={[
            'users',
            {
              sort: '',
              perPage: 50,
              field: '',
              query: '',
              page: 1,
              questionnaire_id: questionnaireID,
            },
          ]}
        />
      </div>
      {questionnaireStats && (
        <>
          {!selectedUser && (
            <div className={styles.statsBadges}>
              <BigBadge
                title={questionnaireStats.sent}
                description={t('questionnaire_sent')}
                Icon={AnquetteIcon}
              />
              <BigBadge
                title={questionnaireStats.answered}
                description={t('questionnaire_answers')}
                Icon={AnswersIcon}
              />
              <BigBadge
                title={`${questionnaireStats.answer_percent?.toFixed(1) || 0}%`}
                description={t('questionnaire_percent')}
                Icon={PercentageIcon}
              />
            </div>
          )}
          <div className={styles.answerListContainer}>
            <AnswerList
              answers={questionnaireStats.answers}
              isSingleStat={!!selectedUser}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default QuestionnarieStats;
