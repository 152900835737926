import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import Checkmark from '~/assets/svg/newSvg/mark-icon.svg?react';
import { IconButton, InputBar } from '~/ui';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import { useState } from 'react';
import classNames from 'classnames';
import styles from './RegisterTable.module.scss';
import { validate } from '~/utils/validateRegister';

export interface IAddedRegister {
  id: string;
  avers_box_office_id: string;
  name: string;
}

interface ISlotTableProps {
  sort: any;
  setSort: ({ field, direction }: any) => void;
  registers: IAddedRegister[];
  isAdding: boolean;
  onDeleteAddingSlotClick: () => void;
  handleAddSlot: (slot: IAddedRegister) => void;
  handleDeleteSlot: (id: string) => void;
  handleEditSlot: (slot: IAddedRegister) => void;
}

const RegisterTable = ({
  sort,
  setSort,
  registers,
  isAdding,
  onDeleteAddingSlotClick,
  handleAddSlot,
  handleDeleteSlot,
  handleEditSlot,
}: ISlotTableProps) => {
  const { t } = useTranslation();
  const [editingSlotId, setEditingSlotId] = useState<string>('');

  const {
    values,
    setFieldValue,
    resetForm,
    handleSubmit,
    errors,
    isValid,
    setValues,
  } = useFormik({
    initialValues: {
      name: '',
      code: '',
    },
    validate,
    onSubmit: values => {
      handleAddSlot({
        ...values,
        id: uuidv4(),
        avers_box_office_id: values.code,
        name: values.name,
      });
      handleResetForm();
    },
  });

  const handleResetForm = () => {
    resetForm();
    onDeleteAddingSlotClick();
  };

  const handleSubmitEditing = () => {
    handleEditSlot({
      ...values,
      id: editingSlotId,
      name: values.name,
      avers_box_office_id: values.code,
    });
    setEditingSlotId('');
    resetForm();
  };

  const handleResetEditing = () => {
    resetForm();
  };

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead field="avers_box_office_id">
            {t('cash_register_code')}
          </TableCellHead>
          <TableCellHead className={styles.slotsCell} field="name">
            {t('cash_register_name')}
          </TableCellHead>
          <TableCellHead field="actions">{t('actions')}</TableCellHead>
        </TableHead>
        <TableBody>
          {isAdding && (
            <TableRow style={styles.editRow}>
              <TableCell className={styles.tableEditCell}>
                <InputBar
                  placeholder={t('cash_register_code')}
                  classNameInput={styles.input}
                  value={values.code}
                  onChange={event => setFieldValue('code', event.target.value)}
                  errors={errors.code}
                  error={!!errors.code}
                />
              </TableCell>
              <TableCell className={classNames(styles.statusCell)}>
                <InputBar
                  placeholder={t('cash_register_name')}
                  classNameInput={styles.input}
                  value={values.name}
                  onChange={event => setFieldValue('name', event.target.value)}
                  errors={errors.name}
                  error={!!errors.name}
                />
              </TableCell>
              <TableCell>
                <div className={styles.slotTableActions}>
                  <IconButton onClick={handleSubmit} isDisabled={!isValid}>
                    <Checkmark />
                  </IconButton>
                  <IconButton onClick={handleResetForm}>
                    <Basket />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          )}
          {registers?.map(item => {
            if (editingSlotId === item.id) {
              return (
                <TableRow style={styles.editRow}>
                  <TableCell className={styles.tableEditCell}>
                    <InputBar
                      placeholder="code"
                      classNameInput={styles.input}
                      value={values.code}
                      onChange={event =>
                        setFieldValue('code', event.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell className={styles.statusCell}>
                    <InputBar
                      placeholder="name"
                      classNameInput={styles.input}
                      value={values.name}
                      onChange={event =>
                        setFieldValue('name', event.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <div className={styles.slotTableActions}>
                      <IconButton onClick={handleSubmitEditing}>
                        <Checkmark />
                      </IconButton>
                      <IconButton onClick={handleResetEditing}>
                        <Basket />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              );
            }
            return (
              <TableRow key={item?.id}>
                <TableCell className={styles.tableCell}>
                  {item.avers_box_office_id}
                </TableCell>
                <TableCell className={styles.statusCell}>{item.name}</TableCell>
                <TableCell>
                  <div className={styles.slotTableActions}>
                    <IconButton
                      onClick={() => {
                        setEditingSlotId(item.id);
                        setValues({
                          name: item.name,
                          code: item.avers_box_office_id,
                        });
                      }}
                      className={styles.entertainmentTableBtnLink}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteSlot(item.id)}>
                      <Basket />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default RegisterTable;
