import { useState } from 'react';
import { TableSelect } from '~/ui/TableSelect/TableSelect';
import { Pagination, Spinner } from '~/ui';
import { useTranslation } from 'react-i18next';
import styles from './StockBoard.module.scss';
import { Action } from '~/ui/Actions';
import { useNavigate } from 'react-router-dom';
import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { useToastError } from '~/utils/useToastError';
import { Board } from '~/components/Shared/Layout/Board/Board';
import StockTable from './StockTable/StockTable';
import { useToastSuccess } from '~/utils/useToastSuccess';

interface SelectedPage {
  selected: number;
}

export interface ICondition {
  id: string;
  department: { id: number; name: string };
  description: { id: number; name: string };
  meaning: string;
}

export interface IStock {
  id: number;
  name: string;
  description: string;
  asset_id: number;
  start_date: string;
  end_date: string;
  status_id: number;
  burn_count_days: number;
  calculated_points: number;
  piwigo_image_id: number;
  photos: { id: number; full_photo_url: string; path: string }[];
  status: { id: number; name: string; type: string };
  accommodations: {
    accommodations: { id: number; name: string; full_name: string };
  }[];
  organizations: { id: number; name: string }[];
  rules: {
    id: number;
    promo_loyalty_id: number;
    rule_id: number;
    value: number;
    rule: {
      module: { name: string };
      id: number;
      description: string;
      module_id: number;
    };
  }[];
}

const StockBoard = () => {
  const [selectedPlaces, setSelectedPlaces] = useState<number[]>([]);
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [perPage, setPerPage] = useState(15);
  const [page, setPage] = useState<number>();
  const { t } = useTranslation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();

  const { mutateAsync: deleteStock } = useMutation(
    async (id: number) => {
      const response = await instance.delete(`admin/promo-loyalty/${id}`);

      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['promotions']);
        toastSuccess(t('stock_successfully_deleted'));
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const params = {
    sort: sort.direction,
    perPage,
    field: sort.field,
    page,
  };

  const { data, isLoading: dataIsLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/promo-loyalty', { params });
      return data;
    },
    queryKey: ['promotions', params],
    onError: error => {
      toastError(error);
    },
  });

  const handleSelect = (id: number) => {
    if (selectedPlaces.includes(id)) {
      const filteredUsers = selectedPlaces.filter(
        selectedId => selectedId !== id
      );
      setSelectedPlaces(filteredUsers);
    } else {
      setSelectedPlaces([...selectedPlaces, id]);
    }
  };

  return (
    <Board>
      <div className={styles.entertainmentTableOptions}>
        <TableSelect perPage={perPage} setPerPage={setPerPage} />
        <div className={styles.tableHeadRightWrapper}>
          <Action
            variant="success"
            onClick={() => {
              navigate('/promotions/create');
            }}
          >
            <AddIcon /> {t('create_new_promotion')}
          </Action>
        </div>
      </div>
      {dataIsLoading ? (
        <Spinner />
      ) : (
        <StockTable
          deleteStock={deleteStock}
          stocks={data?.data}
          sort={sort}
          setSort={setSort}
          selectedItems={selectedPlaces}
          handleToggle={handleSelect}
        />
      )}
      <div className={styles.foodTablePagination}>
        <TableAmountPage
          firstRow={data?.from}
          lastRow={data?.to}
          total={data?.total}
        />
        <Pagination
          pageCount={data?.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
    </Board>
  );
};

export default StockBoard;
