import { useLocation, matchPath, Params } from 'react-router-dom';

export type BreadcrumbComponentType = React.ComponentType<{ params: Params }>;

export interface Route {
  path: string;
  breadcrumb: string | BreadcrumbComponentType;
}

interface Breadcrumb {
  path: string;
  title: React.ReactNode;
}

const useBreadcrumbs = (routes: Route[]) => {
  const { pathname } = useLocation();

  const breadcrumbs: Breadcrumb[] = [];
  pathname
    .split('/')
    .reduce(
      (previousSection: string, currentSection: string, index: number) => {
        const pathSection = !currentSection
          ? '/'
          : `${previousSection}/${currentSection}`;

        if (pathSection === '/' && index !== 0) {
          return '';
        }

        routes.some(({ path, breadcrumb: Breadcrumb }) => {
          const match = matchPath({ path, end: true }, pathSection);
          if (!match) return false;

          const title =
            typeof Breadcrumb === 'string' ? (
              Breadcrumb
            ) : (
              <Breadcrumb params={match.params} />
            );

          breadcrumbs.push({ path: match.pathname, title });
          return true;
        });

        return pathSection === '/' ? '' : pathSection;
      },
      ''
    );

  return breadcrumbs;
};

export default useBreadcrumbs;
