import classNames from 'classnames';
import styles from './SvgIcon.module.scss';

export const SvgIcon = ({
  svgString,
  className = '',
}: {
  svgString: string;
  className?: string;
}) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: svgString }}
      className={classNames(styles.categoryIcon, className)}
    />
  );
};
