import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import EditArticle from './EditArticle/EditArticle';
import { useTranslation } from 'react-i18next';

const EditArticlePage = () => {
  const { t } = useTranslation();
  return (
    <Page heading={<Heading text={t('edit_article')} />}>
      <EditArticle />
    </Page>
  );
};

export default EditArticlePage;
