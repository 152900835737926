import StorageFolderList from './StorageFolderList/StorageFolderList';

export interface IAsset {
  id: number;
  name: string;
}

export interface IStorageProps {
  assets: IAsset[];
}

const Storage = ({ assets }: IStorageProps) => {
  return <StorageFolderList assets={assets} />;
};

export default Storage;
