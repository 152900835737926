import { useTranslation } from 'react-i18next';
import PromotionsBoard from '~/components/PromotionsBoard/PromotionsBoard';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';

const Promotions = () => {
  const { t } = useTranslation();
  return (
    <Page heading={<Heading text={t('promotions')} />}>
      <PromotionsBoard />
    </Page>
  );
};

export default Promotions;
