import { DatePicker } from 'antd';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';
import { InputBar } from '~/ui';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { IUsersFilters } from '~/utils/types/userFilterTypes';
import { validate } from '~/utils/validateFeedbackFilter';
import styles from './FeedbackFilters.module.scss';
import { useEffect, useState } from 'react';
import { FilterButtons } from '../FiltersButtons/FilterButtons';
import { FilterErrorMessage } from '../FilterErrorMessage/FilterErrorMessage';

interface IFormik {
  dates: {
    start_date: string;
    end_date: string;
  };

  category_ids: { id: number; name: string }[] | [];
  feedback: {
    condition: { id: number; name: string; display_name: string };
    average: string;
  };
}

interface IOrderProps {
  selectedUsers: number[];
  onClick: (filters: IUsersFilters) => void;
  setIsStatusUsersModalOpen: () => void;
  isUsersLoading: boolean;
}

export const FeedbackFilters = ({
  onClick,
  selectedUsers,
  setIsStatusUsersModalOpen,
  isUsersLoading,
}: IOrderProps) => {
  const [isAnyFieldFilled, setIsAnyFieldFilled] = useState(false);
  const toastError = useToastError();
  const { data: feedbackCategories } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/feedback-categories');
      return response.data;
    },
    queryKey: ['admin/feedback-categories'],
    onError: error => {
      toastError(error);
    },
  });

  const getModuleOptions = (data: {
    [key: string]: {
      id: number;
      name: string;
      status: string;
    };
  }) => {
    if (!data) return [];
    const modulesOptions = Object.values(data);
    return modulesOptions;
  };

  const modulesOptions = getModuleOptions(feedbackCategories?.categories);

  const formik = useFormik<IFormik>({
    initialValues: {
      dates: {
        start_date: '',
        end_date: '',
      },
      category_ids: [],
      feedback: {
        condition: { id: 0, name: '', display_name: '' },
        average: '',
      },
    },
    validate,
    onSubmit: values => {
      const categoriesIds = values.category_ids.map(item => item.id);

      const filters = {
        feedbacks: {
          ...(values.dates.start_date &&
            values.dates.end_date && {
              dates: {
                start_date: values.dates.start_date,
                end_date: values.dates.end_date,
              },
            }),
          ...(!!values.category_ids.length && {
            category_ids: categoriesIds,
          }),
          ...(values.feedback.condition &&
            values.feedback.average && {
              condition: values.feedback.condition.name,
              average: values.feedback.average,
            }),
        },
      };
      onClick(filters);
    },
  });

  const handleResetForm = () => {
    formik.resetForm();
    onClick({});
  };

  useEffect(() => {
    const isFilled =
      !!formik.values.dates.start_date ||
      !!formik.values.dates.end_date ||
      !!formik.values.category_ids.length ||
      !!formik.values.feedback.average ||
      !!formik.values.feedback.condition.name;

    setIsAnyFieldFilled(isFilled);
  }, [formik.values]);

  return (
    <div className={styles.orderContainer}>
      <form onSubmit={formik.handleSubmit} className={styles.formWrapper}>
        <div className={styles.dateWrapper}>
          <div className={styles.dateContainer}>
            <span className={styles.dateLabel}>{t('feedback_start_date')}</span>
            <div className={styles.dateBox}>
              <DatePicker
                name="start_date"
                className="datepicker"
                format="DD.MM.YYYY"
                value={
                  formik.values.dates.start_date
                    ? dayjs(formik.values.dates.start_date)
                    : null
                }
                onChange={date => {
                  formik.setFieldValue(
                    'dates.start_date',
                    date
                      ? dayjs(date)
                          .hour(0)
                          .minute(0)
                          .second(1)
                          .format('YYYY-MM-DD HH:mm:ss')
                      : null
                  );
                }}
                placeholder={t('date')}
                disabledDate={(current: Dayjs) =>
                  formik.values.dates.end_date
                    ? current &&
                      current > dayjs(formik.values.dates.end_date).endOf('day')
                    : false
                }
                onBlur={() => formik.setFieldTouched('dates.start_date', true)}
              />
              {formik.errors.dates?.start_date && (
                <FilterErrorMessage message={formik.errors.dates?.start_date} />
              )}
            </div>
          </div>
          <div className={styles.dateContainer}>
            <span className={styles.dateLabel}>{t('feedback_end_date')}</span>
            <div className={styles.dateBox}>
              <DatePicker
                name="end_date"
                className="datepicker"
                format="DD.MM.YYYY"
                value={
                  formik.values.dates.end_date
                    ? dayjs(formik.values.dates.end_date)
                    : null
                }
                onChange={date => {
                  formik.setFieldValue(
                    'dates.end_date',
                    date
                      ? dayjs(date).endOf('day').format('YYYY-MM-DD HH:mm:ss')
                      : null
                  );
                }}
                placeholder={t('date')}
                disabledDate={(current: Dayjs) =>
                  formik.values.dates.start_date
                    ? current &&
                      current <
                        dayjs(formik.values.dates.start_date).startOf('day')
                    : false
                }
                onBlur={() => formik.setFieldTouched('dates.end_date', true)}
              />
              {formik.errors.dates?.end_date && (
                <FilterErrorMessage message={formik.errors.dates?.end_date} />
              )}
            </div>
          </div>
        </div>
        <div className={styles.operators}>
          <div className={styles.operatorWrapper}>
            <CustomSelect
              label={t('feedback_category')}
              value={formik.values.category_ids}
              className={styles.topSelect}
              options={modulesOptions}
              placeholder={t('select_categories')}
              onChange={value => formik.setFieldValue('category_ids', value)}
              multiselect
              error={
                formik.touched.category_ids
                  ? (formik.errors.category_ids as string)
                  : undefined
              }
            />
          </div>
          <div className={styles.operatorWrapper}>
            <span className={styles.operatorsLabel}>
              {t('average_of_rating')}
            </span>
            <div className={styles.operatorLine}>
              <CustomSelect
                value={formik.values.feedback.condition}
                options={[
                  { id: 1, display_name: `${t('more')}`, name: '>' },
                  { id: 2, display_name: `${t('less')}`, name: '<' },
                  { id: 3, display_name: `${t('equal')}`, name: '=' },
                ]}
                placeholder={t('select_operator')}
                onChange={value =>
                  formik.setFieldValue('feedback.condition', value)
                }
                error={
                  formik.touched.feedback?.condition?.name
                    ? (formik.errors.feedback?.condition?.name as string)
                    : undefined
                }
              />
              <InputBar
                value={formik.values.feedback.average}
                name="feedback.average"
                onChange={formik.handleChange}
                classNameInputWrapper={styles.input}
                placeholder={t('enter_the_number')}
                label=""
                errors={formik.errors.feedback?.average}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.feedback?.average &&
                  Boolean(formik.errors.feedback?.average)
                }
              />
            </div>
          </div>
        </div>
        <FilterButtons
          setIsModalOpen={setIsStatusUsersModalOpen}
          isAnyFieldFilled={isAnyFieldFilled}
          selectedUsers={selectedUsers}
          resetForm={handleResetForm}
          isLoading={isUsersLoading}
        />
      </form>
    </div>
  );
};

//
