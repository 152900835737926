import i18next from 'i18next'
import { Dayjs } from 'dayjs'

export const validate = values => {
  const errors = {}

  if (!values.user.full_name) {
    errors.user = i18next.t('validate_employee')
  }

  return errors
}
