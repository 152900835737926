import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import { Board } from '~/components/Shared/Layout/Board/Board';
import { useTranslation } from 'react-i18next';
import SlotTable, { IAddedSlot } from './SlotTable/SlotTable';
import { Action } from '~/ui/Actions';
import { useState } from 'react';

import styles from './SlotBoard.module.scss';

interface ISlotBoardProps {
  slots: IAddedSlot[];
  handleAddSlot: (slot: IAddedSlot) => void;
  handleDeleteSlot: (id: string) => void;
  handleEditSlot: (slot: IAddedSlot) => void;
}

const SlotBoard = ({
  slots,
  handleAddSlot,
  handleDeleteSlot,
  handleEditSlot,
}: ISlotBoardProps) => {
  const { t } = useTranslation();
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [isAddingSlot, setIsAddingSlot] = useState(false);

  const toggleAddingSlot = () => {
    setIsAddingSlot(prevState => !prevState);
  };

  return (
    <Board classNames={styles.slotBoard}>
      <div className={styles.slotBoardHeader}>
        <Action variant="success" onClick={toggleAddingSlot}>
          <AddIcon /> {t('create_slot')}
        </Action>
      </div>
      <SlotTable
        sort={sort}
        setSort={setSort}
        records={slots}
        isAdding={isAddingSlot}
        onDeleteAddingSlotClick={toggleAddingSlot}
        handleAddSlot={handleAddSlot}
        handleDeleteSlot={handleDeleteSlot}
        handleEditSlot={handleEditSlot}
      />
    </Board>
  );
};

export default SlotBoard;
