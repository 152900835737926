import { Action } from '~/ui/Actions';
import { Board } from '../Shared/Layout/Board/Board';
import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import { useTranslation } from 'react-i18next';
import styles from './RegisterTableEdit.module.scss';
import { useState } from 'react';
import RegisterTable, {
  IAddedRegister,
} from '../RegisterBoard/RegisterTable/RegisterTable';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useToastSuccess } from '~/utils/useToastSuccess';

interface IRegisterBoardProps {
  registers: IAddedRegister[];
  updateData: () => void;
}

const RegisterBoardEdit = ({ updateData, registers }: IRegisterBoardProps) => {
  const { t } = useTranslation();
  const { assetID } = useParams();
  const [sort, setSort] = useState({ field: '', direction: '' });
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();

  const client = useQueryClient();

  const { mutateAsync: deleteRegister } = useMutation(
    async (id: string) => {
      const result = await instance.delete(`admin/assets-box-office/${id}`);
      return result.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['assets-box-office', assetID]);
        toastSuccess(t('register_successfully_deleted'));
        updateData();
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { mutateAsync: updateRegister } = useMutation(
    async ({ id, name, avers_box_office_id }: IAddedRegister) => {
      const result = await instance.put(`admin/assets-box-office/${id}`, {
        asset_id: assetID,
        name,
        avers_box_office_id,
      });
      return result.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['assets-box-office', assetID]);
        toastSuccess(t('register_successfully_updated'));
        updateData();
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { mutateAsync: craateRegister } = useMutation(
    async ({ name, avers_box_office_id }: IAddedRegister) => {
      const result = await instance.post(`admin/assets-box-office`, {
        asset_id: assetID,
        name,
        avers_box_office_id,
      });
      return result.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['assets-box-office', assetID]);
        toastSuccess(t('register_successfully_created'));
        updateData();
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const [isAddingSlot, setIsAddingSlot] = useState(false);

  const toggleAddingSlot = () => {
    setIsAddingSlot(prevState => !prevState);
  };

  return (
    <Board classNames={styles.board}>
      <div className={styles.slotBoardHeader}>
        <Action
          variant="success"
          className={styles.addBtn}
          onClick={toggleAddingSlot}
        >
          <AddIcon /> {t('add_cash_register')}
        </Action>
      </div>
      <RegisterTable
        registers={registers}
        sort={sort}
        isAdding={isAddingSlot}
        setSort={setSort}
        handleAddSlot={craateRegister}
        handleDeleteSlot={deleteRegister}
        handleEditSlot={updateRegister}
        onDeleteAddingSlotClick={toggleAddingSlot}
      />
    </Board>
  );
};

export default RegisterBoardEdit;
