import cn from 'classnames';
import styles from './EntertainmentStatusBadge.module.scss';

interface IEntertainmentStatusBadgeProps {
  status: {
    id: number;
    name: string;
    type: string;
  };
}

const EntertainmentStatusBadge = ({
  status,
}: IEntertainmentStatusBadgeProps) => {
  return (
    <div
      className={cn(
        styles.entertainmentStatusBadge,
        status.type === 'active' || status.type === 'default'
          ? styles.entertainmentStatusBadgeActive
          : status.type === 'inactive'
            ? styles.entertainmentStatusBadgeInactive
            : styles.entertainmentStatusBadgeFull
      )}
    >
      {status.name}
    </div>
  );
};

export default EntertainmentStatusBadge;
