export const nameValidate = value => /^[А-ЯЁа-яё]+$/i.test(value)
export const telValidate = value =>
  /^\+7\s?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}$/i.test(value)
export const emailValidate = value =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value)
export const phraseValidate = value =>
  /^[А-Яа-яA-Za-z0-9Ёё\s№/.,-]+$/i.test(value)
export const numberValidate = value => /^-?[0-9]+$/.test(value);
export const numberFloatValidate = value => /^[0-9]+(\.[0-9]+)?$/.test()
export const personTelValidate = value =>
  /^\8\s?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}$/i.test(value)
export const englishValidate = value => /^[a-zA-Z0-9]+$/.test(value)
export const spaceValidate = value => !/\s/.test(value)
