import cn from 'classnames';
import styles from './SlotsUserStatusBadge.module.scss';

interface ISlotsUserStatusBadgeProps {
  status: { id: number; name: string; type: string };
}

const SlotsUserStatusBadge = ({ status }: ISlotsUserStatusBadgeProps) => {
  if (status.type === 'active') {
    return (
      <div
        className={cn(styles.slotsStatusBadge, styles.slotsStatusBadgeActive)}
      >
        {status.name}
      </div>
    );
  }
  if (
    status.type === 'canceled_by_user' ||
    status.type === 'canceled_by_admin'
  ) {
    return (
      <div
        className={cn(styles.slotsStatusBadge, styles.slotsStatusBadgeInactive)}
      >
        {status.name}
      </div>
    );
  }
  if (status.type === 'completed') {
    return (
      <div
        className={cn(
          styles.slotsStatusBadge,
          styles.slotsStatusBadgeCompleted
        )}
      >
        {status.name}
      </div>
    );
  }
};

export default SlotsUserStatusBadge;
