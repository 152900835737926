import { useTranslation } from 'react-i18next';
import EditLevel from '~/components/EditLevel/EditLevel';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';

const EditLevelPage = () => {
  const { t } = useTranslation();

  return (
    <Page heading={<Heading text={t('editing_level')} />}>
      <EditLevel />
    </Page>
  );
};

export default EditLevelPage;
