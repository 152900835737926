import { InputBar } from '~/ui';
import styles from './EditSlotForm.module.scss';
import { useTranslation } from 'react-i18next';
import SlotUsersBoard, { IAppointment } from './SlotUsersBoard/SlotUsersBoard';

interface IEditSlotFormProps {
  appointments: {
    data: {
      from: number;
      to: number;
      last_page: number;
      total: number;
      data: IAppointment[];
    };
    max_users_count: string;
    users_count: number;
    date_slot_object: {
      status_id: number;
    };
  };
  sort: any;
  page?: number;
  setSort: ({ field, direction }: any) => void;
  perPage: number;
  setPerPage: (perPage: number) => void;
  setPage: (page: number) => void;
}

const EditSlotForm = ({
  appointments,
  sort,
  setSort,
  setPage,
  perPage,
  setPerPage,
}: IEditSlotFormProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.editSlotForm}>
      <div className={styles.inputs}>
        <InputBar
          label={t('maximum_appointments')}
          value={appointments?.max_users_count}
          onChange={() => {}}
          disabled
        />
        <InputBar
          label={t('active_appointments')}
          value={String(appointments?.users_count)}
          onChange={() => {}}
          disabled
        />
      </div>
      <div className={styles.tableContainer}>
        <SlotUsersBoard
          appointments={appointments}
          sort={sort}
          setSort={setSort}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </div>
    </div>
  );
};

export default EditSlotForm;
