import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import PointsBadge from './PointsBadge/PointsBadge';
import dayjs from 'dayjs';
import styles from './PointsTable.module.scss';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '~/ui';

interface IPromoLoyalty {
  description: string;
  start_date: string;
}

interface IUser {
  full_name: string;
  name: string;
  patronymic: string;
}

interface IPointsProps {
  points: {
    id: number;
    user_id: number;
    points: number;
    promo_loyalty: IPromoLoyalty;
    user: IUser;
    created_at: string;
  }[];
  sort: { field: string; direction: string };
  setSort: ({ field, direction }: { field: string; direction: string }) => void;
}

const PointsTable = ({ points, sort, setSort }: IPointsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead field="user_id">{t('employee')}</TableCellHead>
        <TableCellHead field="created_at">{t('date_and_time')}</TableCellHead>
        <TableCellHead field="promo_loyalty_id">{t('stock')}</TableCellHead>
        <TableCellHead className={styles.pointsHead} field="points">
          {t('accrued_points')}
        </TableCellHead>
      </TableHead>
      <TableBody>
        {points.length !== 0 ? (
          points.map(item => (
            <TableRow
              key={item.id}
              onClick={() => navigate(`/user/${item.user_id}`)}
              style={styles.row}
            >
              <TableCell>
                {`${item?.user?.name} ${item?.user?.patronymic}`}
              </TableCell>
              <TableCell>
                {dayjs(item.created_at).format('DD.MM.YYYY HH:mm')}
              </TableCell>
              <TableCell>{item.promo_loyalty.description}</TableCell>
              <TableCell className={styles.points}>
                <PointsBadge point={item.points} />
              </TableCell>
            </TableRow>
          ))
        ) : (
          <Spinner />
        )}
      </TableBody>
    </Table>
  );
};

export default PointsTable;
