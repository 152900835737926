import { useTranslation } from 'react-i18next';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import { Action } from '~/ui/Actions';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import Storage from '~/components/Storage/Storage';
import FolderIcon from '~/assets/svg/newSvg/folder.svg?react';
import UploadIcon from '~/assets/svg/newSvg/upload-file.svg?react';
import { Spinner } from '~/ui';
import { useContext, useEffect, useState } from 'react';
import { Modal } from '~/components/Shared/Modal/Modal';
import styles from './StoragePage.module.scss';
import { useParams } from 'react-router-dom';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { useToastSuccess } from '~/utils/useToastSuccess';
import StorageSubmitInfo from '~/components/Storage/StorageSubmitInfo/StorageSubmitInfo';

interface IDownloadFile {
  name: string;
  file: File;
}

interface IAsset {
  id: number;
  name: string;
}

const StoragePage = () => {
  const { user } = useContext(AuthenticationContext);
  const isSuperAdmin = checkIfUserSuperAdmin(user?.data.role_name);
  const { t } = useTranslation();
  const { assetId } = useParams();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const [allAssets, setAllAssets] = useState<IAsset[]>([]);
  const [currentAsset, setCurrentAsset] = useState<IAsset>({ id: 0, name: '' });
  const [downloadFiles, setDownloadFiles] = useState<File[]>([]);
  const [isAddingFiles, setIsAddingFiles] = useState(false);
  const [drag, setDrag] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const client = useQueryClient();

  const loadAssets = async () => {
    try {
      const { data } = await instance.get('admin/assets', {
        params: { page: 1, perPage: 100 },
      });
      setAllAssets(data.data);
    } catch {
      toastError(`${t('failed_to_load')} ${t('assets')}`);
    }
  };

  const { data: assets, isLoading: isAssetsLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/assets');
      return data;
    },
    queryKey: ['assets'],
    onError: error => {
      toastError(error);
    },
  });

  const { mutateAsync: createFiles } = useMutation(
    async (data: { asset_id: number; files: IDownloadFile[] }) => {
      const res = await instance.postForm('admin/documents', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['documents', assetId]);
        toastSuccess(t('files_successfully_added'));
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDrag(true);
  };

  const handleDragLeav = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDrag(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setDownloadFiles(files);
    setIsAddingFiles(false);
    setIsModalOpen(true);
  };

  const handleSubmit = () => {
    const filesData = downloadFiles.map(file => ({
      name: file.name,
      file: file,
    }));

    const props = {
      asset_id: currentAsset.id,
      files: filesData,
    };

    createFiles(props);
    setIsAddingFiles(false);
    setIsModalOpen(false);
  };

  const handleCloseBtn = () => {
    setDownloadFiles([]);
    setIsModalOpen(false);
    setIsAddingFiles(false);
  };

  useEffect(() => {
    loadAssets();
    if (isSuperAdmin) {
      setCurrentAsset({ id: 0, name: '' });
    } else {
      const asset = assets?.data?.find(
        (item: { id: number }) => item.id === Number(assetId)
      );
      setCurrentAsset(asset);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dragWrapper = document.getElementById('dragWrapper');
      const actionButton = document.querySelector('.action');

      if (
        dragWrapper &&
        !dragWrapper.contains(event.target as Node) &&
        actionButton &&
        !actionButton.contains(event.target as Node)
      ) {
        setIsAddingFiles(false);
      }
    };

    if (isAddingFiles) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isAddingFiles]);

  return (
    <Page
      heading={
        <Heading
          text={t('storage')}
          actions={
            <>
              {assetId && (
                <Action
                  variant="success"
                  onClick={() => setIsAddingFiles(prev => !prev)}
                  className="action"
                >
                  <AddIcon />
                  {t('create_storage')}
                </Action>
              )}
            </>
          }
        />
      }
    >
      <div className={styles.page}>
        {!assets?.data?.length && isAssetsLoading ? (
          <Spinner />
        ) : (
          <Storage assets={assets.data} />
        )}
        {isAddingFiles && (
          <div
            id="dragWrapper"
            className={styles.dragWrapper}
            onDragStart={e => handleDragStart(e)}
            onDragLeave={e => handleDragLeav(e)}
            onDragOver={e => handleDragStart(e)}
            onDrop={e => handleDrop(e)}
          >
            <div>
              <UploadIcon />
            </div>
            {!drag ? (
              <p className={styles.dragTitle}>{t('drop_files_into_area')}</p>
            ) : (
              <p className={styles.dragTitle}>
                {t('files_in_the_visible_area')}
              </p>
            )}
            <p className={styles.dragDescription}>
              {t('downloaded_files_info')}:{' '}
            </p>
            <div className={styles.folderName}>
              <FolderIcon />
              <span>{currentAsset?.name}</span>
            </div>
          </div>
        )}
      </div>
      <Modal
        modalStyles={styles.photosModal}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <StorageSubmitInfo
          handleCloseBtn={handleCloseBtn}
          handleSubmit={handleSubmit}
          allAssets={allAssets}
          setCurrentAsset={setCurrentAsset}
          currentAsset={currentAsset}
          isSuperAdmin={isSuperAdmin}
        />
      </Modal>
    </Page>
  );
};

export default StoragePage;
