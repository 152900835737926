import { useNavigate, useParams } from 'react-router-dom';
import LevelForm from '../LevelForm/LevelForm';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useToastError } from '~/utils/useToastError';
import { instance } from '~/utils/api/api';
import { IUpdateLevelData } from '~/utils/types/promotion';
import { useTranslation } from 'react-i18next';
import { Spinner } from '~/ui';

const EditLevel = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();

  const { mutateAsync: updateLevel } = useMutation(
    async ({
      name,
      points,
      color_id,
      asset_id,
      discount_percent,
    }: IUpdateLevelData) => {
      const response = await instance.put(`admin/loyalty-level/${id}`, {
        name,
        points,
        color_id,
        asset_id,
        discount_percent,
      });
      return response;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['promotion-level']);
        toastSuccess(t('level_successfull_updated'));
        navigate('/promotions/levels');
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(`admin/loyalty-level/${id}`);
      return data;
    },
    queryKey: ['promotion-level', id],
  });

  return (
    <div>
      {!isLoading && data ? (
        <LevelForm
          isEdit
          leveldata={data || undefined}
          updateLevel={updateLevel}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default EditLevel;
